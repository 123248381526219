import PropTypes from 'prop-types';
import { forwardRef } from 'react';

/**
 * Provides common functionality for lists of radio or checkbox options.
 */
const CommonSelectable = forwardRef(
  ({ options, name, onClick, containerClass, renderItem }, ref) => (
    <div ref={ref} className={containerClass}>
      {options.map((option, idx) => {
        return renderItem(
          option,
          onClick.bind(this, option.value),
          {
            key: option.value,
            name: name,
            id: option.value,
            value: option.value,
            selected: option.selected,
          },
          idx,
        );
      })}
    </div>
  ),
);

const commonOptionProps = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

const commonSelectableProps = {
  options: PropTypes.arrayOf(PropTypes.shape(commonOptionProps)).isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

CommonSelectable.propTypes = Object.assign({}, commonSelectableProps, {
  containerClass: PropTypes.string,
  renderItem: PropTypes.func.isRequired,
});

export { CommonSelectable, commonOptionProps, commonSelectableProps };

export default CommonSelectable;
