import styled, { css } from 'styled-components/macro';

import typeStyle from '@/styles/js/fonts/typeStyles';
import fluidSize from '@/styles/js/utils/fluidSize';
import { notTouch } from '@/styles/js/utils/media';
import { notSelectable } from '@/styles/js/utils/interations';

/*
TimeSet

Allows for incrementing and decrementing of start and end times.
*/

export const TimeSet = styled.div.attrs({
  className: 'TimeSet',
})`
  ${notSelectable}

  display: flex;
  justify-content: space-between; 
  ${fluidSize('lay-s', 'padding-top', { scale: 0.6 })};
  ${fluidSize('lay-s', 'padding-bottom', { scale: 0.6 })};
`;

export const TimeSetDisabled = styled.p.attrs({
  className: 'TimeSet__disabled',
})`
  ${typeStyle('micro')};

  color: ${({ theme }) => theme.colors.accent};
  text-align: center;
  width: 100%;
`;

export const TimeSetGroupLabel = styled.p.attrs({
  className: 'TimeSet__group-label',
})`
  ${typeStyle('micro')};

  color: ${({ theme }) => theme.colors.accent};
  margin-bottom: 1.2rem;
  text-align: center;
`;

export const TimeSetGroupValue = styled.p.attrs({
  className: 'TimeSet__group-value',
})`
  ${typeStyle('body-l')};

  font-weight: bold;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
`;

export const TimeSetGroupToggle = styled.span.attrs({
  className: 'TimeSet__group-toggle',
})`
  cursor: pointer;
  position: absolute;
  top: 3.2rem;

  &:nth-child(3) {
    left: 0;
  }

  &:nth-child(4) {
    right: 0;
  }

  svg {
    color: ${({ theme }) => theme.colors.accent};
    opacity: 0.35;

    ${notTouch} {
      &:hover {
        opacity: 1;
      }
    }

    &:active {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  ${({ $disabled, theme }) => $disabled && css`
    cursor: default;

    .Icon svg {
      color: ${theme.colors.textDisabled};

      ${notTouch} {
        &:hover {
          opacity: 0.35;
        }
      }

      &:active {
        color: ${theme.colors.textDisabled};
      }
    }
  `}
`;

export const TimeSetGroup = styled.div.attrs({
  className: 'TimeSet__group',
})`
  width: 14rem;
  padding-left: 0.3rem;
  position: relative;

  ${({ $disabled, theme }) => $disabled && css`
    ${TimeSetGroupLabel}, 
    ${TimeSetGroupValue} {
      color: ${theme.colors.textDisabled};
    }
  `}
`;