import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useMount from '@/hooks/useMount';

const useOnRouteChange = (onChange) => {
  const initialLocation = useLocation();
  const { listen } = useHistory();

  const [location, setLocation] = React.useState(() => initialLocation);

  const handlerRef = React.useRef(() => null);

  React.useEffect(() => {
    handlerRef.current = onChange;
  });

  useMount(() => {
    const unsubscribe = listen((location) => {
      setLocation(location);
      handlerRef.current(location);
    });

    return () => {
      unsubscribe();
    };
  });

  return location;
};

export default useOnRouteChange;
