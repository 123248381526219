import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { commonLinkProps } from '@/components/buttons-links/CommonLink';

import * as S from './Button.styles';

/**
 * Displays a button, the most common component for
 * important user interactions.
 *
 * (Optional) props
 *  - `type`
 *  - `icon`
 *  - `disabled`
 *  - `color`
 *      - `warning` - Represents an important/destructive action.
 *      - `reverse` - For use on dark backgrounds
 *      - `reverse-no-shadow`
 *      - `lock-locked` - Used exclusively within an Unlockable component when locked
 *      - `lock-unlocking` - Used exclusively within an Unlockable component when unlocking
 *      - `lock-unlocked` - Used exclusively within an Unlockable component when unlocked
 *  - `alt`
 *      - `alt` - Indicates that the button is a less important action.
 *      - `alt-small`
 *      - `alt-large`
 *  - `altHover` - Alternate hover color
 *  - `size`
 *      - `large` - Larger version
 *      - `small` - Smaller version
 *      - `mini` - Very small version
 *      - `micro` - Super small version
 *  - `widthSize`
 *      - `full` - 100% width of parent
 *      - `full-small` - 100% width of parent only at small viewport sizes
 *  - `noMinWidth` - No minimum width set
 *  - `arrow` - includes an arrow icon at the end
 *  - `processing` - indicates that work is being done
 *  - `flat`
 *  - `noMarginTop`
 */
const Button = forwardRef(
  ({ children, icon, type = 'button', ...props }, ref) => {
    let iconBefore = icon;
    if (props.processing) iconBefore = 'processing-24';
    return (
      <S.Button
        ref={ref}
        {...props}
        iconBefore={iconBefore}
        iconAfter={props.arrow ? 'arrow-right-24' : null}
        buttonType={type}
      >
        {children}
      </S.Button>
    );
  },
);

export const propTypes = {
  ...commonLinkProps,
  icon: PropTypes.string,
  arrow: PropTypes.bool,
  flat: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  alt: PropTypes.string,
  altHover: PropTypes.bool,
  size: PropTypes.string,
  widthSize: PropTypes.string,
  noMinWidth: PropTypes.bool,
  processing: PropTypes.bool,
  noMarginTop: PropTypes.bool,
};

Button.propTypes = propTypes;

export default Button;
