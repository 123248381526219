import PropTypes from 'prop-types';
import React from 'react';

import FormSelectable from '@/components/forms/FormSelectable';

import FilterContext from '@/context/Filter';

import useEvent from '@/hooks/useEvent';

import { capacityValueMap } from '@/lib/fieldValues';

const GroupSizeFilterOptions = ({ onSelect = () => null }) => {
  const {
    updateFilters,
    filters: { capacity },
  } = React.useContext(FilterContext);

  const onClick = useEvent((value) => {
    updateFilters({ capacity: value });
    onSelect();
  });

  const options = React.useMemo(() => {
    return Array.from(capacityValueMap).map(([value, label]) => ({
      label,
      value,
      selected: capacity === value,
    }));
  }, [capacity]);

  return (
    <FormSelectable
      type="radio"
      row
      rowNoPadTop
      rowNoPadBottom
      name="groupSize"
      onClick={onClick}
      options={options}
    />
  );
};

GroupSizeFilterOptions.propTypes = {
  onSelect: PropTypes.func,
};

export default GroupSizeFilterOptions;
