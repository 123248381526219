import styled, { css } from 'styled-components/macro';

import { scaleWithVH, scaleWithVW } from '@/styles/js/utils/scaling';
import mq from '@/styles/js/utils/mq';
import * as pseudo from '@/styles/js/utils/pseudo';

import { StyledActionRow } from '@/components/buttons-links/ActionRow';
import { StyledLabel } from '@/components/labels/Label';
import { Text as StyledText } from '@/components/text-headings/Text.styles';
import { TextLink as StyledTextLink } from '@/components/buttons-links/TextLink.styles';
import { Button as StyledButton } from '@/components/buttons-links/Button.styles';

/**
 * Styled ActionSet Header
 * ---
 */
export const Header = styled.div`
  text-align: center;
  margin-top: -0.8rem;

  > ${StyledTextLink} {
    margin-bottom: 0.3rem;
  }

  > ${StyledText} {
    margin-bottom: 1.5rem;
  }
`;

/**
 * Styled ActionSet Item
 * ---
 */
export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  > * {
    justify-content: center;
    width: 100%;
  }

  > ${StyledActionRow} {
    width: 100%;
  }

  > ${StyledLabel} {
    margin-top: 0.8rem;
  }
`;

/**
 * Styled ActionSet Footer
 * ---
 *
 * Props
 * - `footerLeft`
 */
export const Footer = styled.div`
  text-align: center;
  margin-top: 1.5rem;

  ${({ footerLeft }) => footerLeft && css`
    text-align: left;
  `}

  > ${StyledTextLink} {
    margin-top: 0.3rem;
  }

  > ${StyledText} {
    margin-top: 0.8rem;
  }

  &:empty {
    display: none;
    visibility: hidden;
  }
`;

/**
 * Styled ActionSet
 * ----
 *
 * Props
 * - `padded`
 * - `horizontalPadding`
 * - `full`
 * - `fullSmall`
 * - `bottomAbsolute`
 * - `shadowTop`
 * - `split`
 * - `splitSmall`
 * - `spacedHeader`
 * - `spacedFooter`
 */
export const ActionSet = styled.div`
  ${({ padded, horizontalPadding, full, fullSmall, bottomAbsolute, shadowTop, split, splitSmall, spacedHeader, spacedFooter, alignSelf }) => css`

    ${padded && css`
      padding: ${scaleWithVH('2rem')} ${scaleWithVW('2.5rem')};
    `}

    ${horizontalPadding && css`
      padding: 0 ${scaleWithVW('2.5rem')};
    `}

    ${alignSelf && css`
      align-self: center;
    `}

    ${full && css`
      width: 100%;
    `}

    ${fullSmall && css`
      ${mq({ until: 'medium' })} {
        width: 100%;
      }
    `}

    ${bottomAbsolute && css`
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    `}

    ${shadowTop && css`
      position: relative;

      &:before {
        ${pseudo.absolute}
        ${pseudo.shadow('top')}

      left: 0;
        right: 0;
      }
    `}

    ${!split && css`
      > ${Item} {
        > ${StyledActionRow} + ${StyledButton},
        > ${StyledButton} + ${StyledButton} {
          margin-top: ${scaleWithVH('2.2rem')};
        }
      }
    `}

    ${split && css`
      > ${Item} {
        justify-content: space-between;
        align-items: flex-start;

        > * {
          width: 47.5%;
        }
      }
    `}

    ${splitSmall && css`
      ${mq({ until: 'medium' })} {
        > ${Item} {
          flex-direction: column;

          > * {
            width: 100%;
            margin-bottom: 2.4rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    `}

    ${Item} {
      &:empty {
        display: none;
        visibility: hidden;
      }
    }

    ${Header} {
      ${spacedHeader && css`
        margin-bottom: 0.8rem;
      `}
    }

    ${Footer} {
      ${spacedFooter && css`
        margin-top: 1.3rem;
      `}
    }
  `}
`;
