import React from 'react';

import localServerApi from '@/api/localServer';

import LoadingContent from '@/components/helpers/LoadingContent';

import { ThemeProvider } from '@/context/Theme';

import useEvent from '@/hooks/useEvent';
import useMount from '@/hooks/useMount';
import useStateMergeUpdater from '@/hooks/useStateMergeUpdater';

import { consumerToHOC } from '@/lib/hoc';

const Context = React.createContext({});

const authTokenPromise = localServerApi.auth.retrieveAuthToken();

let sessionReady = false;
let sessionLoading = true;
let tokenExists = false;

authTokenPromise.then(() => {
  sessionLoading = false;
  sessionReady = true;
  tokenExists = true;
});

export function SessionProvider({ children }) {
  // If session loaded really fast initialize it synchronously
  const [session, setSession] = React.useState(() => ({
    ready: sessionReady,
    loading: sessionLoading,
    active: tokenExists,
  }));

  const updateSession = useStateMergeUpdater(setSession);

  useMount(() => {
    if (session.ready) return;

    (async () => {
      const active = await authTokenPromise;

      updateSession({
        ready: true,
        loading: false,
        active,
      });
    })();
  });

  const retrieveAuthToken = useEvent(async () => {
    updateSession({ loading: true });
    const active = await localServerApi.auth.retrieveAuthToken();
    updateSession({ loading: false, active });
  });

  const clearSession = useEvent(() => updateSession({ active: false }));

  const context = React.useMemo(
    () => ({
      ...session,
      retrieveAuthToken,
      clearSession,
    }),
    [session, retrieveAuthToken, clearSession],
  );

  return (
    <ThemeProvider>
      <Context.Provider value={context}>
        {session.ready ? <>{children}</> : <LoadingContent />}
      </Context.Provider>
    </ThemeProvider>
  );
}

export const withSessionContext = consumerToHOC(
  Context.Consumer,
  'sessionContext',
);
export const useSessionContext = () => React.useContext(Context);
export default Context;
