import { useContext, useMemo } from 'react';

import SegmentedControl from '@/components/forms/SegmentedControl';

import FilterContext from '@/context/Filter';
import { useTranslation } from '@/context/I18n';

import useEvent from '@/hooks/useEvent';

const ModeFilterToggle = ({ small, mini, name }) => {
  const { filters, updateFilters } = useContext(FilterContext);
  const { t } = useTranslation();

  let value = filters.bookingType;
  if (filters.bookingType === 'hourly' && filters.isOffice) {
    value = 'offices';
  }

  const onClick = useEvent((value) => {
    // Only update bookingType filter when it changes
    if (value === 'deskpass') {
      updateFilters({ bookingType: 'deskpass' });
    } else if (value === 'hourly') {
      updateFilters({ isOffice: false, bookingType: 'hourly' });
    } else {
      // Filter for rooms that can only be booked the entire day
      updateFilters({ isOffice: true, bookingType: 'hourly' });
    }
  });

  const options = useMemo(
    () => [
      {
        label: t('bookingLabels.daily'),
        value: 'deskpass',
      },
      {
        label: t('bookingLabels.hourly'),
        value: 'hourly',
      },
      {
        label: t('bookingLabels.office'),
        value: 'offices',
      },
    ],
    [t],
  );

  const segmentControlSize = small ? 'small' : mini ? 'mini' : undefined;

  return (
    <SegmentedControl
      name={name}
      value={value}
      size={segmentControlSize}
      options={options}
      onClick={onClick}
    />
  );
};

export default ModeFilterToggle;
