/**
 * These are used by fluidSize fn located at src/@/styles/js/utils/fluidSize.js.
 * These figures were originally generated by the fluid-size Sass include from
 * this thing: https://github.com/cmalven/sass-toolkit/blob/v2.4.7/_fluid-size.scss
 */
export default {
  none: {
    default: 0,
    max: 0,
    calcStatic: 0,
    calcFluid: 0,
  },
  'outer-m': {
    default: 25,
    max: 45,
    calcStatic: 20,
    calcFluid: 1.5625,
  },
  'outer-l': {
    default: 23,
    max: 65,
    calcStatic: 12.5,
    calcFluid: 3.28125,
  },
  'lay-s': {
    default: 25,
    max: 30,
    calcStatic: 23.75,
    calcFluid: 0.39063,
  },
  'lay-m': {
    default: 25,
    max: 40,
    calcStatic: 21.25,
    calcFluid: 1.17188,
  },
  'lay-l': {
    default: 30,
    max: 55,
    calcStatic: 23.75,
    calcFluid: 1.95313,
  },
  'lay-xl': {
    default: 40,
    max: 75,
    calcStatic: 31.25,
    calcFluid: 2.73438,
  },
  content: {
    default: 26,
    max: 28,
    calcStatic: 25.5,
    calcFluid: 0.15625,
  },
};
