import styled, { css } from 'styled-components/macro';

import TextLink from '@/components/buttons-links/TextLink';

export const WhenFilterOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
`;

export const ClearDateLink = styled(TextLink)`
  align-self: flex-start;

  ${({ visible }) => !visible && css`
    visibility: hidden;
  `}
`;
