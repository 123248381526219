import moment from 'moment-timezone';
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import 'styled-components/macro';

import { ScrollableArea } from '@/components/bits/ScrollableArea';
import CompanyLogo from '@/components/brand/CompanyLogo';
import TextLink from '@/components/buttons-links/TextLink';
import AmenityFilterOptions from '@/components/filters/AmenityFilterOptions';
import FilterButton from '@/components/filters/FilterButton';
import FavoriteFilterButton from '@/components/filters/FilterButton/Favorite';
import FilterMenu from '@/components/filters/FilterMenu';
import GeocodingFilter from '@/components/filters/GeocodingFilter';
import GroupSizeFilterOptions from '@/components/filters/GroupSizeFilterOptions';
import ListOrMapToggle from '@/components/filters/ListOrMapToggle';
import ModeFilterToggle from '@/components/filters/ModeFilterToggle';
import MoodFilterOptions from '@/components/filters/MoodFilterOptions';
import WhenFilterOptions from '@/components/filters/WhenFilterOptions';

import { useAmenityContext } from '@/context/Amenity';
import { useFilterContext } from '@/context/Filter';
import { useMapContext } from '@/context/Map';
import { useRouterContext } from '@/context/Router';

import useEvent from '@/hooks/useEvent';
import useMediaQuery from '@/hooks/useMediaQuery';
import useOnRouteChange from '@/hooks/useOnRouteChange';
import useRoutePathMatcher from '@/hooks/useRoutePathMatcher';

import { DATE_NO_TIME_FORMAT, formatDateShort } from '@/lib/dateHelpers';
import route, { pathMatchRouteName } from '@/lib/routes';

import * as S from './index.styles';

const _parseOfficeDate = (date) => {
  return formatDateShort(moment(date, DATE_NO_TIME_FORMAT), false, true);
};

const FiltersNavbarContext = createContext({});
const { Provider: FiltersNavbarProvider } = FiltersNavbarContext;
const useFiltersNavbarContext = () => useContext(FiltersNavbarContext);

const FiltersNavbar = memo(({ shadow }) => {
  const large = useMediaQuery({ min: 'large' });
  const inSpaceListRoute = useRoutePathMatcher(['spaceList']);

  const { filters, updateFilters } = useFilterContext();
  const { slugToName, roomSlugToName } = useAmenityContext();

  const [selectedFilter, setSelectedFilter] = useState(() => null);

  const closeFilterMenu = useEvent(() => setSelectedFilter(null));

  const toggleFilter = useCallback(
    (filter) => (e) => {
      e.stopPropagation();
      setSelectedFilter((prev) => (prev === filter ? null : filter));
    },
    [],
  );

  const toggleWhenFilter = useEvent(toggleFilter('when'));
  const toggleAmenitiesFilter = useEvent(toggleFilter('amenities'));
  const toggleMoodFilter = useEvent(toggleFilter('mood'));
  const toggleCapacityFilter = useEvent(toggleFilter('capacity'));
  const toggleInstantBookingFilter = useEvent(() => {
    updateFilters({ instantBooking: !instantBooking });
    toggleFilter('instantBooking');
  });

  const {
    start,
    end,
    date,
    amenities,
    instantBooking,
    mood,
    capacity,
    bookingType,
    isOffice,
  } = filters;
  const roomMode = bookingType !== 'deskpass';

  const whenFilterButtonValue = useMemo(() => {
    if (!date && !start) {
      return 'Date/Time';
    }

    let dateString;

    if (!isOffice) {
      dateString = formatDateShort(date, false, true);
      // If both start and end are set, tack those on
      if (start && end) {
        dateString += `, ${start}-${end}`;
      }
    } else {
      dateString = `From ${_parseOfficeDate(start)}`;
      if (end) {
        dateString += ` to ${_parseOfficeDate(end)}`;
      }
    }

    return dateString;
  }, [start, end, date, isOffice]);

  const amenityValue = useMemo(() => {
    const mapper = inSpaceListRoute ? slugToName : roomSlugToName;
    const amenityNames = amenities.map(mapper);

    if (!amenityNames.length) {
      return 'Amenities';
    }

    if (amenityNames.length === 1) {
      return `1 amenity`;
    }

    if (amenityNames.length > 1) {
      return `${amenityNames.length} amenities`;
    }

    return amenityNames.join(', ');
  }, [amenities, inSpaceListRoute, roomSlugToName, slugToName]);

  const moodValue = useMemo(
    () => (mood ? slugToName(mood) : 'Mood'),
    [mood, slugToName],
  );

  const capacityValue = useMemo(() => {
    if (!capacity || capacity <= 1) {
      return 'Group Size';
    }

    return `${capacity} People`;
  }, [capacity]);

  const ctx = useMemo(
    () => ({
      // State
      roomMode,
      whenFilterButtonValue,
      moodValue,
      capacityValue,
      amenityValue,
      selectedFilter,

      // Actions
      toggleWhenFilter,
      toggleAmenitiesFilter,
      toggleMoodFilter,
      toggleCapacityFilter,
      toggleInstantBookingFilter,
      closeFilterMenu,
    }),
    [
      roomMode,
      whenFilterButtonValue,
      moodValue,
      capacityValue,
      amenityValue,
      selectedFilter,
      toggleWhenFilter,
      toggleAmenitiesFilter,
      toggleMoodFilter,
      toggleCapacityFilter,
      toggleInstantBookingFilter,
      closeFilterMenu,
    ],
  );

  return (
    <FiltersNavbarProvider value={ctx}>
      {large && <DesktopFiltersNavbar shadow={shadow} />}

      {!large && <MobileFiltersNavbar shadow={shadow} />}
    </FiltersNavbarProvider>
  );
});

export const MobileFiltersNavbar = memo(({ shadow }) => {
  const { filters, resetFilters } = useFilterContext();
  const { setLastMainMobileRoute } = useRouterContext();

  const { replace: replaceRoute } = useHistory();
  const { setActiveMarkerId } = useMapContext();

  const goToIndex = useEvent(() => {
    replaceRoute(route('index'));
  });

  const { start, date, amenities, instantBooking, mood, capacity } = filters;

  const {
    // State
    roomMode,
    whenFilterButtonValue,
    moodValue,
    capacityValue,
    amenityValue,
    selectedFilter,

    // Actions
    toggleWhenFilter,
    toggleAmenitiesFilter,
    toggleMoodFilter,
    toggleCapacityFilter,
    toggleInstantBookingFilter,
    closeFilterMenu,
  } = useFiltersNavbarContext();

  // Save the last route where filter was visible. Room/Space List/Map
  // this is useful on mobile to help the "Spaces" button navigate back
  // to the last route the user was in.
  useOnRouteChange(({ pathname }) => {
    const inListOrMapRoutes = [
      'spaceMap',
      'spaceList',
      'roomMap',
      'roomList',
    ].some((routeName) => pathMatchRouteName(pathname, routeName));

    if (!inListOrMapRoutes) {
      return;
    }

    let nextRoute = pathname.includes('list') ? 'spaceList' : 'spaceMap';

    if (pathname.includes('room')) {
      nextRoute = pathname.includes('list') ? 'roomList' : 'roomMap';
    }

    setLastMainMobileRoute(nextRoute);
  });

  return (
    <S.FiltersNavbar shadow={shadow}>
      <S.FilterNav asAppBar>
        <S.MainNav>
          <S.LogoLink
            to={route('index')}
            onClick={(e) => {
              e.stopPropagation();

              setActiveMarkerId(null);
              goToIndex();
            }}
          >
            <CompanyLogo simplified />
          </S.LogoLink>

          <ModeFilterToggle name="viewType" mini />

          <ListOrMapToggle />
        </S.MainNav>
      </S.FilterNav>

      <S.FilterNav>
        <S.SearchNav>
          <GeocodingFilter />
        </S.SearchNav>

        <FilterMenu
          open={!!selectedFilter && selectedFilter !== 'instantBooking'}
          onClickOutside={closeFilterMenu}
          menu={
            <>
              {selectedFilter === 'when' && <WhenFilterOptions />}
              {selectedFilter === 'amenities' && <AmenityFilterOptions />}
              {selectedFilter === 'mood' && <MoodFilterOptions />}
              {selectedFilter === 'capacity' && <GroupSizeFilterOptions />}
            </>
          }
        >
          <ScrollableArea>
            <FavoriteFilterButton />

            <FilterButton
              active={!!date || !!start}
              selected={selectedFilter === 'when'}
              onClick={toggleWhenFilter}
              icon="clock-24"
            >
              {whenFilterButtonValue}
            </FilterButton>

            {roomMode && (
              <FilterButton
                active={!!capacity && capacity !== '1'}
                selected={selectedFilter === 'capacity'}
                onClick={toggleCapacityFilter}
                icon="person-17"
              >
                {capacityValue}
              </FilterButton>
            )}

            {roomMode && (
              <FilterButton
                active={!!instantBooking}
                selected={selectedFilter === 'instantBooking'}
                onClick={toggleInstantBookingFilter}
                icon="bolt-24"
              >
                Book Instantly
              </FilterButton>
            )}

            <FilterButton
              active={!!amenities.length}
              selected={selectedFilter === 'amenities'}
              onClick={toggleAmenitiesFilter}
              icon="coffee-24"
            >
              {amenityValue}
            </FilterButton>

            <FilterButton
              active={!!mood}
              selected={selectedFilter === 'mood'}
              onClick={toggleMoodFilter}
              icon="sun-24"
            >
              {moodValue}
            </FilterButton>

            <TextLink size="mini" nowrap underlined onClick={resetFilters}>
              Reset Filters
            </TextLink>
          </ScrollableArea>
        </FilterMenu>
      </S.FilterNav>
    </S.FiltersNavbar>
  );
});

export const DesktopFiltersNavbar = memo(({ shadow }) => {
  const { filters, resetFilters } = useFilterContext();
  const { setLastMainMobileRoute } = useRouterContext();

  const { start, date, amenities, instantBooking, mood, capacity } = filters;

  const {
    // State
    roomMode,
    whenFilterButtonValue,
    moodValue,
    capacityValue,
    amenityValue,
    selectedFilter,

    // Actions
    toggleWhenFilter,
    toggleAmenitiesFilter,
    toggleMoodFilter,
    toggleCapacityFilter,
    toggleInstantBookingFilter,
    closeFilterMenu,
  } = useFiltersNavbarContext();

  // Save the last route where filter was visible. Room/Space List/Map
  // this is useful on mobile to help the "Spaces" button navigate back
  // to the last route the user was in.
  useOnRouteChange(({ pathname }) => {
    const inListOrMapRoutes = [
      'spaceMap',
      'spaceList',
      'roomMap',
      'roomList',
    ].some((routeName) => pathMatchRouteName(pathname, routeName));

    if (!inListOrMapRoutes) {
      return;
    }

    let nextRoute = pathname.includes('list') ? 'spaceList' : 'spaceMap';

    if (pathname.includes('room')) {
      nextRoute = pathname.includes('list') ? 'roomList' : 'roomMap';
    }

    setLastMainMobileRoute(nextRoute);
  });

  return (
    <S.FiltersNavbar shadow={shadow}>
      <S.DesktopFilterMenu
        open={!!selectedFilter && selectedFilter !== 'instantBooking'}
        onClickOutside={closeFilterMenu}
        menu={
          <>
            {selectedFilter === 'when' && <WhenFilterOptions />}
            {selectedFilter === 'amenities' && <AmenityFilterOptions />}
            {selectedFilter === 'mood' && <MoodFilterOptions />}
            {selectedFilter === 'capacity' && <GroupSizeFilterOptions />}
          </>
        }
      >
        <ModeFilterToggle name="viewType" small />

        <FavoriteFilterButton />

        <FilterButton
          active={!!date || !!start}
          selected={selectedFilter === 'when'}
          onClick={toggleWhenFilter}
          icon="clock-24"
        >
          {whenFilterButtonValue}
        </FilterButton>

        {roomMode && (
          <FilterButton
            active={!!capacity && capacity !== '1'}
            selected={selectedFilter === 'capacity'}
            onClick={toggleCapacityFilter}
            icon="person-17"
          >
            {capacityValue}
          </FilterButton>
        )}

        {roomMode && (
          <FilterButton
            active={!!instantBooking}
            selected={selectedFilter === 'instantBooking'}
            onClick={toggleInstantBookingFilter}
            icon="bolt-24"
          >
            Book Instantly
          </FilterButton>
        )}

        <FilterButton
          active={!!amenities.length}
          selected={selectedFilter === 'amenities'}
          onClick={toggleAmenitiesFilter}
          icon="coffee-24"
        >
          {amenityValue}
        </FilterButton>

        <FilterButton
          active={!!mood}
          selected={selectedFilter === 'mood'}
          onClick={toggleMoodFilter}
          icon="sun-24"
        >
          {moodValue}
        </FilterButton>

        <TextLink size="mini" nowrap underlined onClick={resetFilters}>
          Reset Filters
        </TextLink>
      </S.DesktopFilterMenu>
    </S.FiltersNavbar>
  );
});

export default FiltersNavbar;
