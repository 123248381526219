import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Icon from '@/components/graphics/Icon';
import SvgUse from '@/components/graphics/SvgUse';

import typeStyle from '@/styles/js/fonts/typeStyles';

import { Icon as StyledIcon } from '../graphics/Icon.styles';

/**
 * Color styles
 * @param {String} color - `active | inactive | warning | reverse | special`
 */
const getColorStyles = ({ theme, color }) =>
  ({
    active: css`
      > ${StyledIcon}:first-child {
        color: ${theme.colors.accent};
      }

      span:last-child {
        color: ${theme.colors.accent};
      }
    `,
    inactive: css`
      span:last-child {
        color: ${theme.colors.textLight};
      }
    `,
    warning: css`
      > ${StyledIcon}:first-child {
        color: ${theme.colors.red};
      }

      span:last-child {
        color: ${theme.colors.red};
      }
    `,
    reverse: css`
      color: white;

      > ${StyledIcon}:first-child {
        color: ${theme.colors.white};
      }
    `,
    special: css`
      span:last-child {
        color: ${theme.colors.primaryDark};
      }
    `,
  }[color]);

/**
 * Styled Label
 * ---
 * An identifying label for a piece of content
 *
 * Optional props
 * - `color`
 *    - `active` - Stronger appearance
 *    - `inactive` - Weaker appearance
 *    - `warning` - Alert the user
 *    - `reverse` - For use on dark backgrounds
 *    - `special`
 */
export const StyledLabel = styled.p`
  ${typeStyle('micro')}

  color: ${({ theme }) => theme.colors.text};
  display: inline-block;

  > ${StyledIcon}:first-child {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    margin-top: 0.1rem;
    color: ${({ theme }) => theme.colors.iconsLight};
  }

  > svg {
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.9rem;
  }

  span:last-child {
    vertical-align: middle;
  }

  > a,
  > span > a {
    text-decoration: underline;
  }

  ${getColorStyles}
`;

/**
 * A small text element that communicates some qualities
 * of the content it is associated with, including an
 * optional icon.
 */
const Label = ({ children, icon, inProgress, ...other }) => (
  <StyledLabel {...other}>
    {inProgress ? (
      <SvgUse id="active-checkbox" category="full" />
    ) : icon ? (
      <Icon type={icon} />
    ) : null}
    <span>{children}</span>
  </StyledLabel>
);

Label.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  color: PropTypes.oneOf([
    'active',
    'inactive',
    'warning',
    'reverse',
    'special',
  ]),
  inProgress: PropTypes.bool,
};

export default Label;
