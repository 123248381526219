import { createGlobalStyle, css } from 'styled-components/macro';

// TODO Remove CSS variables when we're done with the CSS in JS conversion
const CSSVariables = createGlobalStyle`
  ${({ theme: { colors } }) => css`
    :root {
      --primary-color: ${colors.primary};
      --primary-dark-color: ${colors.primaryDark};
      --primary-tint-color: ${colors.primaryTint};
      --primary-tint-light-color: ${colors.primaryTintLight};
      --accent-color: ${colors.accent};
      --accent-faint-color: ${colors.accentFaint};
      --accent-whitened-color: ${colors.accentWhitened};
      --accent-transparent-color: ${colors.accentTransparent};
      --accent-transparent-dark-color: ${colors.accentTransparentDark};
      --accent-transparent-light-color: ${colors.accentTransparentLight};
    }
  `}
`;

export default CSSVariables;
