const units = {
  zIndex: {
    modal: 500,
    navBar: 600,
    navSmall: 20,
    notifications: 700,
    persistentNotifications: 480,
    menu: 110,
    siteHeader: 100,
    filtersNavbar: 90,
    geocodingSearch: 30,
    intercom: 475,
  },

  // TODO temporary while components get migrated
  components: {
    // Nav
    navSmallerHeight: '36px',
    navSmallHeight: '53px',
    navLargeHeight: '82px',

    // Filter
    filterLargeHeight: '115px',
    filterSmallHeight: '105px',
  },
};

export default units;
