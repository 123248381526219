// import the babel macro here to process css prop
import 'styled-components/macro';

import useOutsideClick from '@/hooks/useOutsideClick';

import * as Styled from './index.styles';

const FilterMenu = ({
  open,
  onClickOutside,
  menu,
  children,
  wrapper,
  ...props
}) => {
  const ref = useOutsideClick(onClickOutside);

  let extraProps = {};

  if (wrapper) extraProps.as = wrapper;

  return (
    <Styled.Wrapper {...props} {...extraProps}>
      {children}

      {open && <Styled.Menu ref={ref}>{menu}</Styled.Menu>}
    </Styled.Wrapper>
  );
};

export default FilterMenu;
