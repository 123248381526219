import FormDatePicker from '@/components/forms/FormDatePicker';

import useWhenFilterProps from './useWhenFilterProps';

const DeskWhenFilter = () => {
  const {
    filters: { date },
    handleDayClick,
    disabledDays,
  } = useWhenFilterProps();

  return (
    <FormDatePicker
      onDayClick={handleDayClick}
      value={date?.toDate?.()}
      disabledDays={disabledDays}
    />
  );
};

export default DeskWhenFilter;
