import { Helmet } from 'react-helmet';

import GenericError from '@/components/error-handling/GenericError';

const ServerUnavailable = () => {
  const message =
    "We're having a problem loading Deskpass right now. Please try again later.";
  return (
    <>
      <Helmet>
        <title>Server Unavailable</title>
      </Helmet>

      <GenericError message={message} />
    </>
  );
};

export default ServerUnavailable;
