const validIcons = [
  'checkmark',
  '24-hours-24',
  'accessibility-24',
  'add-cal-24',
  'baby-24',
  'bike-24',
  'booze-24',
  'bullhorn-24',
  'bus-24',
  'calendar-24',
  'car-24',
  'chat-24',
  'chat-alt-24',
  'clock-24',
  'coffee-24',
  'cold-24',
  'desks-24',
  'edit-24',
  'ellipse-24',
  'filter-24',
  'food-24',
  'grid-24',
  'grid-hollow-24',
  'kitchen-24',
  'location-24',
  'lock-24',
  'lockers-24',
  'mail-24',
  'map-24',
  'microwave-24',
  'my-location-24',
  'night-24',
  'outdoor-24',
  'phone-24',
  'present-24',
  'printer-24',
  'private-24',
  'rail-24',
  'receipt-24',
  'search-24',
  'showers-24',
  'snacks-24',
  'space-24',
  'tv-24',
  'view-24',
  'weekends-24',
  'wifi-24',

  'arrow-left-24',
  'arrow-right-24',
  'arrow-small-left-24',
  'arrow-small-right-24',
  'carat-down-24',
  'carat-up-24',
  'carat-left-24',
  'carat-right-24',
  'carat-right-small-24',
  'close-x-24',
  'close-x-small-24',
  'processing-24',
  'minus-circle-24',
  'plus-circle-24',
  'up-down-scroll-24',

  'facebook-24',
  'twitter-24',
  'instagram-24',

  'arrow-right-17',
  'bullhorn-17',
  'calendar-17',
  'contact-17',
  'credit-card-17',
  'filter-17',
  'clock-17',
  'key-17',
  'marker-17',
  'person-17',
  'question-mark-17',
  'star-17',
  'close-x-17',
  'exclamation-17',
  'exclamation-24',
  'user-14',
  'menu-24',
];

const getSizeModifier = function (type) {
  const split = type.split('-');
  return split[split.length - 1];
};

export { validIcons, getSizeModifier };
