const methods = ['get', 'post', 'put', 'patch', 'delete', 'head', 'options'];

class Driver {
  constructor(args = {}) {
    const { getInstance, requestMapper, ...config } = args;

    if (!getInstance) {
      throw new Error('Driver "instance" must be initialized.');
    }

    if (typeof requestMapper !== 'function') {
      throw new Error('Driver "requestMapper" must be specified.');
    }

    this.getInstance = getInstance;
    this.config = config;

    methods.forEach((method) => {
      this[method] = requestMapper({ getInstance, config, method });
    });
  }
}

export default Driver;
