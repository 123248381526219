import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { commonLinkProps } from '@/components/buttons-links/CommonLink';

import * as S from './TextLink.styles';

/**
 * Displays a very subtle, text-based link with optional
 * icon and arrow.
 *
 * props
 * - `<CommonLink /> props`
 * - `size`
 *    - `small` - A smaller version
 *    - `mini` - An even smaller version
 *    - `large`
 * - `color`
 *    - `primary`
 *    - `warning` - Indicates a destructive or important action
 *    - `reverse` - For use on dark backgrounds
 *    - `space-subnav-small` - Exclusive styling for SpaceSubnavSmall
 * - `arrow` - Includes an arrow icon
 * - `bold` - bool
 * - `underlined` - bool
 * - `nowrap` - bool
 */
const TextLink = forwardRef(({ children, className, icon, ...other }, ref) => (
  <S.TextLink
    ref={ref}
    className={className}
    iconBefore={icon}
    iconAfter={other.arrow ? 'arrow-right-17' : null}
    {...other}
  >
    {children}
  </S.TextLink>
));

TextLink.propTypes = Object.assign(commonLinkProps, {
  icon: PropTypes.string,
  arrow: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  underlined: PropTypes.bool,
  nowrap: PropTypes.bool,
  bold: PropTypes.bool,
});

export default TextLink;
