import { dashedToCammelCase } from '@/lib/utility';

/*
 * Helps convert props with names that are - separated into cammel case names.
 *
 * This is just meant to make legacy code conversion less tedious.
 */
const useMapOldDashedProps = (props, propNameArray = []) => {
  return Object.keys(props).reduce((acc, propKey) => {
    if (propNameArray.includes(propKey)) {
      const camelCasePropName = dashedToCammelCase(propKey);

      return {
        ...acc,
        [camelCasePropName]: props[propKey],
      };
    }

    return {
      ...acc,
      [propKey]: props[propKey],
    };
  }, {});
};

export default useMapOldDashedProps;
