import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Mq from '@/components/helpers/Mq';
import Notification from '@/components/notifications/Notification';

import UserContext from '@/context/User';

import route, { pathMatchRouteName } from '@/lib/routes';

const SignupCTANotification = () => {
  const { authenticated } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  const onBannerClick = () => history.push(route('signup'));

  const notInRoutes = ![
    'guestInvitationIndex',
    'teamSignup',
    'teamSignupProfile',
    'signup',
  ].some((it) => pathMatchRouteName(location.pathname, it));

  const shouldShowBanner = [!authenticated, notInRoutes].every(Boolean);

  return (
    <Mq min="large">
      {shouldShowBanner && (
        <Notification icon="arrow" onClick={onBannerClick}>
          Get instant desks, meeting rooms, and private offices around the
          globe. Simply select and go!
        </Notification>
      )}
    </Mq>
  );
};

export default SignupCTANotification;
