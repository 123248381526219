import PropTypes from 'prop-types';
import { Redirect, Route, withRouter } from 'react-router-dom';

import { withUserContext } from '@/context/User';

import { compose, mapProps } from '@/lib/hoc';
import route from '@/lib/routes';

const PrivateRoute = ({ authenticated, redirectTo, ...remainingProps }) => {
  if (authenticated) {
    return <Route {...remainingProps} />;
  }

  return (
    <Redirect
      to={{
        pathname: redirectTo,
        state: {
          redirectBackTo: remainingProps.location.pathname,
        },
      }}
    />
  );
};

PrivateRoute.defaultProps = {
  redirectTo: route('login'),
};

PrivateRoute.propTypes = {
  ...Route.propTypes,
  redirectTo: PropTypes.string,
};

export { PrivateRoute };

export default compose(
  withUserContext,
  withRouter,
  mapProps(({ userContext, ...remainingProps }) => ({
    authenticated: userContext.authenticated,
    ...remainingProps,
  })),
)(PrivateRoute);
