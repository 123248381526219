const defaultOptions = {
  retryCount: 5,
  interval: 1000,
  backoffPolicy: null,
  retryIf: null,
  onErrorIgnore: null,
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export class RetryError extends Error {
  constructor(message, originalError = null) {
    super(message);
    this.originalError = originalError;
  }
}

/*
 * Add retry capability to any async function that returns Promise.
 */
function addRetry(asyncFn, options = {}) {
  const { retryIf, onErrorIgnore, backoffPolicy, ..._opts } = {
    ...defaultOptions,
    ...options,
  };

  return async function wrappedAsyncFn(...args) {
    const retry = async ({ retriesLeft, currentInterval }) => {
      try {
        const result = await asyncFn(...args);

        const shouldRetryIf = typeof retryIf === 'function' && retryIf(result);

        if (shouldRetryIf) {
          throw new RetryError('Forced retry through "retryIf"');
        }

        return result;
      } catch (err) {
        const shouldIgnoreErrorCase =
          typeof onErrorIgnore === 'function' && onErrorIgnore(err);

        if (shouldIgnoreErrorCase) {
          throw err;
        }

        if (retriesLeft !== Infinity && retriesLeft === 1) {
          console.error('Maximum retries exceeded', err);

          throw new RetryError('maximum retries exceeded', err);
        }

        if (typeof backoffPolicy === 'function') {
          currentInterval = Math.max(
            backoffPolicy(currentInterval),
            currentInterval,
          );
        }

        await sleep(currentInterval);

        return retry({
          retriesLeft: retriesLeft - 1,
          currentInterval,
        });
      }
    };

    return await retry({
      retriesLeft: _opts.retryCount,
      currentInterval: _opts.interval,
    });
  };
}

export default addRetry;
