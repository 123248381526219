import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import FiltersNavbar from '@/components/filters/FiltersNavbar';
import Mq from '@/components/helpers/Mq';
import NavLarge from '@/components/nav/NavLarge';

import route, { pathMatchRouteName } from '@/lib/routes';

import * as S from './index.styles';

function Menu() {
  const { pathname } = useLocation();

  const matchesPathname = React.useCallback(
    (it) => pathMatchRouteName(pathname, it),
    [pathname],
  );

  const showSimpleNav = ['guestInvitationIndex'].some((it) =>
    pathMatchRouteName(location.pathname, it),
  );

  // When showing only the map on mobile, add shadow to the filter
  const filterShadow = React.useMemo(
    () => ['roomMap', 'spaceMap'].some(matchesPathname),
    [matchesPathname],
  );

  const routesWithFilter = React.useMemo(
    () => ['roomMap', 'spaceMap', 'spaceList', 'roomList'].map(route),
    [],
  );

  return (
    <S.Menu>
      <Mq min="large">
        <NavLarge simple={showSimpleNav} />
      </Mq>

      <Switch>
        <Route exact path={routesWithFilter}>
          <FiltersNavbar shadow={filterShadow} />
        </Route>
      </Switch>
    </S.Menu>
  );
}

export default Menu;
