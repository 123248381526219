const defaultOptions = {
  ratio: 100,
  negative: false,
};

const scale = (width, options = {}, mode) => {
  const _options = {
    ...defaultOptions,
    ...options,
  };

  let formula = `${width} + 1 * (100${mode} / ${_options.ratio})`;

  if (_options.negative) {
    formula = `-1 * (${formula})`;
  }

  let result = `calc(${formula})`;

  if (_options.max) {
    result = `min(${result}, ${_options.max})`;
  }

  return result;
};

export const scaleWithVW = (width, options = {}) => {
  return scale(width, options, 'vw');
};

export const scaleWithVH = (width, options = {}) => {
  return scale(width, options, 'vh');
};
