import { createContext, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import useWatch from '@/hooks/useWatch';

import { consumerToHOC } from '@/lib/hoc';
import { pathMatchRouteName } from '@/lib/routes';

const Context = createContext({});

/*
 * Hold current space or room while a detail page is opened
 */
export function CurrentRoomOrSpaceProvider({ children }) {
  const { pathname } = useLocation();

  const [isRedirect, setIsRedirect] = useState(false);

  const inSpaceDetailPage = useMemo(
    () => _isInSpaceDetailPage(pathname),
    [pathname],
  );

  const inRoomDetailPage = useMemo(
    () => _isInRoomDetailPage(pathname),
    [pathname],
  );

  const [spaceSlug, roomSlug] = useMemo(
    () => _extractSlugsFromPathname(pathname),
    [pathname],
  );

  const [
    {
      ready: spaceReady,
      loading: spaceLoading,
      data: currentSpace,
      error: currentSpaceError,
    },
    fetchCurrentSpace,
    { updateState: updateCurrentSpace },
  ] = useDeskpassAPI(
    (api) =>
      (...args) => {
        const space = api.space.getOne(...(args.length ? args : [spaceSlug]));

        setIsRedirect(space && space.slug !== spaceSlug);

        return space;
      },
    { fireOnMount: false },
  );

  const [
    {
      ready: roomReady,
      loading: roomLoading,
      data: currentRoom,
      error: currentRoomError,
    },
    fetchCurrentRoom,
    { updateState: updateCurrentRoom },
  ] = useDeskpassAPI(
    (api) =>
      (...args) => {
        const room = api.room.getOne(
          ...(args.length ? args : [roomSlug, spaceSlug]),
        );

        setIsRedirect(room && room.slug !== roomSlug);

        return room;
      },
    { fireOnMount: false },
  );

  const ready = spaceReady || roomReady;
  const loading = spaceLoading || roomLoading;
  const inDetailPage = inSpaceDetailPage || inRoomDetailPage;

  /*
   * Reset state every time the pathname changes
   */
  useWatch(() => {
    if (inDetailPage) return;

    const emptyState = {
      error: undefined,
      data: undefined,
      ready: false,
      loading: false,
    };

    updateCurrentSpace({ ...emptyState });
    updateCurrentRoom({ ...emptyState });
  }, [inDetailPage]);

  /*
   * Fetches space when getting in a detail page or changing space slug
   */
  useWatch(() => {
    if (inSpaceDetailPage) {
      try {
        fetchCurrentSpace();
      } catch (err) {
        console.warn('Error fetching current space', err);
      }
    }
  }, [inSpaceDetailPage, spaceSlug]);

  /*
   * Fetches room when getting in a detail page or changing space/room slug
   */
  useWatch(() => {
    if (inRoomDetailPage) {
      try {
        fetchCurrentRoom();
      } catch (err) {
        console.warn('Error fetching current room', err);
      }
    }
  }, [inRoomDetailPage, spaceSlug, roomSlug]);

  const context = useMemo(
    () => ({
      isRedirect,
      inDetailPage,
      inSpaceDetailPage,
      inRoomDetailPage,
      ready,
      spaceReady,
      roomReady,
      loading,
      spaceLoading,
      roomLoading,
      currentSpace,
      currentSpaceError,
      fetchCurrentSpace,
      updateCurrentSpace,
      currentRoom,
      currentRoomError,
      fetchCurrentRoom,
      updateCurrentRoom,
      oldOrSubmittedSpaceSlug: spaceSlug,
      oldOrSubmittedRoomSlug: roomSlug,
    }),
    [
      isRedirect,
      inDetailPage,
      inSpaceDetailPage,
      inRoomDetailPage,
      ready,
      spaceReady,
      roomReady,
      loading,
      spaceLoading,
      roomLoading,
      currentSpace,
      currentSpaceError,
      fetchCurrentSpace,
      updateCurrentSpace,
      currentRoom,
      currentRoomError,
      fetchCurrentRoom,
      updateCurrentRoom,
      spaceSlug,
      roomSlug,
    ],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

function _extractSlugsFromPathname(pathname) {
  const [spaceSlug, roomSlug] = pathname
    .replace(/(\/spaces\/|\/rooms\/)/, '')
    .split('/');

  return [spaceSlug, roomSlug];
}

function _somePathMatchRouteName(pathname, routes = []) {
  return routes.some((routeName) => pathMatchRouteName(pathname, routeName));
}

function _isInSpaceDetailPage(pathname) {
  return _somePathMatchRouteName(pathname, [
    'spaceDetail',
    'spaceReserve',
    'spaceReserveConfirm',
  ]);
}

function _isInRoomDetailPage(pathname) {
  return _somePathMatchRouteName(pathname, [
    'roomDetail',
    'roomReserve',
    'roomReserveConfirm',
  ]);
}

export const withCurrentRoomOrSpaceContext = consumerToHOC(
  Context.Consumer,
  'currentRoomOrSpaceContext',
);
export const useCurrentRoomOrSpaceContext = () => useContext(Context);

export default Context;
