import React from 'react';
import { useHistory } from 'react-router-dom';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import FilterContext from '@/context/Filter';
import GeolocationContext from '@/context/Geolocation';
import UserContext from '@/context/User';

import useEvent from '@/hooks/useEvent';

import route from '@/lib/routes';

const useGeocodingFilter = () => {
  const { push: pushRoute } = useHistory();

  const { coords, available } = React.useContext(GeolocationContext);
  const { authenticated, user } = React.useContext(UserContext);
  const {
    updateFilters,
    filters: { place },
  } = React.useContext(FilterContext);

  const [, getPlace] = useDeskpassAPI((api) => api.geocoding.getPlace, {
    fireOnMount: false,
  });

  const onSelect = useEvent(async (selectedItem) => {
    const { deskpassType, googlePlaceId, slug } = selectedItem;

    if (deskpassType === 'space') {
      updateFilters({ place: selectedItem });

      return pushRoute(route('spaceDetail', { spaceSlug: slug }));
    }

    const place = await getPlace(googlePlaceId);
    updateFilters({ place });
  });

  let geocodingParams = {};

  if (available) {
    geocodingParams.proximity = coords;
  } else if (authenticated && !!user.city) {
    const { lat, lng } = user.city;
    geocodingParams.proximity = { lat, lng };
  }

  return {
    place,
    onSelect,
    geocodingParams,
  };
};

export default useGeocodingFilter;
