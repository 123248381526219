/**
 * Given a URL and image transform args to apply, format the URL with the args
 * in the format Cloudflare wants. If the passed URL is not a Cloudflare Images
 * URL, the unmodified URL will simply be returned.
 *
 * @param {String} url Image URL to format
 * @param {Object} args Object of transformation args to apply to image
 *
 * @return {String} Returns image URL modified with correctly-formatted
 * transform args
 */
export function getCloudflareImageURL(url, args = {}) {
  // If no transformation is being applied or image is not a Cloudflare Image
  // URL, just return the unmodified URL
  if (!Object.keys(args).length || !url?.includes('imagedelivery.net')) {
    return url;
  }

  // Remove trailing slash if around as well as default variant name if around
  // (you can't append transform args if the URL has the variant on the end,
  // you'll just get a 404)
  return (
    url.replace(/\/$/, '').replace(/\/default$/, '') +
    '/' +
    // Format args as Cloudflare is expecting them (key1=value1,key2=value2,
    // etc.)
    Object.entries(args)
      .map((v) => v.join('='))
      .join(',')
  );
}

/**
 * Provides the correct ratio (width/height) for
 * a given image transform
 *
 * @param    {string}  transform  The transform
 * @return   {object}  Object containing the width + height
 */
const imageRatioForTransform = (transform) => {
  const ratios = {
    square: {
      width: 10,
      height: 10,
    },
    space: {
      width: 16,
      height: 9,
    },
    spaceShort: {
      width: 16,
      height: 7,
    },
  };

  return ratios[transform];
};

/**
 * Returns a Base64-encoded preview image for
 * a given image transform
 *
 * @param    {string}  transform  The transform
 * @return   {string}  Base64-encoded string of image
 */
const imagePlaceholderForTransform = (transform) => {
  const ratios = {
    square:
      'iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKAQAAAAClSfIQAAAAAnRSTlMAAQGU/a4AAAANSURBVHgBY/h/AA8CAAdSEXcIxUkmAAAAAElFTkSuQmCC',
    space:
      'iVBORw0KGgoAAAANSUhEUgAAABAAAAAJAQAAAAATUZGfAAAAAnRSTlMAAQGU/a4AAAANSURBVHgBY/j/HxcCAPIoEe+JMKgiAAAAAElFTkSuQmCC',
    spaceShort:
      'iVBORw0KGgoAAAANSUhEUgAAABAAAAAGAQAAAADiByNKAAAAAnRSTlMAAQGU/a4AAAAMSURBVHgBY/zPgIYAORgGAb5U22cAAAAASUVORK5CYII=',
  };

  return ratios[transform];
};

export { imageRatioForTransform, imagePlaceholderForTransform };
