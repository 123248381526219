import React from 'react';
import { Router } from 'react-router-dom';

import ServerUnavailable from '@/components/error-handling/top-level/ServerUnavailable';
import UnhandledError from '@/components/error-handling/top-level/UnhandledError';

import { ThemeProvider } from '@/context/Theme';

import { TopLevelErrorTypes } from '@/lib/errors';
import history from '@/lib/history';

const ERROR_VIEW_MAP = {
  [TopLevelErrorTypes.Unhandled]: UnhandledError,
  [TopLevelErrorTypes.AppSetupError]: ServerUnavailable,
};

/*
 * Renders a react component tree that is alternate branch and not the main one.
 *
 * Here we do all the graceful error handling for cases that should be
 * treated globally and when the main tree should not be rendered at all.
 *
 * Keep in mind that none of the app contexts will exist in here not even the Router
 * so the child components that are rendered here need to be context agnostic.
 *
 * To make our life easier, any component rendered as a child of this container
 * that uses client side routing will have it's routing converted into full
 * URL reloading to make sure that navigation always works without the need
 * to handle it on each component that is rendered in this tree branch.
 */
const ErrorContainer = ({ error }) => {
  const { listen: routeChangeSubscriber } = history;

  // Converts history API navigation into full URL reloads so that navigation always
  // work even though the main routes aren't defined into this component tree branch.
  React.useEffect(() => {
    const unsubscribe = routeChangeSubscriber(({ pathname, search }) => {
      window.location.replace(pathname + search);
    });

    return () => {
      if (unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [routeChangeSubscriber]);

  const ErrorView = ERROR_VIEW_MAP[error.type] || UnhandledError;

  let title, message;

  if (error.type) {
    title = error.title;
    message = error.message;
  }

  return (
    <ThemeProvider>
      <Router history={history}>
        <ErrorView title={title} message={message} />
      </Router>
    </ThemeProvider>
  );
};

export default ErrorContainer;
