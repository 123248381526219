import styled, { css } from 'styled-components';

import { TimeSet as StyledTimeSet } from '@/components/forms/TimeSet.styles';
import { TimeSpan as StyledTimeSpan } from '@/components/forms/TimeSpan.styles';

import fluidSize from '@/styles/js/utils/fluidSize';

const negativeMargin = css`calc((2.3rem + 1 * (100vw / 100)) * (-1));`;

/**
 * Styled LayoutHourlyTimeSelection
 * ---
 * Wraps the contents of date/time selection for an hourly reservation.
 */
export const StyledLayoutHourlyTimeSelection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${negativeMargin};
  margin-right: ${negativeMargin};
  margin-top: 0;

  > ${StyledTimeSet} {
    ${fluidSize('outer-m', 'padding-left')}
    ${fluidSize('outer-m', 'padding-right')}
  }

  > ${StyledTimeSpan} {
    min-height: 200px;
    flex: 1 0 auto;
  }
`;
/**
 * Wraps the contents of date/time selection
 * for an hourly reservation.
 */
const LayoutHourlyTimeSelection = ({ children }) => (
  <StyledLayoutHourlyTimeSelection>{children}</StyledLayoutHourlyTimeSelection>
);

LayoutHourlyTimeSelection.propTypes = {};

export default LayoutHourlyTimeSelection;
