import { AsYouType } from 'libphonenumber-js';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import Button from '@/components/buttons-links/Button';
import ActionSet from '@/components/forms/ActionSet';
import FormGroup from '@/components/forms/FormGroup';
import FormRow from '@/components/forms/FormRow';
import FormSelect from '@/components/forms/FormSelect';
import FormText from '@/components/forms/FormText';
import StripeCard, { useCard } from '@/components/forms/Stripe/Card';
import StripeCoupon from '@/components/forms/Stripe/Coupon';
import LoadingContent from '@/components/helpers/LoadingContent';
import Spacer from '@/components/layout/Spacer';
import ContentSection from '@/components/text-headings/ContentSection';
import Heading from '@/components/text-headings/Heading';
import Text from '@/components/text-headings/Text';
import {
  trackCreateUser,
  trackSubmitEmailMetrics,
} from '@/components/views/Signup/analytics';

import AnalyticsContext from '@/context/Analytics';
import CountryContext from '@/context/Country';
import NotificationContext from '@/context/Notification';
import RouterContext from '@/context/Router';
import UserContext from '@/context/User';

import useCities from '@/hooks/api/useCities';
import useEvent from '@/hooks/useEvent';
import useStateMergeUpdater from '@/hooks/useStateMergeUpdater';

import route from '@/lib/routes';
import { parseDigits, upperCaseEachFirstLetter } from '@/lib/utility';
import { isValidEmail, isValidPassword } from '@/lib/validation';

import * as Styled from './index.styles';

const fieldNames = [
  'email',
  'country',
  'firstName',
  'lastName',
  'password',
  'homeCity',
  'organization',
  'mobileNumber',
  'coupon',
];

function SignupForm({ noHeader = false }) {
  const { countries } = React.useContext(CountryContext);
  const analyticsContext = React.useContext(AnalyticsContext);
  const { login } = React.useContext(UserContext);
  const { parsedSearchParams } = React.useContext(RouterContext);
  const { create: createNotification } = React.useContext(NotificationContext);
  const { replace: replaceRoute } = useHistory();
  const { generateCardToken } = useCard();

  const [, signup] = useDeskpassAPI((api) => api.user.signup, {
    fireOnMount: false,
  });

  const [formFields, setFormFields] = React.useState(() => {
    /**
     * Checks for URL search fields
     */
    const searchFields = omit(pick(parsedSearchParams, fieldNames), 'password');
    const initialFormFields = fieldNames.reduce(
      (fields, fieldKey) => ({
        // Add default values to empty string
        [fieldKey]: '',
        // Keep fields populated from seach
        ...fields,
      }),
      searchFields,
    );

    return initialFormFields;
  });

  const [submitting, setSubmitting] = React.useState(() => false);

  const [formState, setFormState] = React.useState(() => {
    const initialDirtyFieldValues = fieldNames
      // Add card field from StripeCard to manage it's state here
      .concat('card')
      .reduce((acc, key) => ({ ...acc, [key]: false }), {});

    const initialErrorFieldValues = fieldNames
      // Add card field from StripeCard to manage it's state here
      .concat('card')
      // Remove optional fields
      .filter((name) => !['organization', 'coupon'].includes(name))
      .reduce(
        (acc, key) => ({
          // All required fields start with their error set.
          // It won't show cause field only show errors on
          // dirty fields. This way the valid state will
          // start as invalid until all errors are fixed.
          ...acc,
          [key]: 'This field is required',
        }),
        {},
      );

    return {
      errors: initialErrorFieldValues,
      dirtyFields: initialDirtyFieldValues,
    };
  });

  const updateFormFields = useStateMergeUpdater(setFormFields);
  const updateFormState = useStateMergeUpdater(setFormState);

  const {
    firstName,
    lastName,
    email,
    password,
    country,
    homeCity,
    organization,
    mobileNumber,
    coupon,
  } = formFields;

  const { errors, dirtyFields } = formState;

  const valid = React.useMemo(() => isEmpty(errors), [errors]);

  const setError = useEvent((field, message) => {
    let newErrors = { ...errors };

    newErrors[field] = message;

    // Delete message with falsy value. Removes error
    if (!message) {
      delete newErrors[field];
    }

    return updateFormState({ errors: newErrors });
  });

  const setDirty = useEvent((field) => {
    if (!dirtyFields[field]) {
      updateFormState({ dirtyFields: { ...dirtyFields, [field]: true } });
    }
  });

  const validateField = useEvent((name, value) => {
    // Ignore optional fields
    if (['organization', 'coupon'].includes(name)) return;

    if (!value) {
      return setError(name, 'This field is required');
    }

    if (name === 'password' && !isValidPassword(value)) {
      return setError(name, 'Must be at least 10 characters');
    }

    if (name === 'email' && !isValidEmail(value)) {
      return setError(name, 'Invalid email address');
    }

    // Removes error on field
    setError(name);
  });

  const onBlur = useEvent((evt) => {
    setDirty(evt.target.name);
  });

  const getCountryCode = useEvent((country) => {
    if (country) {
      return countries
        .find(({ id }) => id === Number(country))
        ?.code?.toUpperCase();
    }
  });

  const formatPhoneNumber = useEvent((value = '') => {
    const code = getCountryCode(country);
    const formatter = new AsYouType(code?.toUpperCase());
    return formatter.input(value);
  });

  const onChange = useEvent((evt) => {
    let { name, value } = evt.target;

    if (name === 'mobileNumber') {
      // Max number of digits should be 15
      if (parseDigits(value)?.length > 15) {
        return;
      }

      value = formatPhoneNumber(value);
    }

    if (name === 'email') {
      value = value.toLowerCase();
    }

    if (['firstName', 'lastName'].includes(name)) {
      value = upperCaseEachFirstLetter(value);
    }

    validateField(name, value.trim());

    updateFormFields({ [name]: value.trim() });
  });

  const handleSelectorChange = useEvent((name, value) => {
    setDirty(name);
    validateField(name, value);
    updateFormFields({ [name]: value });
  });

  const onCountryChange = useEvent((country) => {
    handleSelectorChange('country', country);
    const countryCode = getCountryCode(country);
    loadCities({ countryCode });
  });

  const onCityChange = useEvent((homeCity) => {
    handleSelectorChange('homeCity', homeCity);
  });

  const onCardInfoChange = useEvent(({ error }) => {
    if (error) {
      return setError('card', error.message);
    }

    // Removes error on field
    setError('card');
  });

  const onCardInfoBlur = useEvent(() => {
    setDirty('card');
  });

  const trackAnalytics = useEvent(
    ({ stripeCardToken, teamOrganizationId, isTeamUser, cityId }) => {
      const contexts = {
        analyticsContext,
        routerContext: { parsedSearchParams },
      };

      trackSubmitEmailMetrics({
        ...contexts,
        data: { email },
      });

      trackCreateUser({
        ...contexts,
        data: {
          ...formFields,
          cityId,
          stripeCardToken,
          teamOrganizationId,
          isTeamUser,
        },
      });
    },
  );

  const handleSubmit = useEvent(async () => {
    try {
      setSubmitting(true);

      const generatedToken = await generateCardToken();

      if (generatedToken?.error) {
        console.error(
          'Stripe error trying to create new user:',
          generatedToken?.error,
        );

        return setSubmitting(false);
      }

      const {
        token: { id: stripeCardToken },
      } = generatedToken;

      const signupPayload = {
        // Omit fields that are sent with different names
        ...omit(formFields, ['country', 'homeCity']),
        // Add/Override rest of the fields
        mobileNumber: parseDigits(mobileNumber),
        email: email.trim(),
        countryId: country,
        cityId: homeCity,
        stripeCardToken,
      };

      // Create user
      const { cityId, isTeamUser, teamOrganizationId } = await signup(
        signupPayload,
      );

      // In the event we can't find a Deskpass city don't
      // stop the signup process, but inform the user
      if (!cityId) {
        createNotification(
          "Looks like we're not in your area yet. " +
            'You can still create an account.',
          'warning',
          { timeout: 30000 },
        );
      }

      trackAnalytics({
        stripeCardToken,
        teamOrganizationId,
        isTeamUser,
        cityId,
      });

      await login(email, password);
    } catch (err) {
      console.error('Error creating new user:', err);

      setSubmitting(false);

      // Attempt to show user useful error message
      createNotification(
        `There was a problem creating your account${
          err.displayError ? `: ${err.displayError}` : '.'
        }`,
        'warning',
        { timeout: 10000 },
      );

      // TODO this handling looks weird
      if (err.errorCode === 'accountAlreadyExists') {
        return replaceRoute({ pathname: route('login'), state: { email } });
      }
    }
  });

  const onSubmit = useEvent((evt) => {
    evt.preventDefault();
    handleSubmit();
  });

  const isSignupRoute = useRouteMatch(route('signup'));

  const onCitiesLoadError = useEvent(() => {
    createNotification(
      'Could not load cities. Try again in a few minutes. ' +
        'If the problem persists, contact Deskpass Support.',
      'warning',
      { timeout: 10000 },
    );
  });

  const [{ cities, loading: citiesLoading, error: citiesError }, loadCities] =
    useCities(
      { countryId: country },
      {
        fireOnMount: false,
        onError: onCitiesLoadError,
      },
    );

  const citiesSortedBySlug = React.useMemo(
    () =>
      cities.sort((a, b) =>
        a.slug?.toLowerCase() >= b.slug?.toLowerCase() ? 1 : -1,
      ),
    [cities],
  );

  const cityOptions = React.useMemo(
    () =>
      citiesSortedBySlug.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    [citiesSortedBySlug],
  );

  const countryOptions = React.useMemo(
    () =>
      countries.map((it) => ({
        label: it.name,
        value: it.id,
      })),
    [countries],
  );

  const getError = React.useCallback(
    (field) => {
      if (!dirtyFields[field]) return;

      return errors[field] ? errors[field] : '';
    },
    [errors, dirtyFields],
  );

  return (
    <Styled.SignupForm onSubmit={onSubmit}>
      {!noHeader && (
        <Heading size="m" el="h2">
          One time setup only. You can login with your email and password for
          your next reservation.
        </Heading>
      )}

      <ContentSection>
        <FormRow name="fullname" half>
          <FormGroup
            name="firstName"
            label="First Name"
            errorMessage={getError('firstName')}
          >
            <FormText
              name="firstName"
              placeholder="Your first name"
              autoCapitalize="words"
              value={firstName}
              onChange={onChange}
              onBlur={onBlur}
            />
          </FormGroup>

          <FormGroup
            name="lastName"
            label="Last Name"
            errorMessage={getError('lastName')}
          >
            <FormText
              name="lastName"
              placeholder="Your last name"
              autoCapitalize="words"
              value={lastName}
              onChange={onChange}
              onBlur={onBlur}
            />
          </FormGroup>
        </FormRow>

        <FormGroup
          name="email"
          label="Email Address"
          errorMessage={getError('email')}
        >
          <FormText
            name="email"
            placeholder="Your email address"
            value={email}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormGroup>

        <FormGroup
          name="password"
          label="Create Password"
          errorMessage={getError('password')}
        >
          <FormText
            name="password"
            placeholder="Must be at least 10 characters."
            value={password}
            onBlur={onBlur}
            onChange={onChange}
            type="password"
          />
        </FormGroup>

        <FormGroup name="country" label="Your country">
          <FormSelect
            name="country"
            value={country}
            emptyOption={!country ? 'Choose your country…' : null}
            options={countryOptions}
            onChange={onCountryChange}
          />
        </FormGroup>

        {country && citiesLoading && (
          <LoadingContent placement="inline" small />
        )}

        {country && !citiesLoading && (
          <FormGroup name="homeCity" label="Your home city">
            {!citiesError && (
              <FormSelect
                name="homeCity"
                value={homeCity}
                emptyOption="Choose your home city…"
                options={cityOptions}
                disabled={citiesLoading || !country}
                onChange={onCityChange}
              />
            )}
          </FormGroup>
        )}

        <FormGroup name="organization" label="Company" optional>
          <FormText
            name="organization"
            placeholder="Your company name"
            value={organization}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormGroup>

        <FormGroup
          name="mobileNumber"
          label="Contact Number"
          errorMessage={getError('mobileNumber')}
        >
          <FormText
            name="mobileNumber"
            placeholder="Your phone number"
            value={mobileNumber}
            type="tel"
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormGroup>

        <StripeCard onBlur={onCardInfoBlur} onChange={onCardInfoChange} />

        <StripeCoupon value={coupon} onBlur={onBlur} onChange={onChange} />

        <ContentSection center>
          <Spacer xl />

          <Text size="large">
            <p>
              <>Deskpass </>
              <a
                className="underline"
                href={route('tos')}
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms of Service
              </a>
              <>, </>
              <a
                className="underline"
                href={route('privacyPolicy')}
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
              <> and </>
              <a
                className="underline"
                href={route('rules')}
                rel="noopener noreferrer"
                target="_blank"
              >
                Rules
              </a>
            </p>
          </Text>
        </ContentSection>
      </ContentSection>

      <ActionSet padded>
        <Button
          arrow
          type="submit"
          disabled={submitting || !valid}
          processing={submitting}
        >
          {isSignupRoute ? 'Register' : 'Setup Account and Confirm Reservation'}
        </Button>
      </ActionSet>
    </Styled.SignupForm>
  );
}

export default SignupForm;
