import React from 'react';

import { useRouterContext } from '@/context/Router';

import { DEFAULT_FILTER_STATE } from './constants';

const ARRAY_PARAMS = ['amenities'];

function _getKeyValue(key, val) {
  let value = val ?? DEFAULT_FILTER_STATE[key];

  // Transform array search param keys in array instance
  // when there is only one occurrence of it in the URL.
  if (ARRAY_PARAMS.includes(key) && !Array.isArray(value)) {
    value = [value];
  }

  return { [key]: value };
}

// Set default values to existing query params if not available
function _getFilterParams(searchParams) {
  return Object.entries(searchParams).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ..._getKeyValue(key, value),
    }),
    {},
  );
}

export default function useSearchParams() {
  const { parsedSearchParams } = useRouterContext();

  return React.useMemo(
    () => _getFilterParams(parsedSearchParams),
    [parsedSearchParams],
  );
}
