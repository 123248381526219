import React from 'react';

import FilterButton from '@/components/filters/FilterButton';

import FilterContext from '@/context/Filter';
import UserContext from '@/context/User';

import useEvent from '@/hooks/useEvent';

const FavoriteFilterButton = () => {
  const { authenticated } = React.useContext(UserContext);
  const { filters, updateFilters } = React.useContext(FilterContext);

  const toggleFavorite = useEvent(() =>
    updateFilters({
      isFavorite: !filters.isFavorite,
    }),
  );

  if (!authenticated) {
    return null;
  }

  return (
    <FilterButton
      reverse
      selected={filters.isFavorite}
      onClick={toggleFavorite}
      icon="heart-24"
    >
      Favorites
    </FilterButton>
  );
};

export default FavoriteFilterButton;
