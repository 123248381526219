import { useEffect, useRef } from 'react';

export const useExistingOrNewRef = (ref, cb = () => null) => {
  const selfRef = useRef({});

  useEffect(() => {
    selfRef.current.onReady = cb;
  });

  useEffect(() => {
    const { onReady, finalRef } = selfRef.current;
    if (typeof finalRef !== 'function') {
      onReady(finalRef.current);
    }
  }, []);

  if (!!ref && ref.current instanceof window.HTMLElement) {
    selfRef.current.finalRef = ref;
  } else if (typeof ref === 'function') {
    selfRef.current.finalRef = (domEl) => {
      if (domEl) {
        ref(domEl);
        cb(domEl);
      }
    };
  } else {
    selfRef.current.finalRef = (domEl) => {
      if (domEl) {
        cb(domEl);
      }
    };
  }

  return selfRef.current.finalRef;
};
