import { createBrowserHistory } from 'history';

//
//   Create single instace of browser history wrapper
//
//////////////////////////////////////////////////////////////////////
const history = createBrowserHistory();

// Export some explicit methods for the benefit of being able to
// require it directly
const replaceRoute = history.replace;

export { replaceRoute };

export default history;
