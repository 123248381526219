// import moment from 'moment-timezone';
import localForage from 'localforage';

/**
 * Mark all notifications closed by the user as closed locally.
 *
 * @param {String} notificationName Name of the notification
 *
 * @return {Promise} Returns after updating closed notifications
 */
export function markNotificationAsClosed(notificationName) {
  // Pull current closedNotifications object and update it
  return localForage.getItem('closedNotifications').then((notifications) => {
    return localForage.setItem('closedNotifications', {
      ...notifications,
      [notificationName]: false,
    });
  });
}

/**
 * Get all notifications closed by the user.
 *
 * @return {Promise} Returns closed notifications
 */
export function getClosedNotifications() {
  return localForage.getItem('closedNotifications');
}
