import ComposeChildren from '@/components/helpers/ComposeChildren';
import { Provider as IntercomProvider } from '@/components/intercom';

import { AmenityProvider } from '@/context/Amenity';
import { AnalyticsProvider } from '@/context/Analytics';
import { CountryProvider } from '@/context/Country';
import { CurrentRoomOrSpaceProvider } from '@/context/CurrentRoomOrSpace';
import { ErrorProvider } from '@/context/Error';
import { FilterProvider } from '@/context/Filter';
import { GeolocationProvider } from '@/context/Geolocation';
import { GlobalConfigProvider } from '@/context/GlobalConfig';
import { GoogleMapProvider } from '@/context/GoogleMap';
import { HubspotProvider } from '@/context/Hubspot';
import { I18NProvider } from '@/context/I18n';
import { IpLocationProvider } from '@/context/IpLocation';
import { MapProvider } from '@/context/Map';
import { MetaProvider } from '@/context/Meta';
import { MoreModalProvider } from '@/context/MoreModal';
import { NetworkStatusProvider } from '@/context/NetworkStatus';
import { NotificationProvider } from '@/context/Notification';
import { PageVisibilityProvider } from '@/context/PageVisibility';
import { RoomProvider } from '@/context/Room';
import { RouterProvider } from '@/context/Router';
import { SessionProvider } from '@/context/Session';
import { SpaceProvider } from '@/context/Space';
// Context Providers
import { StripeProvider } from '@/context/Stripe';
import { ThemeProvider } from '@/context/Theme';
import { UserProvider } from '@/context/User';

import GlobalStateSetupContainer from './GlobalStateSetupContainer';

/*
 * Composes all app wide context providers that
 * will hold important app shared state.
 *
 * The order you pass the providers in the "components" array matter.
 *
 * The providers on the bottom can access values
 * from the ones on the top but not the opposite.
 *
 * Be mindful of that while adding more global context providers.
 */
const AppContextProviders = ({ children }) => {
  return (
    <ComposeChildren
      components={[
        GoogleMapProvider,
        PageVisibilityProvider,
        StripeProvider,
        RouterProvider,
        NotificationProvider,
        NetworkStatusProvider,
        SessionProvider,
        // From here on session needs to be around for other things to load
        GlobalConfigProvider,
        GeolocationProvider,
        IpLocationProvider,
        I18NProvider,
        AmenityProvider,
        UserProvider,
        CountryProvider,
        IntercomProvider,
        HubspotProvider,
        MoreModalProvider,
        ThemeProvider,
        AnalyticsProvider,
        MetaProvider,
        CurrentRoomOrSpaceProvider,
        // Waits for global state
        GlobalStateSetupContainer,
        // Every context below only renders when global state is ready
        FilterProvider,
        MapProvider,
        SpaceProvider,
        RoomProvider,
        ErrorProvider,
      ]}
    >
      {children}
    </ComposeChildren>
  );
};

export default AppContextProviders;
