import styled, { css } from 'styled-components/macro';

import units from '@/styles/js/units';

export const StatusBarArea = styled.div`
  ${({ hasStatusBar }) => hasStatusBar && css`
    height: env(safe-area-inset-top);
    background: white;
    z-index: ${units.zIndex.filtersNavbar};
    position: sticky;
    top: 0;
  `}
`;

