import React from 'react';

import FormDatePicker from '@/components/forms/FormDatePicker';
import FormGroup from '@/components/forms/FormGroup';
import FormTimePicker from '@/components/forms/FormTimePicker';
import { LayoutSplit, Left, Right } from '@/components/layout/LayoutSplit';
import ContentSection from '@/components/text-headings/ContentSection';
import Text from '@/components/text-headings/Text';

import useEvent from '@/hooks/useEvent';

import useWhenFilterProps from './useWhenFilterProps';

const MeetWhenFilter = () => {
  const { filters, disabledDays, updateFilters, formatedTimeDuration } =
    useWhenFilterProps();

  const [{ start, end, date }, setTimeEntries] = React.useState(() => ({
    start: filters.start,
    end: filters.end,
    date: filters.date?.toDate?.(),
  }));

  const handleDayClick = useEvent((day) => {
    setTimeEntries((prevEntries) => ({ ...prevEntries, date: day }));
  });

  const handleTimeChange = useEvent((timeEntry) => {
    setTimeEntries((prevEntries) => ({ ...prevEntries, ...timeEntry }));
  });

  React.useEffect(() => {
    if (start && end && date) {
      updateFilters({ start, end, date });
    }
  }, [start, end, date, updateFilters]);

  return (
    <LayoutSplit>
      <Left>
        <FormDatePicker
          onDayClick={handleDayClick}
          value={date}
          disabledDays={disabledDays}
        />
      </Left>

      <Right>
        <FormGroup label="Time" name="time">
          <FormTimePicker
            start={start}
            end={end}
            onChange={handleTimeChange}
            disabled={!date}
          />
        </FormGroup>
        <ContentSection>
          <Text size="small">
            <p>
              <strong>Duration: {formatedTimeDuration}</strong>
            </p>
          </Text>

          <Text size="small" color="subtle">
            <p>
              Reservations of 6 hours or more may qualify for a less expensive
              day rate (varies by space).
            </p>
          </Text>
        </ContentSection>
      </Right>
    </LayoutSplit>
  );
};

export default MeetWhenFilter;
