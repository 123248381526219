import PersistentNotifications from '@/components/notifications/PersistentNotifications';
import TemporaryNotifications from '@/components/notifications/TemporaryNotifications';

/**
 * Wraps permanent and temporary notifications
 */
const NotificationsCentral = () => (
  <>
    <PersistentNotifications />
    <TemporaryNotifications />
  </>
);

export default NotificationsCentral;
