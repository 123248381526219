import React from 'react';

/**
 * General utilities that don't make more sense elsewhere.
 */

/**
 * Key passed array by passed prop name.
 *
 * @param {Array} array Array to create keyed object for
 * @param {String} prop  Name of prop to key object by
 *
 * @return {Object} Returns object containing keyed values
 */
export function keyBy(array, prop) {
  return array.reduce((current, next) => {
    if (next[prop]) {
      current[next[prop]] = next;
    }

    return current;
  }, {});
}

const defaultRemoveEmptyOptions = {
  string: false,
};

export const removeEmpty = (obj, options = {}) => {
  const { emptyString } = {
    ...defaultRemoveEmptyOptions,
    ...options,
  };

  const newObj = obj ?? {};
  return Object.keys(newObj).reduce((acc, it) => {
    if (newObj[it] === undefined) {
      return acc;
    }

    if (emptyString && newObj[it] === '') {
      return acc;
    }

    return { ...acc, [it]: newObj[it] };
  }, {});
};

export const atLeastOneTrue = (...args) => {
  return args.some(Boolean);
};

export const someFalsy = (...args) => {
  return args.some((it) => !it);
};

export const prefixProps = (props = {}, prefix = '') => {
  return Object.keys(props).reduce(
    (newProps, nextPropKey) => ({
      ...newProps,
      [`${prefix}${nextPropKey}`]: props[nextPropKey],
    }),
    {},
  );
};

export const usePrefixProps = (props = {}, prefix = '') => {
  const memoArray = Object.keys(props).concat(prefix);

  const prefixedProps = React.useMemo(
    () => {
      return prefixProps(props, prefix);
    },
    // intentionally disable lint here and do memo
    // check based on prop keys and prefix
    /* eslint-disable-next-line */
    memoArray,
  );

  return prefixedProps;
};

export const upperCaseEachFirstLetter = (sentence = '') => {
  if (!sentence) return sentence;

  return sentence
    .split(' ')
    .map(
      (word = '') =>
        (word[0]?.toUpperCase() ?? '') + (word?.substring(1) ?? ''),
    )
    .join(' ');
};

export const isNumber = (value) => {
  return !isNaN(+value);
};

export const parseDigits = (value = '') => {
  if (isNumber(value)) return value;

  return value?.match(/\d+/g)?.join('');
};

export function dashedToCammelCase(str) {
  return str
    .split('-')
    .map((it, idx) => {
      if (idx === 0) return it;

      return it[0].toUpperCase() + it.substring(1);
    })
    .join('');
}

export function snakeToCamelCase(str, upperCaseFirstChar = false) {
  return str
    .split('_')
    .map((it, idx) => {
      if (!upperCaseFirstChar && idx === 0) return it;

      return it[0].toUpperCase() + it.substring(1);
    })
    .join('');
}

/**
 * Add a class from the HTML element.
 *
 * @param {String} className Class name to add
 */
export const addHTMLClass = (className) => {
  document.documentElement.classList.add(className);
};

/**
 * Remove a class from the HTML element.
 *
 * @param {String} className Class name to remove
 */
export const removeHTMLClass = (className) => {
  document.documentElement.classList.remove(className);
};
