/**
 * Field values that need to be referenced in multiple places and aren't simple
 * values.
 */

// Values used for Group Size space filter
export const capacityValueMap = new Map([
  ['1', 'Just Me'],
  ['2', '2 people'],
  ['3', '3 people'],
  ['4', '4 people'],
  ['5', '5 people'],
  ['6', '6 people'],
  ['7', '7 people'],
  ['8', '8 people'],
  ['9', '9 people'],
  ['10', '10 people'],
  ['11', '11 people'],
  ['12', '12 people'],
  ['12-20', '12-20 people'],
  ['20-30', '20-30 people'],
  ['30', '30+ people'],
]);
