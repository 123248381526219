import Notification from '@/components/notifications/Notification';

import config from '@/lib/config';

const MaintenanceNotification = ({ closeNotification }) => {
  if (!config.MAINTENANCE_MODE) {
    return null;
  }

  return (
    <Notification warning onIconClick={closeNotification}>
      Deskpass is currently undergoing maintenance. You may experience slower
      performance for now. We apologize for any inconvenience.
    </Notification>
  );
};

export default MaintenanceNotification;
