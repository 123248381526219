import { createGlobalStyle, css } from 'styled-components/macro';

import CamptonEot from 'fonts/campton/campton.eot';
import CamptonWoff2 from 'fonts/campton/campton.woff2';
import CamptonWoff from 'fonts/campton/campton.woff';
import CamptonTtf from 'fonts/campton/campton.ttf';

import AvertaWoff2 from 'fonts/averta/averta-regular-webfont.woff2';
import AvertaWoff from 'fonts/averta/averta-regular-webfont.woff';
import AvertaItalicWoff2 from 'fonts/averta/averta-regular-italic-webfont.woff2';
import AvertaItalicWoff from 'fonts/averta/averta-regular-italic-webfont.woff';
import AvertaBoldWoff2 from 'fonts/averta/averta-bold-webfont.woff2';
import AvertaBoldWoff from 'fonts/averta/averta-bold-webfont.woff';

const fontFaceProps = (family, weight = 'normal', style = 'normal') => css`
  font-family: ${family};
  font-weight: ${weight};
  font-style: ${style};
`;

/* Exported */

const GlobalFonts = createGlobalStyle`
  @font-face {
    src: url(${CamptonEot}?#iefix);
    src: url(${CamptonEot}#iefix) format('eot'),
      url(${CamptonWoff2}) format('woff2'),
      url(${CamptonWoff}) format('woff'),
      url(${CamptonTtf}) format('truetype');
    ${fontFaceProps('Campton W00 Bold', 'bold')}
  }


  @font-face {
    src: url(${AvertaWoff2}) format('woff2'),
      url(${AvertaWoff}) format('woff');
    ${fontFaceProps('averta-regular')}
  }

  @font-face {
    font-family: 'averta-regular';
    src: url(${AvertaItalicWoff2}) format('woff2'),
      url(${AvertaItalicWoff}) format('woff');
    ${fontFaceProps('averta-regular', 'normal', 'italic')}
  }

  @font-face {
    font-family: 'averta-regular';
    src: url(${AvertaBoldWoff2}) format('woff2'),
      url(${AvertaBoldWoff}) format('woff');
    ${fontFaceProps('averta-regular', 'bold')}
  }
`;

export default GlobalFonts;
