import { Suspense, lazy } from 'react';

import LoadingContent from '@/components/helpers/LoadingContent';

import addRetry from '@/lib/retry';

// React.lazy will slit the code in chunks
export function lazyImport(LazyComponentPromise) {
  const LazyLoadedView = lazy(
    addRetry(LazyComponentPromise, {
      interval: 500,
    }),
  );

  return function LazyWrapperComponent(props) {
    return (
      <Suspense fallback={<LoadingContent />}>
        <LazyLoadedView {...props} />
      </Suspense>
    );
  };
}
