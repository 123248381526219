import debounce from 'p-debounce';
import React from 'react';

import useEvent from '@/hooks/useEvent';

const useDebounce = (handler, delay = 500) => {
  // Gets the updated handler but always returns same reference
  const handlerEvent = useEvent(handler);

  const debouncedRef = React.useRef(handlerEvent);

  React.useEffect(() => {
    debouncedRef.current = debounce(handlerEvent, delay);
  }, [handlerEvent, delay]);

  return debouncedRef.current;
};

export default useDebounce;
