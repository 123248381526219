import moment from 'moment-timezone';
import React from 'react';

import FormDatePicker from '@/components/forms/FormDatePicker';

import useEvent from '@/hooks/useEvent';

import useWhenFilterProps from './useWhenFilterProps';

const DATE_FORMAT = 'yyyy-MM-DD';

const dateToString = (date) => {
  return moment(date).format(DATE_FORMAT);
};

const stringToDate = (dateString) => {
  if (typeof dateString !== 'string' || !dateString) {
    return null;
  }

  return moment(dateString, DATE_FORMAT).toDate();
};

const OfficeWhenFilter = () => {
  const {
    updateFilters,
    filters: { start, end },
    disabledDays,
    reset,
  } = useWhenFilterProps();

  const rangeSelected = React.useMemo(() => !!start && !!end, [start, end]);

  const from = React.useMemo(() => stringToDate(start), [start]);
  const to = React.useMemo(() => stringToDate(end), [end]);

  const isSelectingFirstDay = useEvent((day) => {
    const isBeforeFirstDay = !!from && moment(day).isBefore(moment(from));
    return !from || isBeforeFirstDay || rangeSelected;
  });

  const selectedDays = React.useMemo(() => {
    return [from, { from, to }];
  }, [from, to]);

  const modifiers = React.useMemo(
    () => ({
      singleDay: !!from && !to ? from : null,
      start: !!from && !!to ? from : null,
      end: to,
    }),
    [from, to],
  );

  const handleOfficeDayClick = useEvent((day) => {
    // Click before or after selection, reset and abort
    if (from && end && day >= from && day <= end) {
      return reset();
    }

    // Ignore selections on the same date
    if (moment(from).isSame(moment(day), 'day')) {
      return;
    }

    if (isSelectingFirstDay(day)) {
      return updateFilters({ start: dateToString(day), end: null });
    }

    updateFilters({ end: dateToString(day) });
  });

  return (
    <FormDatePicker
      range
      value={selectedDays}
      disabledDays={disabledDays}
      modifiers={modifiers}
      onDayClick={handleOfficeDayClick}
      fromMonth={new Date()}
    />
  );
};

export default OfficeWhenFilter;
