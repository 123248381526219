import { childrenSequenceOf } from 'airbnb-prop-types';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import fluidSize from '@/styles/js/utils/fluidSize';
import mq from '@/styles/js/utils/mq';
import { commonSpacing } from '@/styles/js/utils/spacing';

/**
 * Styled LayoutSplit
 * ---
 * A layout with two equal halves.
 */
const StyledLayoutSplit = styled.section`
  ${mq('medium')} {
    display: flex;
  }
`;

/**
 * LayoutSplit content flex-center styles
 * ---
 * @param {Boolean} center
 */
const getContentCenterStyles = ({ center }) =>
  center &&
  css`
    ${mq('medium')} {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `;

/**
 * Styled LayoutSplitLeft
 * ---
 * optional props
 * - `center`
 */
const StyledLayoutSplitLeft = styled.div`
  ${commonSpacing}

  ${mq('medium')} {
    width: 50%;
  }

  ${mq('medium')} {
    ${fluidSize('outer-l', 'padding-right')}

    border-right: ${({ theme }) => '1px solid '.concat(theme.colors.rule)};
  }

  ${getContentCenterStyles}
`;

/**
 * Styled LayoutSplitRight
 * ---
 * optional props
 * - `center`
 */
const StyledLayoutSplitRight = styled.div`
  ${commonSpacing}

  ${mq({ until: 'medium' })} {
    ${fluidSize('lay-l')}
  }

  ${mq('medium')} {
    flex-basis: 50%;
  }

  ${mq('medium')} {
    ${fluidSize('outer-l', 'padding-left')}
  }

  ${getContentCenterStyles}
`;

const LayoutSplit = ({ children }) => (
  <StyledLayoutSplit>{children}</StyledLayoutSplit>
);

const Left = ({ children, ...otherProps }) => (
  <StyledLayoutSplitLeft {...otherProps}>{children}</StyledLayoutSplitLeft>
);

const Right = ({ children, ...otherProps }) => (
  <StyledLayoutSplitRight {...otherProps}>{children}</StyledLayoutSplitRight>
);

LayoutSplit.propTypes = {
  children: childrenSequenceOf(
    { validator: Left, min: 1, max: 1 },
    { validator: Right, min: 1, max: 1 },
  ),
};

Left.propTypes = Right.propTypes = {
  center: PropTypes.bool,
};

export { LayoutSplit, Left, Right };
