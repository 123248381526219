import styled from 'styled-components/macro';

import { colors } from '@/styles/js/utils/theme';
import { rgba } from '@/styles/js/utils/colors';
import mq from '@/styles/js/utils/mq';

export const Wrapper = styled.div`
  position: relative;
`;

const topBarHeight = '105px';
const spacingFromTopBar = '2rem';

export const Menu = styled.div`
  position: absolute;
  padding: 1rem;
  background: white;
  top: calc(100% + ${spacingFromTopBar});
  border-radius: 4px;
  border: solid 1px ${colors.rule};
  box-shadow: 0 0 2rem ${rgba('black', 0.12)};
  min-width: 100%;
  max-height: 50rem;
  overflow-y: auto;

  ${mq('smallMedium')} {
    padding: 2rem;
    min-width: 40rem;
  }

  ${mq({ until: 'smallMedium' })} {
    max-height: calc(100vh - ${topBarHeight} - ${spacingFromTopBar});
    padding-bottom: 4rem;
  }
`;
