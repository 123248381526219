import { useHistory } from 'react-router-dom';

import Notification from '@/components/notifications/Notification';

import useRoutePathMatcher from '@/hooks/useRoutePathMatcher';

import route from '@/lib/routes';

import { useIsIncomingBreatherUser } from './hooks';

function IncomingBreatherMemberNotification({ closeNotification }) {
  const isBreatherSession = useIsIncomingBreatherUser();

  const { push: pushRoute } = useHistory();
  const isLoginPage = useRoutePathMatcher(['login']);

  if (!isBreatherSession || isLoginPage) {
    return null;
  }

  return (
    <Notification
      onClick={() => pushRoute(route('login'))}
      onIconClick={closeNotification}
    >
      Welcome Breather users! Log in with your Breather account here to start
      booking rooms on Deskpass
    </Notification>
  );
}

export default IncomingBreatherMemberNotification;
