import useMediaQuery from '@/hooks/useMediaQuery';

import GeocodingFilterDesktop from './Desktop';
import GeocodingFilterMobile from './Mobile';

const GeocodingFilter = () => {
  const isMobile = useMediaQuery({ max: 'large' });

  if (isMobile) {
    return <GeocodingFilterMobile />;
  }

  return <GeocodingFilterDesktop />;
};

export default GeocodingFilter;
