import styled from 'styled-components/macro';

export const Separator = styled.div`
  border: none;
  border-top: 1px solid #D1D5FC;
  position: relative;
`;

export const Label = styled.label`
  position: absolute;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  background-color: white; /* To match the background of the page */
  left: 5%;
  transform: translateY(-50%);

  /* Text styles */
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
