import PropTypes from 'prop-types';
import { memo } from 'react';

import * as S from './ContentSection.styles';

/**
 * Wraps generic blocks of content to allow consistent
 * spacing between elements.
 */
const ContentSection = ({ children, center, important }) => {
  return (
    <S.ContentSection center={center} important={important}>
      {children}
    </S.ContentSection>
  );
};

ContentSection.defaultProps = {
  center: false,
  important: false,
};

ContentSection.propTypes = {
  center: PropTypes.bool,
  important: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default memo(ContentSection);
