import debounce from 'p-debounce';
import { useCallback, useState } from 'react';

import useWatch from './useWatch';

const useWindowResize = ({ debounceTime, onResize = () => {} } = {}) => {
  const getDimensions = useCallback((target = window) => {
    const width = target.innerWidth || document.documentElement.clientWidth;
    const height = target.innerHeight || document.documentElement.clientHeight;

    return { width, height };
  }, []);

  const [dimensions, setDimensions] = useState(() => getDimensions());

  useWatch(() => {
    const defaultDebounceTime = 100;
    const timeToDebounce = isNaN(debounceTime)
      ? defaultDebounceTime
      : debounceTime;

    function handleResize({ target }) {
      const newDimensions = getDimensions(target);
      setDimensions(newDimensions);
      onResize(newDimensions, target);
    }

    const handler = timeToDebounce
      ? debounce(handleResize, timeToDebounce)
      : handleResize;

    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [debounceTime, getDimensions]);

  return dimensions;
};

export default useWindowResize;
