import React from 'react';

import { useFilterContext } from '@/context/Filter';

import useEvent from '@/hooks/useEvent';
import useStateMergeUpdater from '@/hooks/useStateMergeUpdater';
import useWatch from '@/hooks/useWatch';

import { consumerToHOC } from '@/lib/hoc';

const Context = React.createContext({});

const initialState = {
  dirty: false,
  // The marker that the user clicked should show as active
  activeMarkerId: null,
  // A second marker that will behave as active while the user is
  // hovering over it's space or room on the list or slider
  focusedMarkerId: null,
  // Used to get markers in view
  lastRadius: null,
  lastCenter: {},

  googleMap: null,
};

export const MapProvider = ({ children }) => {
  const { bookingType } = useFilterContext().filters;

  const [state, setState] = React.useState(() => initialState);

  const updateState = useStateMergeUpdater(setState);

  const setDirty = useEvent((dirty = true) => updateState({ dirty }));

  const setLastRadiusAndCenter = useEvent(({ lastCenter, lastRadius }) => {
    updateState({ lastRadius, lastCenter });
  });

  const setActiveMarkerId = useEvent((activeMarkerId) =>
    updateState({ activeMarkerId }),
  );

  const setFocusedMarkerId = useEvent((focusedMarkerId) => {
    const { activeMarkerId } = state;

    // Do not allow the same space to be active and focused
    if (activeMarkerId && activeMarkerId === focusedMarkerId) {
      return;
    }

    return updateState({ focusedMarkerId });
  });

  const setGoogleMap = useEvent((googleMap) => updateState({ googleMap }));

  const getCurrentBoundingBox = React.useCallback(() => {
    const { googleMap } = state;

    if (!googleMap) {
      return null;
    }

    const bounds = googleMap.getBounds();

    return {
      northeast: {
        lat: bounds.getNorthEast().lat(),
        lng: bounds.getNorthEast().lng(),
      },
      southwest: {
        lat: bounds.getSouthWest().lat(),
        lng: bounds.getSouthWest().lng(),
      },
    };
  }, [state]);

  // Clear any active or focused markers when bookingType change
  useWatch(
    () => {
      updateState({ activeMarkerId: null, focusedMarkerId: null });
    },
    [bookingType],
    false,
  );

  const context = React.useMemo(
    () => ({
      ...state,
      setLastRadiusAndCenter,
      setActiveMarkerId,
      setFocusedMarkerId,
      setDirty,
      setGoogleMap,
      getCurrentBoundingBox,
    }),
    [
      state,
      setLastRadiusAndCenter,
      setActiveMarkerId,
      setFocusedMarkerId,
      setDirty,
      setGoogleMap,
      getCurrentBoundingBox,
    ],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useMapContext = () => React.useContext(Context);
export default Context;
export const withMapContext = consumerToHOC(Context.Consumer, 'mapContext');
