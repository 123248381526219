import PropTypes from 'prop-types';

import Icon from '@/components/graphics/Icon';
import Mq from '@/components/helpers/Mq';

import { childrenType } from '@/lib/props';

import * as Styled from './index.styles';

/**
 * A banner with a message that indicates something noteworthy has occurred.
 */
const Notification = ({
  children,
  onClick,
  onIconClick,
  timeout,
  icon,
  clickable,
  special,
  warning,
}) => {
  return (
    <Styled.Notification
      clickable={clickable}
      special={special}
      warning={warning}
      onClick={onClick}
    >
      <Styled.Content>{children}</Styled.Content>

      {timeout && (
        <Styled.ProgressIndicator
          timeout={timeout}
          special={special}
          warning={warning}
        />
      )}

      <Styled.CloseIcon onClick={onIconClick}>
        <Mq max="large">
          <Icon
            type={
              icon === 'close' ? 'close-x-small-24' : 'arrow-small-right-24'
            }
          />
        </Mq>

        <Mq min="large">
          <Icon type={icon === 'close' ? 'close-x-24' : 'arrow-right-24'} />
        </Mq>
      </Styled.CloseIcon>
    </Styled.Notification>
  );
};

Notification.defaultProps = {
  clickable: true,
  special: false,
  warning: false,
  onClick: () => {},
  onIconClick: () => {},
  icon: 'close',
};

Notification.propTypes = {
  children: childrenType.isRequired,
  onClick: PropTypes.func,
  onIconClick: PropTypes.func,
  timeout: PropTypes.number,
  icon: PropTypes.oneOf(['close', 'arrow']),
  clickable: PropTypes.bool,
  special: PropTypes.bool,
  warning: PropTypes.bool,
};

export default Notification;
