import React from 'react';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import NotificationContext from '@/context/Notification';

import useEvent from '@/hooks/useEvent';
import useMount from '@/hooks/useMount';

const Context = React.createContext({});

const OFFLINE_NOTIFICATION = 'OFFLINE_NOTIFICATION';
const offlineMessage =
  'You appear to be offline currently. ' +
  'Some things might not work right until you reconnect.';

const supportsOnlineCheck = typeof navigator.onLine !== 'undefined';

const Provider = ({ children }) => {
  const timeoutRef = React.useRef();
  const [online, setOnline] = React.useState(() => true);

  const {
    createOrReplacePersistent: notify,
    destroyPersistent: cancelNotification,
  } = React.useContext(NotificationContext);

  const [{ loading: checkingHeartbeat }, checkHeartbeat] = useDeskpassAPI(
    (api) => api.checkHeartbeat,
    { fireOnMount: false },
  );

  const onOffline = useEvent(() => {
    // Wait a few seconds to make sure this isn't a temporary glitch
    timeoutRef.current = setTimeout(() => {
      setOnline(false);
      notify(OFFLINE_NOTIFICATION, offlineMessage, 'warning');
    }, 5000);
  });

  const onOnline = useEvent(async () => {
    clearTimeout(timeoutRef.current);

    if (!checkingHeartbeat) {
      // This request has Infinity retry setting and will wait here
      // until it succeed. 1 request per second.
      await checkHeartbeat();

      setOnline(true);
      cancelNotification(OFFLINE_NOTIFICATION);
    }
  });

  const registerOnlineChecker = useEvent(() => {
    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);
  });

  useMount(() => {
    if (supportsOnlineCheck) {
      return registerOnlineChecker();
    }

    console.warn('Not setting up offline checking - not supported.');
  });

  const context = React.useMemo(() => ({ online }), [online]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default Context;
export const NetworkStatusProvider = Provider;
