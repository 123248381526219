/**
 * Given a rectangle described by a width, height and x/y coords and a bounding
 * box, figure out how much (if at all) the shape falls outside of the bounding
 * box and calculate the correction necessary to bring it back inside the
 * bounding box.
 *
 * @param {Integer} x           Current X coord of rectangle to rectify
 * @param {Integer} y           Current Y coord of rectangle to rectify
 * @param {Integer} width       Width of rectangle to rectify
 * @param {Integer} height      Height of rectangle to rectify
 * @param {Integer} outerWidth  Width of bounding box
 * @param {Integer} outerHeight Height of bounding box
 * @param {Object}  options     Custom options to use in calculation--this
 * includes the padding to assume around the rectangle as well as any extra
 * offset to assume for the rectangle
 *
 * @return {Object} Returns { x, y } object describing correction to apply to
 * rectangle to return it to within bounding box
 */
const rectifyBounds = (
  x,
  y,
  width,
  height,
  outerWidth,
  outerHeight,
  options = {},
) => {
  const { padding = 40, offset = { x: 0, y: 0 } } = options;

  // Calculate bounds of the object with respect to the map container (i.e.
  // how far the marker has penetrated out of bounds if it has)
  const bounds = {
    top: y - (height / 2 - offset.y),
    right: outerWidth - (x + (width / 2 + offset.x)),
    bottom: outerHeight - (y + (height / 2 + offset.y)),
    left: x - (width / 2 - offset.x),
  };

  // Set a default correction
  let correction = { x: 0, y: 0 };

  // If marker is already within bounds there's nothing to do here
  if (
    bounds.top > 0 &&
    bounds.right > 0 &&
    bounds.bottom > 0 &&
    bounds.left > 0
  ) {
    return correction;
  }

  // If we got this far, calculate the necessary correction
  if (bounds.top < 0) {
    correction.y += bounds.top - padding;
  }
  if (bounds.right < 0) {
    correction.x += -bounds.right + padding;
  }
  if (bounds.bottom < 0) {
    correction.y += -bounds.bottom + padding;
  }
  if (bounds.left < 0) {
    correction.x += bounds.left - padding;
  }

  // And return the correction
  return correction;
};

/**
 * Return a random integer within specified min/max bounds, inclusive.
 *
 * @param {Integer} min Lower bound for random int, inclusive
 * @param {Integer} max Upper bound for random int, inclusive
 *
 * @return {Integer} Returns generated random integer
 */
function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function roundFloat(num, precision = 2) {
  if (!num) return;

  return Number(num.toFixed(precision));
}

export { randomIntFromInterval, rectifyBounds, roundFloat };
