import { Loader, LoaderStatus } from '@googlemaps/js-api-loader';
import React from 'react';

import useMount from '@/hooks/useMount';

import config from '@/lib/config';

const Context = React.createContext({});

const loader = new Loader({
  apiKey: config.GOOGLE_MAPS_API_KEY,
  // We should consider wether or not to set a fixed version
  version: 'weekly',
});

const googleMapPromise = loader.load();

// export this in case we need to do something outside react
export async function getGoogleMapsSdkInstance() {
  return await googleMapPromise;
}

function isLoaderStatus(status) {
  return loader.status === LoaderStatus[status];
}

export function GoogleMapProvider({ children }) {
  const [googleMapsSdk, setGoogleMapSdk] = React.useState(() => null);

  useMount(() => {
    (async () => {
      // TODO handle error for when it happens map won't load
      const google = await getGoogleMapsSdkInstance();

      const { Map } = await google.maps.importLibrary('maps');
      const { AdvancedMarkerElement } = await google.maps.importLibrary(
        'marker',
      );

      setGoogleMapSdk({
        google,
        // Add other specific pieces of the SDK here as needed
        Map,
        AdvancedMarkerElement,
      });
    })();
  });

  const context = React.useMemo(
    () => ({
      // Imported pieces of the SDK
      ...googleMapsSdk,
      // Access loader status and other properties
      loader,

      get ready() {
        return isLoaderStatus('SUCCESS');
      },

      get loading() {
        return isLoaderStatus('LOADING');
      },

      get hasError() {
        return isLoaderStatus('FAILURE');
      },
    }),
    [googleMapsSdk],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export const useGoogleMapContext = () => React.useContext(Context);
export default Context;
