import React from 'react';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import FormGroup from '@/components/forms/FormGroup';
import FormText from '@/components/forms/FormText';
import ContentSection from '@/components/text-headings/ContentSection';
import Text from '@/components/text-headings/Text';

import useDebounce from '@/hooks/useDebounce';
import useEvent from '@/hooks/useEvent';
import useMount from '@/hooks/useMount';
import useWatch from '@/hooks/useWatch';

const StripeCouponField = ({
  value: _value = '',
  onChange = () => ({}),
  onValid = () => ({}),
  ...inputProps
}) => {
  const [value, setValue] = React.useState(() => _value);
  const [validCoupon, setValidCoupon] = React.useState(() => false);

  // Keep value up to date if state is managed outside
  useWatch(() => setValue(_value), [_value], false);

  const [, isValidCoupon] = useDeskpassAPI((api) => api.user.isValidCoupon, {
    fireOnMount: false,
  });

  const debouncedCouponCheck = useDebounce(isValidCoupon, 300);

  const checkCouponIsValid = useEvent(async (value, debounced = false) => {
    if (!value) {
      onValid(false);
      return;
    }

    const request = debounced ? debouncedCouponCheck : isValidCoupon;

    try {
      await request(value);
      setValidCoupon(true);
      onValid(true);
    } catch (err) {
      setValidCoupon(false);
      onValid(false);
    }
  });

  useMount(() => {
    if (value) {
      checkCouponIsValid(value);
    }
  });

  const onCouponeChange = useEvent((evt) => {
    evt.target.value = evt.target.value.toUpperCase();
    onChange(evt);

    const { value } = evt.target;

    setValue(value);

    checkCouponIsValid(value, true);
  });

  return (
    <FormGroup name="coupon" label="Promotional Code" optional>
      <FormText
        name="coupon"
        placeholder="Enter an optional promotional code"
        autoCapitalize="characters"
        {...inputProps}
        value={value}
        onChange={onCouponeChange}
      />

      {!!value && validCoupon && (
        <ContentSection center>
          <Text>
            <p>
              The <strong>{value}</strong> coupon will be applied on signup.
            </p>
          </Text>
        </ContentSection>
      )}

      {!!value && !validCoupon && (
        <Text color="warning">
          <p>This coupon is invalid.</p>
        </Text>
      )}
    </FormGroup>
  );
};

export default StripeCouponField;
