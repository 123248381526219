import React from 'react';

import ipGeolocationAPI from '@/api/ipGeolocation';

import { useRouterContext } from '@/context/Router';

import useAPI from '@/hooks/useAPI';

import { consumerToHOC } from '@/lib/hoc';

const Context = React.createContext({});

const { Consumer, Provider } = Context;

export const IpLocationProvider = ({ children }) => {
  const { lat, lng } = useRouterContext().parsedSearchParams;

  const [{ ready, data: { location } = {} }] = useAPI(
    ipGeolocationAPI,
    (api) => api.getGeolocationByIp,
    {
      // Don't load ip-location if URL params lat/lng are provided
      fireOnMount: !lat && !lng,
      ...(lat && lng && { initialState: { ready: true } }),
    },
  );

  const context = React.useMemo(() => {
    if (!location) {
      return {
        ready,
        available: false,
        location: null,
      };
    }

    const { country, region, city, lat, lng } = location;

    return {
      available: true,
      ready,
      location: {
        country,
        city,
        region,
        coords: {
          lat,
          lng,
        },
      },
    };
  }, [location, ready]);

  return <Provider value={context}>{children}</Provider>;
};

export const IpLocationConsumer = Consumer;
export const useIpLocationContext = () => React.useContext(Context);
export const withIpLocationContext = consumerToHOC(
  Consumer,
  'ipLocationContext',
);
export default Context;
