import styled, { css, keyframes } from 'styled-components';

import eases from '@/styles/js/utils/eases';
import { rgba } from '@/styles/js/utils/colors';
import { absolute as pseudoAbsolute } from '@/styles/js/utils/pseudo';
import fontStack from '@/styles/js/fonts/stacks';

const squareBadgeHighlightRadiate = keyframes`
  0% {
    opacity: 1;
    transform: scale(0);
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
`;

export const SquareBadgeLogo = styled.div`
  &&& {
    > svg {
      // Component CompanyLogo has some inlined width/height...
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;

const backgroundPrimary = css`
  background-color: ${({ theme }) => theme.colors.primary};
`;

/**
 * SquareBadge Size
 * @param {string} size - `small | medium | large`
 */
const getBadgeSize = ({ size }) => ({
  'small': css``,
  'medium': css`
    height: 4rem;
    width: 4rem;

    & > ${SquareBadgeLogo} > svg {
      // Component CompanyLogo has some inlined width/height...
      width: 1.6rem !important;
      height: 1.6rem !important;
    }
  `,
  'large': css`
    height: 4.5rem;
    width: 4.5rem;

    & > ${SquareBadgeLogo} > svg {
      // Component CompanyLogo has some inlined width/height...
      width: 1.9rem !important;
      height: 1.9rem !important;
    }
  `,
}[size]);

/**
 * Styled SquareBadge
 * ---
 * 
 * (Optional) - props
 * - `size`
 *    - `small` - default size
 *    - `medium`
 *    - `large`
 * - `featured`
 * - `hq`
 * - `highlihted`
 * - `customLogo`
 * - `customColors`
 */
export const SquareBadge = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.text};
    box-shadow: 0 0.3rem 1rem ${rgba(theme.colors.black, 0.2)};
  `}
  border-radius: 0.4rem;
  will-change: opacity, transform;
  opacity: 0;
  transform: translateY(1.5rem);
  transition: 0.8s ${eases['outQuint']};
  transition-property: opacity, transform;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    ${pseudoAbsolute}

    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    z-index: 1;
    ${({ theme }) => css`
      background: radial-gradient(${rgba(theme.colors.white, 0)} 30%, ${rgba(theme.colors.white, 1)});
    `}
    will-change: opacity, transform;
    opacity: 0;
    border-radius: 50%;
  }

  ${getBadgeSize}

  ${({ theme, featured, hq, highlihted, customLogo, customColors }) => css`
    ${featured && css`
      ${backgroundPrimary}
    `}

    ${customColors && css`
      ${backgroundPrimary}
    `}

    ${customLogo && css`
      padding: 0.2rem;

      & > ${SquareBadgeLogo} {
        background-color: ${theme.colors.white};
        width: 100%;
        height: 100%;
        border-radius: 2px;
        padding: 0.2rem;
        display: flex;
        align-items: center;
    
        & > img {
          position: relative;
          max-width: 100%;
          max-height: 100%;
          border-radius: 2px;
        }
      }
    `}

    ${hq && css`
      opacity: 1;
      background-color: ${theme.colors.white};
      color: ${theme.colors.primary};
      padding: 0;
      height: 3.5rem;
      width: 3.5rem;
      transform: translateY(0);
      ${fontStack('campton')}
    `}

    ${highlihted && css`
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.7s;
    
      &::before {
        animation-name: ${squareBadgeHighlightRadiate};
        animation-duration: 2.5s;
        animation-delay: 0.85s;
        animation-iteration-count: 1;
        animation-timing-function: ${eases['outExpo']};
      }
    `}
  `}
`;

