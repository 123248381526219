import React from 'react';
import { useLocation } from 'react-router-dom';

import { pathMatchRouteName } from '@/lib/routes';

const useRoutePathMatcher = (routeNames = [], exact = true) => {
  const { pathname } = useLocation();

  const routeNamesRef = React.useRef(routeNames);

  return React.useMemo(
    () =>
      !!routeNamesRef.current?.some?.((routeName) =>
        pathMatchRouteName(pathname, routeName, exact),
      ),
    [pathname, exact],
  );
};

export default useRoutePathMatcher;
