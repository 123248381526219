import { Helmet } from 'react-helmet';

import GenericError from '@/components/error-handling/GenericError';

const UnhandledError = (props) => {
  return (
    <>
      <Helmet>
        <title>Something went wrong</title>
      </Helmet>

      <GenericError {...props} />
    </>
  );
};

export default UnhandledError;
