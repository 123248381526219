import { useHistory, useLocation } from 'react-router-dom';

import ErrorContainer from '@/components/error-handling/app-level/ErrorContainer';
import Mq from '@/components/helpers/Mq';
import Menu from '@/components/menu';
import FewerEmailsModal from '@/components/modals/FewerEmailsModal';
import LoginSignupModal from '@/components/modals/LoginSignupModal';
import MoreModal from '@/components/modals/MoreModal';
import NavSmall from '@/components/nav/NavSmall';
import NotificationsCentral from '@/components/notifications/NotificationsCentral';
import MainRoutes from '@/components/routes';

import { useErrorContext } from '@/context/Error';

import { page } from '@/lib/analytics';
import { pathMatchRouteName } from '@/lib/routes';

import { useSetIsIncomingBreatherUser } from '../breather/hooks';

const MainContent = () => {
  const location = useLocation();
  const { appError } = useErrorContext();

  // Breather Incoming Session
  useSetIsIncomingBreatherUser();

  const showSimpleNav = ['guestInvitationIndex'].some((it) =>
    pathMatchRouteName(location.pathname, it),
  );

  // When routes change, call generic analytics pageview function
  const { listen: onRouteChange } = useHistory();

  onRouteChange(page);

  return (
    <>
      <NotificationsCentral />

      <Menu />

      {!appError && <MainRoutes />}
      {!!appError && <ErrorContainer />}

      <Mq max="large">
        <NavSmall simple={showSimpleNav} />
        <MoreModal />
      </Mq>

      <LoginSignupModal />
      <FewerEmailsModal />
    </>
  );
};

export default MainContent;
