import React from 'react';

import useDeskpassAPI from '@/api/deskpass/useAPI';

const Context = React.createContext({});
const { Provider } = Context;

export const GlobalConfigProvider = ({ children }) => {
  const [{ data: globalConfig = {}, loading, ready, error }] = useDeskpassAPI(
    (api) => () => api.global.getConfig(),
  );

  const context = React.useMemo(
    () => ({
      ready,
      globalConfig,
      loading,
      error,
    }),
    [globalConfig, ready, loading, error],
  );

  return <Provider value={context}>{children}</Provider>;
};

export const useGlobalConfigContext = () => React.useContext(Context);

export default Context;
