import styled, { css } from 'styled-components/macro';

import { subtleShadow } from '@/styles/js/utils/shadow';
import { commonSpacingContent } from '@/styles/js/utils/spacing';

export const ContentSection = styled.div`
  ${commonSpacingContent()};

  text-align: ${props => (props.center ? 'center' : 'left')};

  ${props =>
    props.important &&
    css`
      padding: 1.5rem;
      border: solid 1px ${props.theme.primary};
      border-radius: 4px;

      ${subtleShadow};
    `}
`;
