// import 'lib/logRocket';
import 'lib/openReplay';
import { createRoot } from 'react-dom/client';

import App from '@/components/App';
import ErrorBoundary from '@/components/error-handling/ErrorBoundary';
import SvgSprites from '@/components/graphics/SvgSprites';

import AppContextProviders from '@/context/App';

import AppStyles from '@/styles/js/global';

import './polyfills';
import * as serviceWorker from './serviceWorker';

const appRoot = document.getElementById('app-root');
const root = createRoot(appRoot);

const initialNodes = (
  <>
    <AppStyles />
    <SvgSprites />

    <ErrorBoundary>
      <AppContextProviders>
        <App />
      </AppContextProviders>
    </ErrorBoundary>
  </>
);

root.render(initialNodes);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
