import { useState } from 'react';

import { useRouterContext } from '@/context/Router';
import { useUserContext } from '@/context/User';

import useEvent from '@/hooks/useEvent';
import { useMountAsync } from '@/hooks/useMount';
import useWatch from '@/hooks/useWatch';

import {
  clearIsBreatherUser,
  getIsBreatherUser,
  setIsBreatherUser,
} from '@/lib/browserStorage';

/*
 * Save Incoming Breather Users into localforage.
 */
export function useSetIsIncomingBreatherUser() {
  const { loginSource } = useRouterContext().parsedSearchParams;
  const { authenticated, user } = useUserContext();

  useMountAsync(async () => {
    if (!authenticated && loginSource === 'breather') {
      await setIsBreatherUser();
    }
  });

  const onLogin = useEvent(async () => {
    if (authenticated && user?.meta?.breather) {
      await clearIsBreatherUser();
    }
  });

  useWatch(() => {
    onLogin();
  }, [authenticated]);
}

export function useIsIncomingBreatherUser() {
  const [state, setState] = useState(false);

  const { authenticated } = useUserContext();

  useMountAsync(async () => {
    if (!authenticated) {
      const value = await getIsBreatherUser();
      setState(value);
    }
  });

  return !authenticated && state;
}
