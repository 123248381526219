import React from 'react';

/**
 * useEventListener
 *
 * @param {string} eventName - The name of event to listen
 * @param {function} handler - The callback function that injects the event itself for event manipulation
 * @param {object} elementOrRef - The Dom elementOrRef where the event will be added. Defaults to window
 */
function useEventListener(
  eventName, // string to allow custom event
  handler,
  elementOrRef = window,
) {
  // Create a ref that stores handler
  const savedHandler = React.useRef();

  let targetElement = elementOrRef;

  if (elementOrRef?.current) {
    targetElement = elementOrRef?.current;
  }

  React.useEffect(() => {
    if (
      !(targetElement && typeof targetElement.addEventListener === 'function')
    ) {
      return;
    }

    // Update saved handler if necessary
    if (savedHandler.current !== handler) {
      savedHandler.current = handler;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = (event) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!savedHandler?.current) {
        savedHandler.current(event);
      }
    };

    targetElement.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, targetElement, handler]);
}

export default useEventListener;
