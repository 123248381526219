import React from 'react';

import { AppSetupError } from '@/lib/errors';

import useSetupContexts from './useSetupContexts';

// TODO For this to work properly the contexts
// should have an error state
export default function useContextErrorHandler() {
  const setupContexts = useSetupContexts();

  const hasError = setupContexts.some(({ error }) => !!error);

  React.useEffect(() => {
    if (hasError) {
      throw new AppSetupError();
    }
  }, [hasError]);
}
