import { useState } from 'react';

import SignupForm from '@/components/forms/SignupForm';
import Icon from '@/components/graphics/Icon';
import Modal from '@/components/modals/Modal';
import Tabs, { Tab } from '@/components/nav/Tabs';
import Heading from '@/components/text-headings/Heading';

import { useUserContext } from '@/context/User';

import useWindowResize from '@/hooks/useWindowResize';

import { isMobile } from '@/lib/userAgent';

import * as Styled from './index.styles';

const MAX_SHRINK_SIZE = 1268;

const TABS = {
  login: 'Login',
  signup: 'Get Started',
};

function LoginSignupModal() {
  const [selectedTab, setSelectedTab] = useState(() => TABS.signup);
  const { showSignupLoginModal, closeSignupLoginModal } = useUserContext();
  const { height: windowHeight } = useWindowResize();

  return (
    <Modal
      sticky
      // Used to adjust modal height on shrinked desktop screens
      fillViewHeight={Boolean(!isMobile() && windowHeight < MAX_SHRINK_SIZE)}
      no-padding
      type="center"
      label=""
      show={showSignupLoginModal}
      onExitTrigger={closeSignupLoginModal}
      onClose={closeSignupLoginModal}
      renderNav={() => (
        <>
          <Styled.CloseIcon onClick={closeSignupLoginModal}>
            <Icon type={'close-x-24'} />
          </Styled.CloseIcon>
          <Styled.Tabs>
            <Tabs
              full
              bordered
              selected={selectedTab}
              onChange={setSelectedTab}
            >
              <Tab name={TABS.signup}>
                <Heading el="h4">{TABS.signup}</Heading>
              </Tab>
              <Tab name={TABS.login}>
                <Heading el="h4">{TABS.login}</Heading>
              </Tab>
            </Tabs>
          </Styled.Tabs>
        </>
      )}
    >
      <Styled.ModalContent>
        {selectedTab === TABS.login && <Styled.LoginForm />}
        {selectedTab === TABS.signup && <SignupForm />}
      </Styled.ModalContent>
    </Modal>
  );
}

export default LoginSignupModal;
