import styled, { css } from 'styled-components/macro';

import Icon from '@/components/graphics/Icon';

import mq from '@/styles/js/utils/mq';
import units from '@/styles/js/units';
import { rgba } from '@/styles/js/utils/colors';
import { colors } from '@/styles/js/utils/theme';
import { processingAnimation } from '@/styles/js/utils/animation';
import { Link } from 'react-router-dom';

const inputHorizontalPadding = '4rem';

const infoTextColor = '#495596';

const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ComboBox = styled.div`
  position: relative;
  max-height: 100%;
`;

export const GeocodingSearch = styled.div`
  width: 41rem;
  max-height: 100%;
  position: relative;
  user-select: none;

  ${mq('large')} {
    max-width: 35rem;
  }

  ${mq('xlarge')} {
    max-width: 41rem;
  }
`;

export const Input = styled.input`
  max-height: 100%;
  height: 4rem;
  width: 100%;
  border: 1px solid ${colors.rule};
  outline: none;
  padding: 1rem;
  padding-left: ${inputHorizontalPadding};

  ${({ includeClear }) => includeClear && css`
    padding-right: ${inputHorizontalPadding};
  `}

  ${({ menuOpen }) => !menuOpen && css`
    border-radius: 4px;
  `}

  ${({ menuOpen }) => menuOpen && css`
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `}

  &::placeholder {
    color: ${colors.textLight}
  }
`;

const IconArea = css`
  ${flexCenter}
  position: absolute;
  height: 100%;
  width: ${inputHorizontalPadding};
`;

export const LeftIconArea = styled.div`
  ${IconArea}
  margin-left: 6px;
`;

export const LeftIcon = styled(Icon).attrs(({ $loading }) => ({
  type: $loading ? 'processing-24' : 'search-24'
}))`
  ${({ $loading }) => css`
    color: ${$loading ? colors.primary : colors.textLight};

    ${$loading && processingAnimation}
  `}
`;

export const ClearIcon = styled(Icon).attrs({
  type: 'close-x-17'
})`
  color: ${rgba(colors.text, 0.25)};
  margin-right: 6px;
`;

export const CaratRight = styled(Icon).attrs({
  type: 'carat-right-24'
})`
  color: ${colors.ruleDark};
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  position: absolute;
  margin: 0 1rem;

  & > svg {
    height: 1.8rem;
    width: 1.8rem;
    stroke-width: 1.5;
  }
`;

export const Clear = styled.button.attrs({
  type: 'button'
})`
  ${IconArea}
  display: inline-flex;
  right: 0;
  width: 4.4rem;
  background-color: transparent;
  border: none;

  &:hover, &:active, &:focus {
    .Icon {
      color: initial;
    }
  }
`;

export const SpaceIcon = styled(Icon).attrs({
  type: 'marker-inverted-24'
})`
  color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CityIcon = styled(Icon).attrs({
  type: 'map-24'
})`
  color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const overlayBox = css`
  border: 1px solid ${colors.rule};
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  width: 100%;
  box-shadow: 0 2px 5px ${rgba(colors.text, 0.12)};
`;

export const RoomsMenuOverlay = styled.div`
  position: absolute;
  z-index: ${units.zIndex.geocodingSearch};
  width: 120%;
  top: 0;
  left: 100%;
  padding-left: 2rem;
`;

export const RoomsMenu = styled.ul`
  ${overlayBox}

  border-radius: 4px;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${mq({ until: 'large' })} {
    box-shadow: none;
    border: none;
  }
`;

const ellipsisBreak = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Menu = styled.ul`
  ${overlayBox}

  position: absolute;
  z-index: ${units.zIndex.geocodingSearch};
  padding: 1rem;
  padding-top: 0;

  ${mq({ until: 'large' })} {
    margin-top: 1rem;
  }

  ${({ $open }) => !$open && css`
    display: none;
  `}
`;

export const MenuItem = styled.li`
  position: relative;
  padding: 1rem;
  min-height: 4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  ${({ $highlighted }) => $highlighted && css`
    background-color: ${colors.accentFaint};
  `}

  &:hover {
    background-color: ${colors.faint};
  }

  &:before {
    content: '';
    position: absolute;
    background: ${colors.rule};
    height: 1px;
    top: 0;
    left: 0;
    right: 0;
  }
`;

export const MenuItemSpace = styled.div`
  ${ellipsisBreak}

  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ $withRooms }) => $withRooms && css`
    padding-right: 3rem;


    ${mq({ until: 'large' })} {
      padding-right: 7rem;
    }
  `}
`;

export const MenuItemSpaceMetaInfo = styled.div`
  color: ${infoTextColor};
  font-size: 1.1rem;
  text-transform: uppercase;
  ${ellipsisBreak}

  & > *:not(:last-child) {
    margin-right: 3px;
  }
`;

export const MenuItemContent = styled.p`
  ${ellipsisBreak}

  position: relative;
  line-height: 1.2;
`;

export const Highlighted = styled.strong`
  color: ${colors.primary};
`;

export const ItemInfo = styled.span`
  ${ellipsisBreak}

  color: ${infoTextColor};
  font-size: 1.4rem;
`;

export const ItemInfoSpace = styled.strong`
  font-size: 1.6rem;
`;

export const ItemInfoCity = styled.strong`
  font-size: 1.6rem;
`;

export const ItemInfoCityCount = styled.span`
  font-size: 1.4rem;
  color: ${infoTextColor};
`;

export const MeetingRoomIcon = styled(Icon).attrs({
  type: 'meeting-room-17'
})`
  margin-right: 4px;
`;

export const OfficeIcon = styled(Icon).attrs({
  type: 'office-17'
})`
  margin-right: 4px;
`;

export const LocationCard = styled(Link)`
  display: grid;
  grid-template-columns: 10rem 1fr;
  gap: 2rem;
  padding: 1rem;

  &:hover {
    background-color: ${colors.faint};
  }
`;

export const LocationCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;

  & > h2 {
    font-weight: bold;
  }

  & > p {
    line-height: 1.3;
    font-size: 1.4rem;
  }
`;

const linkStyles = css`
  color: ${colors.primary};
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  background: none;
  text-decoration: underline;
  padding: 0;
  margin: 0 1rem;
`;

export const SeeAllLink = styled.button`
  ${linkStyles}
`;

export const SeeSpaceLink = styled(Link)`
  ${linkStyles}
`;

export const GoToSpaceBox = styled.div`
  background-color: #E7EDFA;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  align-items: center;
`;

export const RoomsMenuButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  z-index: ${units.zIndex.geocodingSearch + 100};
  padding: 1rem;
  border: none;
  border-radius: 4px;
  color: ${colors.primary};
  background-color: #E7EDFA;
  font-weight: 700;
  font-size: 1.3rem;
`;
