import styled, { css } from 'styled-components';

const positionAbsolute = css`
  position: absolute;
  z-index: 10;
`;

const getPlacement = ({ placement }) => ({
  'topLeft': css`
    ${positionAbsolute}
    top: 1.4rem;
    left: 1.4rem;
  `,
  'topRight': css`
    ${positionAbsolute}
    top: 1.4rem;
    right: 1.4rem;
  `,
  'bottomLeft': css`
    ${positionAbsolute}
    bottom: 1.4rem;
    left: 1.4rem;
  `,
  'bottomRight': css`
    ${positionAbsolute}
    bottom: 1.4rem;
    right: 1.4rem;
  `,
}[placement]);

const getRightBadgePadding = ({ rightBadgePadding }) => rightBadgePadding && css`
  right: 1.7rem;
`;

/**
 * Styled RoomOrSpaceImagePlacement
 * ---
 * (Optional) props
 * - `placement`
 *    - `topLeft`
 *    - `topRight`
 *    - `bottomLeft`
 *    - `bottomRight`
 * - `rightBadgePadding`
 */
export const RoomOrSpaceImagePlacement = styled.div`
  ${getPlacement}
  ${getRightBadgePadding}
`;

/**
 * Styled RoomOrSpaceImage
 * ---
 * (Optional) props
 * - `placement`
 *    - `topLeft`
 *    - `topRight`
 *    - `bottomLeft`
 *    - `bottomRight`
 * - `rightBadgePadding`
 */
export const RoomOrSpaceImage = styled(RoomOrSpaceImagePlacement)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.faint};
`;
