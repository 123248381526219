import PropTypes from 'prop-types';

import { usePrefixProps } from '@/lib/utility';

import * as Styled from './index.styles';

const sizeProps = ['xxs', 'xs', 's', 'm', 'l', 'xl'];

const Spacer = (props) => {
  const prefixedProps = usePrefixProps(props, '$');
  return <Styled.Spacer {...prefixedProps} />;
};

const sizePropTypes = sizeProps.reduce(
  (propTypes, prop) => ({
    ...propTypes,
    [prop]: PropTypes.bool,
  }),
  {},
);

Spacer.propTypes = {
  ...sizePropTypes,
};

export default Spacer;
