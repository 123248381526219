import React from 'react';

import Button from '@/components/buttons-links/Button';
import ActionSet from '@/components/forms/ActionSet';
import FluidSvg from '@/components/graphics/FluidSvg';
import SvgUse from '@/components/graphics/SvgUse';
import Modal from '@/components/modals/Modal';
import Text from '@/components/text-headings/Text';

import RouterContext from '@/context/Router';

const FewerEmailsModal = () => {
  const { parsedSearchParams, removeSearchParams } =
    React.useContext(RouterContext);

  return (
    <Modal
      type="center"
      label="fewerEmailsConfirmation"
      show={parsedSearchParams.utm_content === 'feweremails'}
      onExitTrigger={() => {}}
      renderFooter={() => (
        <ActionSet padded>
          <Button
            widthSize="full"
            size="large"
            onClick={() => {
              removeSearchParams('utm_content');
            }}
          >
            Great!
          </Button>
        </ActionSet>
      )}
      fit-to-content
    >
      <FluidSvg percent={40}>
        <SvgUse id="radio-selected" category="full" />
      </FluidSvg>
      <Text size="large">
        <p>
          Thanks! We got the message and we'll be sending you only important
          news and updates.
        </p>
      </Text>
    </Modal>
  );
};

export default FewerEmailsModal;
