import React from 'react';

import { useCurrentRoomOrSpaceContext } from '@/context/CurrentRoomOrSpace';
import { useIpLocationContext } from '@/context/IpLocation';
import { useUserContext } from '@/context/User';

import { DEFAULT_FILTER_STATE } from './constants';
import useSearchParams from './useSearchParams';

/*
 * The order of precedence to set the initial location
 * should be resolved using these fields when available
 * following this order top down:
 *  - URL query params
 *  - [Only authenticated] Home City
 *  - IP Location
 *  - Chicago
 */
export default function useFilterInitialPlace() {
  const searchParams = useSearchParams();
  const { authenticated, user } = useUserContext();
  const { available: ipLocationAvailable, location: ipLocation } =
    useIpLocationContext();
  const { inSpaceDetailPage, inRoomDetailPage, currentSpace, currentRoom } =
    useCurrentRoomOrSpaceContext();

  /*
   * useState here is used so we only compute this the first time and
   * on following renders return the cached result
   */
  const [initialPlace] = React.useState(() => {
    // Sets initial location to active space lat/lng
    if (inSpaceDetailPage && currentSpace) {
      return {
        lat: currentSpace.lat,
        lng: currentSpace.lng,
      };
    }

    // Sets initial location to room.space lat/lng
    if (inRoomDetailPage && currentRoom) {
      return {
        lat: currentRoom.space.lat,
        lng: currentRoom.space.lng,
      };
    }

    // Sets initial location from URL query params
    if (searchParams.lat && searchParams.lng) {
      const { lat, lng, query } = searchParams;

      return {
        lat: Number(lat),
        lng: Number(lng),
        ...(query && { name: query }),
      };
    }

    // Sets initial location from user home city
    if (authenticated && user.city) {
      const { lat, lng, name } = user.city;
      return { lat, lng, name };
    }

    // Sets initial location from user IP Location
    if (ipLocationAvailable) {
      const {
        coords: { lat, lng },
        city,
        region,
      } = ipLocation;
      let name = city;
      if (region) name += `, ${region}`;

      return { lat, lng, name };
    }

    // Fallback to Chicago
    return DEFAULT_FILTER_STATE.place;
  });

  return initialPlace;
}
