export const TopLevelErrorTypes = {
  Unhandled: 'Unhandled',
  AppSetupError: 'AppSetupError',
  TeamInviteFailed: 'TeamInviteFailed',
};

export class AppSetupError extends Error {
  type = TopLevelErrorTypes.AppSetupError;
  message = 'Could not complete initial requests on app setup.';
}

export class TeamInviteError extends Error {
  type = TopLevelErrorTypes.TeamInviteFailed;
  title = 'Invitation Expired';
  message =
    'Please check your email for a new team invitation. ' +
    'If you don’t see it, please contact Deskpass Support.';
}

export const AppLevelErrorTypes = {
  NotFound: 'NotFound',
  Forbidden: 'Forbidden',
};
