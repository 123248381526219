import styled, { css, keyframes } from 'styled-components/macro';

const animateUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }  
`;

export const Drawer = styled.div`
  display: none;
  visibility: hidden;
  opacity: 0;

  position: fixed;
  flex: 1 0 auto;
  flex-direction: column;

  top: 0;
  left: 0;
  outline: 0;
  overflow-y: auto;

  // Add iOS momentum scrolling for iOS < 13.0
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
  transition: opacity 200ms;
  will-change: opacity;

  // Add full width or specify a number of px
  width: 100%;
  min-height: fill-available;

  ${({ open }) => open && css`
    display: flex;
    visibility: visible;
    opacity: 1;
    animation: ${animateUp} 0.2s;
    transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
  `}
`;
