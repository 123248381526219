import React from 'react';

import { getCityAndSpaceCount } from '@/api/deskpass/feeds';

import useEvent from '@/hooks/useEvent';
import useMount from '@/hooks/useMount';

import { consumerToHOC } from '@/lib/hoc';

const Context = React.createContext();
const { Consumer, Provider } = Context;

export const MetaProvider = ({ children }) => {
  const [metadata, setMetadata] = React.useState(() => {});
  const [loading, setLoading] = React.useState(() => true);

  // TODO move this into Deskpass client
  const getSpaceAndCityCount = useEvent(async () => {
    const { cityCount, spaceCount } = await getCityAndSpaceCount();

    setMetadata({
      count: {
        city: cityCount,
        space: spaceCount,
      },
    });
    setLoading(false);
  });

  useMount(() => {
    getSpaceAndCityCount();
  });

  const context = {
    metadata,
    loading,
    ready: !loading,
  };

  return <Provider value={context}>{children}</Provider>;
};

export const useMetaContext = () => React.useContext(Context);
export const MetaConsumer = Consumer;
export const withMetaContext = consumerToHOC(Consumer, 'metaContext');

export default Context;
