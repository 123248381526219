import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import SvgUse from '@/components/graphics/SvgUse';

import ThemeContext from '@/context/Theme';

const TeamLogo = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  align-content: center;

  > img,
  > svg {
    height: 2.5rem;
    margin: auto;
  }

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

const CompanyLogo = ({ simplified, white, ...rest }) => {
  // Then figure out if we should change the logo based on available Teams
  // customization options
  const { customized, companyLogo, simplifiedLogo, colors } =
    React.useContext(ThemeContext);

  // Set default Deskpass logo, either full-size
  // or smaller flavor without text
  let logo = simplified ? (
    <SvgUse
      id={`deskpass-logo-mini${white ? '-white' : ''}`}
      category="full"
      width="11"
      height="12"
      {...rest}
    />
  ) : (
    <SvgUse
      id="deskpass-logo"
      category="full"
      width="118"
      height="33"
      {...rest}
    />
  );

  if (customized) {
    if (companyLogo || simplifiedLogo) {
      // Use custom logo if set
      logo =
        simplified && simplifiedLogo ? (
          <img src={simplifiedLogo} alt="Company Logo" {...rest} />
        ) : (
          <img src={companyLogo} alt="Company Logo" {...rest} />
        );
    } else if (colors && colors.primary) {
      // Switch to white version of simplified logo if company colors are around
      // (white version is more likely to blend better with solid colors)
      logo = (
        <TeamLogo color={colors.primary}>
          <SvgUse
            id="deskpass-logo-mini-white"
            category="full"
            width="11"
            height="12"
            {...rest}
          />
        </TeamLogo>
      );
    }
  }

  return logo;
};

CompanyLogo.propTypes = {
  simplified: PropTypes.bool,
  white: PropTypes.bool,
};

CompanyLogo.defaultProps = {
  simplified: false,
  white: false,
};

export default CompanyLogo;
