import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Icon from '@/components/graphics/Icon';

import * as S from './Badge.styles';

/**
 * Badge
 * ---
 * A small text element that communicates some qualities
 * of the content it is associated with.
 * @param {String} size - `small | mdium | large`
 * @param {String} color - `white | altA | altB | altC | good | altGood | bad |warn | neutral | inactive`
 *
 * Other component props
 * - Icon
 */
const Badge = forwardRef(
  ({ children, icon, iconProps = {}, ...props }, ref) => (
    <S.Badge ref={ref} {...props}>
      {!!icon && <Icon type={icon} svgProps={iconProps} />}

      {children}
    </S.Badge>
  ),
);

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf([
    'white',
    'altA',
    'altB',
    'altC',
    'good',
    'altGood',
    'bad',
    'warn',
    'neutral',
    'inactive',
  ]),
};

export default Badge;
