import isEmpty from 'lodash/isEmpty';
import { useCallback, useContext, useMemo } from 'react';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import GeolocationContext from '@/context/Geolocation';

import { makeWithHook } from '@/lib/hoc';
import { closestCityByCoords } from '@/lib/mapHelpers';

/*
 * Wraps useDeskpassAPI with logic to fetch cities.
 */
const useCities = (hookArgs = {}, options = {}) => {
  const [{ data: cities = [], ...requestState }, loadCities] = useDeskpassAPI(
    (api) => (callerArgs) => {
      return api.city.getAll(isEmpty(callerArgs) ? hookArgs : callerArgs);
    },
    options,
  );

  const { coords, available } = useContext(GeolocationContext);

  const groupCityBy = useCallback(
    (field) =>
      cities.reduce(
        (cityGroupedBy, city) => ({ ...cityGroupedBy, [city[field]]: city }),
        {},
      ),
    [cities],
  );

  const citiesBySlug = useMemo(() => groupCityBy('slug'), [groupCityBy]);

  const citiesById = useMemo(() => groupCityBy('id'), [groupCityBy]);

  const citiesSortedBySlug = useMemo(
    () =>
      cities.sort((a, b) =>
        a.slug?.toLowerCase() >= b.slug?.toLowerCase() ? 1 : -1,
      ),
    [cities],
  );

  const closestCity = useMemo(() => {
    if (!available) return null;

    return closestCityByCoords(coords, cities);
  }, [available, coords, cities]);

  return [
    {
      ...requestState,
      cities,
      citiesBySlug,
      citiesById,
      citiesSortedBySlug,
      closestCity,
    },
    loadCities,
  ];
};

export default useCities;

// Expose a HOC flavor for compatibility with legacy components
export const withCitiesHook = makeWithHook(useCities, 'citiesHook');
