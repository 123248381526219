import styled, { css, keyframes } from 'styled-components/macro';

import typeStyle from '@/styles/js/fonts/typeStyles';

import { commonFocus, notSelectable, resetButtonStyles } from '@/styles/js/utils/interations';
import { notTouch } from '@/styles/js/utils/media';
import { rgba } from '@/styles/js/utils/colors';
import mq from '@/styles/js/utils/mq';
import { em } from '@/styles/js/utils/units';

import { Icon } from '@/components/graphics/Icon.styles';
import { CommonLink } from '@/components/buttons-links/CommonLink';
import { subtleShadow } from '@/styles/js/utils/shadow';
import { StripeSecure as StyledStripeSecure } from '@/components/details/StripeSecure.styles';

const buttonProcessingAnim = keyframes`
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
`;

/**
 * Button type/color styles
 * @param {string} color - `warning | reverse | reverse-no-shadow | lock-locked | lock-unlocking | lock-unlocked`
 */
const getButtonColor = ({ theme, color }) => ({
  'warning': css`
    background-color: ${theme.colors.red};
  `,
  'reverse': css`
    ${subtleShadow}

    color: ${theme.colors.text};
    background-color: white;

    ${notTouch} {
      &:hover {
        color: white;
        background-color: ${theme.colors.text};
      }
    }
  `,
  'reverse-no-shadow': css`
    color: ${theme.colors.text};
    background-color: white;
    border: 1px solid ${theme.colors.rule};

    &:hover {
      ${notTouch} {
        color: white;
        background-color: ${theme.colors.text};
      }
    }
  `,
  'lock-locked': css`
    pointer-events: none;
    background-color: ${theme.colors.accent};
  `,
  'lock-unlocking': css`
    pointer-events: none;
    background-color: ${theme.colors.primary};
  `,
  'lock-unlocked': css`
    pointer-events: none;
    background-color: ${theme.colors.green};
  `,
}[color]);

/**
 * Button Alt styles
 * @param {string} alt  - `alt | alt-small | alt-large`
 * @returns 
 */
const getButtonAlt = ({ theme, alt, size }) => ({
  'alt': css`
    color: ${theme.colors.primary};
    background-color: transparent;
    border: 1px solid ${theme.colors.primary};

    ${notTouch} {
      &:hover {
        border-color: ${theme.colors.text};
        color: white;
      }
    }

    ${size === 'warning' && css` 
      &&& {
        background-color: transparent;
        border-color: ${theme.colors.red};
        color: ${theme.colors.red};
      }
    `}
  `,
  'alt-small': css`
    min-width: auto;
  `,
  'alt-large': css`
    min-width: 34rem;

    ${mq({ until: 'smallMedium' }) }{
      min-width: 30rem;
    }
  `,
}[alt]);

/**
 * Button size
 * @param {string} size - `large | small | mini | micro`
 */
const getButtonSize = ({ size }) => ({
  'large': css`
    ${notTouch} {
      ${typeStyle('button-l')}

      padding: ${em(20, 18)} ${em(40, 18)} ${em(22, 18)};
    }
  `,
  'small': css`
    ${typeStyle('button-s')}

    min-width: auto;
    padding: ${em(11, 13)} ${em(15, 13)} ${em(13, 13)};
  
    > ${Icon}:first-child {
      margin-left: -0.3rem;
    }
  
    > ${Icon}:first-child + span {
      padding-left: 3rem;
      padding-right: 2.2rem;
    }
  `,
  'mini': css`
    ${typeStyle('button-s')}

    min-width: auto;
    padding: ${em(7, 13)} ${em(15, 13)} ${em(9, 13)};

    // Adjacent Styling: Less margin-top when follows mini "update credit card" button - @Collin
    & + ${StyledStripeSecure} {
      margin-top: 1.2rem;
    }
  `,
  'micro': css`
    ${typeStyle('mini')}

    font-weight: bold;
    min-width: auto;
    border-radius: 0.3rem;
    padding: ${em(4, 12)} ${em(10, 12)} ${em(5, 12)};
  `,
}[size]);

/**
 * Button width size
 * @param {string} widthSize - `full | full-small`
 */
const getButtonWidthSize = ({ widthSize }) => ({
  'full': css`
    display: block;
    width: 100%;
  `, 
  'full-small': css`
    ${mq({ until: 'smallMedium' })} {
      display: block;
      width: 100%;
    }
  `,
}[widthSize]);

/**
 * Styled Button
 * ---
 * Commonly interacted-with object.
 * 
 * (Optional) props
 *  - `disabled`
 *  - `color` 
 *      - `warning` - Represents an important/destructive action.
 *      - `reverse` - For use on dark backgrounds
 *      - `reverse-no-shadow`
 *      - `lock-locked` - Used exclusively within an Unlockable component when locked
 *      - `lock-unlocking` - Used exclusively within an Unlockable component when unlocking
 *      - `lock-unlocked` - Used exclusively within an Unlockable component when unlocked
 *  - `alt`
 *      - `alt` - Indicates that the button is a less important action.
 *      - `alt-small`
 *      - `alt-large`
 *  - `altHover` - Alternate hover color
 *  - `size`
 *      - `large` - Larger version
 *      - `small` - Smaller version
 *      - `mini` - Very small version
 *      - `micro` - Super small version
 *  - `widthSize`
 *      - `full` - 100% width of parent
 *      - `full-small` - 100% width of parent only at small viewport sizes
 *  - `noMinWidth` - No minimum width set
 *  - `arrow` - includes an arrow icon at the end
 *  - `processing` - indicates that work is being done
 *  - `flat`
 *  - `noMarginTop`
 */

export const Button = styled(CommonLink).attrs(({ className }) => ({
  className: `Button ${className ?? ''}`
}))`
  ${resetButtonStyles}
  ${typeStyle('button')}
  ${notSelectable}

  color: white;
  padding: ${em(16, 16)} ${em(30, 16)} ${em(18, 16)};

  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  display: inline-block;
  position: relative;
  text-align: center;
  min-width: 27rem;

  &:focus {
    ${commonFocus}
  }

  ${notTouch} {
    &:hover {
      background-color: ${({ theme }) => theme.colors.text};
    }
  }

  &:active {
    opacity: 0.8;
  }

  > ${Icon}:first-child {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    margin-left: -1rem;
  }

  > ${Icon}:first-child + span {
    padding-left: 3rem;
    padding-right: 1rem;
  }

  ${getButtonColor}
  ${getButtonAlt}
  ${getButtonSize}
  ${getButtonWidthSize}

  ${({ theme, altHover, noMinWidth, arrow, processing, flat, noMarginTop, disabled }) => css`
    ${altHover && css`
      ${notTouch} {
        &:hover {
          background-color: ${theme.colors.primary};
          border-color: ${theme.colors.primary};
        }
      }
    `}

    ${flat && css`
      border-radius: 0;
    `}

    ${noMinWidth && css`
      min-width: auto;
    `}

    ${noMarginTop && css`
      &&& {
        margin-top: unset;
      }
    `}

    ${arrow && css`
      padding-right: ${em(48, 16)};
      padding-left: ${em(42, 16)};
    
      > ${Icon}:last-child {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: ${em(14, 16)};
      }
    `}

    ${processing && css`
      > ${Icon}:first-child {
        // TODO: Button processing spinner wobbles in iOS Safari
        animation-name: ${buttonProcessingAnim};
        animation-duration: 0.8s;
        animation-timing-function: ease(linear);
        animation-iteration-count: infinite;
        will-change: transform;
      }
    
      > ${Icon}:first-child + span {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    `}

    ${disabled && css`
      &&&,
      &&&:hover,
      &&&:active {
        color: ${rgba(theme.colors.text, 0.25)};
        background-color: ${rgba(theme.colors.text, 0.12)};
        border-color: transparent;
        cursor: auto;
        opacity: 1;
        pointer-events: none;
      }
    `}
  `}
`;