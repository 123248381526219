import config from '@/lib/config';
import NetworkClient from '@/lib/network/client';

// Local Server API client

const baseURL = `${config.SERVER_URL}/api`;

export class LocalServerNetworkClient extends NetworkClient {
  config = {
    baseURL,
    mode: 'same-origin',
    credentials: 'include',
    withCredentials: true,
  };
}

// Default singleton instance
export default new LocalServerNetworkClient();
