import styled from 'styled-components/macro';
import { CardElement } from '@stripe/react-stripe-js';

import { colors } from '@/styles/js/utils/theme';

export const Card = styled(CardElement)`
  border-bottom: 1px solid ${colors.rule};
  padding-bottom: 0.9375em;
`;

export const Container = styled.div`
  width: 100%;
`;
