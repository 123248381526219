import moment from 'moment-timezone';
import React from 'react';

import FilterContext from '@/context/Filter';

import useEvent from '@/hooks/useEvent';

import { formatDuration, momentFromTime } from '@/lib/dateHelpers';

const useWhenFilterProps = () => {
  const filterContext = React.useContext(FilterContext);
  const { filters, updateFilters } = filterContext;

  const { start, end } = filters;

  const handleDayClick = useEvent((day) => updateFilters({ date: day }));

  const reset = useEvent(() =>
    updateFilters({
      date: null,
      start: '',
      end: '',
    }),
  );

  const disabledDays = React.useMemo(
    () =>
      [].concat(
        // Time in the past
        (day) => moment(day).isBefore(moment().startOf('day').toDate()),
      ),
    [],
  );

  const formatedTimeDuration = React.useMemo(() => {
    if (!start || !end) {
      return '0 hours';
    }

    return formatDuration(
      momentFromTime(moment(), start),
      momentFromTime(moment(), end),
    );
  }, [start, end]);

  return {
    ...filterContext,
    formatedTimeDuration,
    handleDayClick,
    disabledDays,
    reset,
  };
};

export default useWhenFilterProps;
