import config from '@/lib/config';
import NetworkClient from '@/lib/network/client';

const client = new (class HubspotNetworkClient extends NetworkClient {
  config = {
    baseURL: config.HUBSPOT_URL,

    transformData: ({ data } = {}) => data?.result,

    onError: (error) => {
      console.error('Error sending Hubspot request:', error);
    },
  };
})();

/**
 * Send a legacy Custom Behavioral Event to Hubspot (as opposed to a newer,
 * probably-still-in-beta CBE).
 *
 * @param {String} email User email event is about
 * @param {String} eventName Name of event to fire
 * @param {Object} properties Object of properties to send along with the event
 */
export async function legacyEvent(email, eventName, properties = null) {
  if (config.DISABLE_HUBSPOT) {
    console.info(
      `Skipping sending Hubspot event ${eventName}: DISABLE_HUBSPOT flag is set.`,
    );

    return;
  }

  try {
    return client.post('/event/legacy', {
      email,
      eventName,
      properties,
    });
  } catch (err) {
    console.error(`Unable to send legacy event ${eventName} to Hubspot:`, err);
  }
}

/**
 * Send a shiny new (beta) Custom Behavioral Event to Hubspot (as opposed to the
 * older, stankier Legacy event).
 *
 * @param {String} email User email event is about
 * @param {String} eventName Name of event to fire
 * @param {Object} properties Object of properties to send along with the event
 */
export async function event(email, eventName, properties = null) {
  if (config.DISABLE_HUBSPOT) {
    console.info(
      `Skipping sending Hubspot event ${eventName}: DISABLE_HUBSPOT flag is set.`,
    );

    return;
  }

  try {
    return client.post('/event', {
      email,
      eventName,
      properties,
    });
  } catch (err) {
    console.error(`Unable to send beta event ${eventName} to Hubspot:`, err);
  }
}
