import styled from 'styled-components/macro';

export const FluidSvg = styled.div`
  width: 100%;
  height: 0;
  position: ${({ absolute }) => absolute ? 'absolute' : 'relative'};

  ${({ absolute }) => absolute && `
  top: 0;
  left: 0;
  `}

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
