import PropTypes from 'prop-types';
import React from 'react';

import CompanyLogo from '@/components/brand/CompanyLogo';

import ThemeContext from '@/context/Theme';
import UserContext from '@/context/User';

import * as S from './SquareBadge.styles';

const SquareBadge = ({ hq, showLogo, highlighted, featured, size }) => {
  const { companyLogo, simplifiedLogo, colors } =
    React.useContext(ThemeContext);
  const { teamUser } = React.useContext(UserContext);
  const hasCustomLogo = !!(companyLogo || simplifiedLogo);

  return (
    <S.SquareBadge
      hq={hq}
      size={size}
      featured={featured}
      highlighted={highlighted}
      customLogo={hasCustomLogo}
      customColors={!!colors.primaryColor}
    >
      {teamUser && hq && <strong>HQ</strong>}

      {showLogo && !hq && (
        <S.SquareBadgeLogo>
          <CompanyLogo simplified white={featured} />
        </S.SquareBadgeLogo>
      )}
    </S.SquareBadge>
  );
};

SquareBadge.defaultProps = {
  featured: false,
  highlighted: false,
  showLogo: false,
  hq: false,
  size: 'small',
};

SquareBadge.propTypes = {
  featured: PropTypes.bool,
  highlighted: PropTypes.bool,
  showLogo: PropTypes.bool,
  hq: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default SquareBadge;
