import styled, { css } from 'styled-components';

import typeStyle from '@/styles/js/fonts/typeStyles';
import smoothing from '@/styles/js/fonts/smoothing';
import eases from '@/styles/js/utils/eases';
import { notSelectable } from '@/styles/js/utils/interations';
import { rgba } from '@/styles/js/utils/colors';
import { absolute as positionAbsolute } from '@/styles/js/utils/position';

/**
 * Styled SegmentedControlOption
 * ---
 * (Optional) prop
 * - `checked` - boolean
 */
export const SegmentedControlOption = styled.div`
  flex: 1;
  text-align: center;
  position: relative;
  z-index: 1;
  outline: none;
  width: 6rem;

  > input {
    display: none;
  }

  > label {
    ${typeStyle('body')}
    ${smoothing}

    font-size: inherit;

    transition: color 0.2s;
    color: ${({ theme }) => theme.colors.accent};
    font-weight: bold;
    position: relative;
    z-index: 1;
    will-change: transform;
    pointer-events: none;
    outline: none;
  }

  ${({ theme, checked }) => checked && css`
    & > label,
    & > input:checked + label {
      color: ${theme.colors.white};
    }
  `}
`;

/**
 * Styled SegmentedControlBg
 * ---
 */
export const SegmentedControlBg = styled.span`
  ${positionAbsolute}

  display: block;
  background-color: ${({ theme }) => theme.colors.accent};
  border-radius: 0.3rem;
  transition: transform 0.5s ${eases['outQuint']};
  will-change: transform;
`;

/**
 * SegmentedControl Size Styles
 * @param {string} size - `small | mini`
 */
const getSizeStyles = ({ theme, size }) => ({
  'small': css`
    border-radius: 0.4rem;
    border-color: ${theme.colors.rule};
    width: auto;

    ${SegmentedControlOption} {
      padding: 0.4rem 1.2rem;

      > label {
        ${typeStyle('body-s')}

        font-weight: bold;
        color: ${theme.colors.textDisabled};
      }
    }

    ${SegmentedControlBg} {
      border-radius: 0.2rem;
    }
  `,
  'mini': css`
    border: 1px solid ${({ theme }) => theme.colors.accentWhitened};
    border-radius: 0.3rem;
    width: auto;
    height: auto;

    ${SegmentedControlOption} {
      padding: 0.3rem 0.7rem;

      > label {
        ${typeStyle('mini')}

        font-size: 1.1rem;
        font-weight: bold;
      }
    }

    ${SegmentedControlBg} {
      border-radius: 0.3rem;
    }
  `,
}[size]);

/**
 * Styled SegmentedControl
 * ---
 * A form control that allows the user to toggle between several values.
 *
 * (Optional) props
 * - `reverse` - For use on dark backgrounds
 * - `size`
 *    - `small` - A smaller version
 *    - `mini` - A much smaller version
 */
export const SegmentedControl = styled.div`
  ${notSelectable}

  padding: 0.5rem;
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.accentTransparentDark};
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;

  max-height: 6rem;

  ${getSizeStyles}

  ${({ theme, reverse }) => css`

    ${reverse && css`
      background-color: ${rgba(theme.colors.text, 0.35)};

      ${SegmentedControlOption} {
        > label {
          color: ${theme.colors.white};
        }

        > input:checked + label {
          color: ${theme.colors.accent};
        }
      }

      ${SegmentedControlBg} {
        background-color: ${theme.colors.white};
      }
    `}
  `}
`;
