// import the babel macro here to process css prop
import { useCombobox } from 'downshift';
import PropTypes from 'prop-types';
import { memo, useCallback, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import reactStringReplace from 'react-string-replace';
import 'styled-components/macro';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import { SeparatorLabel } from '@/components/bits/SeparatorLabel';
import Modal from '@/components/modals/Modal';
import NavBar from '@/components/nav/NavBar';
import RoomOrSpaceImage from '@/components/rooms-or-spaces/RoomOrSpaceImage';

import { useFilterContext } from '@/context/Filter';

import useDebounce from '@/hooks/useDebounce';
import useEvent from '@/hooks/useEvent';
import useMediaQuery from '@/hooks/useMediaQuery';

import { formatCurrency } from '@/lib/currencyHelpers';
import { cssPropType } from '@/lib/props';
import route from '@/lib/routes';
import { removeEmpty } from '@/lib/utility';

import * as S from './Search.styles';

const { InputChange, InputBlur, ItemClick } = useCombobox.stateChangeTypes;

/**
 * GeocodingSearch
 * ---
 *
 * Autocomplete search input that queries places from
 * Deskpass API endpoint that wraps google places API
 * and adds our own results to it.
 */
const GeocodingSearch = ({
  onSelectedItemChange = () => null,
  placeholder = 'Search locations and spaces...',
  initialInputValue = '',
  proximity,
  className,
  inputCSS,
  menuCSS,
  menuItemCSS,
  comboboxCSS,
}) => {
  const mobile = useMediaQuery({ max: 'large' });

  const showSecondaryOverlay = !mobile;

  const [inputValue, setInputValue] = useState(() => initialInputValue);

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const selfRef = useRef({
    isMouseInMenuItem: false,
  });

  const handleMouseEnter = useEvent((idx) => {
    if (!showSecondaryOverlay) return;

    selfRef.current.isMouseInMenuItem = true;
    setHoveredRowIndex(idx);
  });

  const debouncedMouseLeaveHandler = useDebounce(() => {
    if (selfRef.current.isMouseInMenuItem) return;

    setHoveredRowIndex(null);
  }, 200);

  const handleMouseLeave = useEvent(() => {
    if (!showSecondaryOverlay) return;

    selfRef.current.isMouseInMenuItem = false;
    debouncedMouseLeaveHandler();
  });

  const searchParams = removeEmpty({
    proximity,
  });

  const [searchState, search] = useDeskpassAPI(
    (api) => (address) => api.geocoding.search(address, searchParams),
    { fireOnMount: false },
  );

  const items = searchState?.data ?? [];

  const debouncedSearch = useDebounce(search);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    reset,
    closeMenu,
    openMenu,
    setInputValue: _setInputValue,
    inputValue: _inputValue,
    highlightedIndex,
  } = useCombobox({
    initialInputValue,
    items,
    itemToString: (item) => {
      return item.addressString ?? '';
    },
    onInputValueChange: ({ inputValue, type }) => {
      if (inputValue && type === InputChange) {
        setInputValue(inputValue);
        debouncedSearch(encodeURIComponent(inputValue));
      }
    },
    stateReducer: (_, actionAndChanges) => {
      const { type, changes } = actionAndChanges;

      if (type === ItemClick && changes.selectedItem) {
        onSelectedItemChange(changes.selectedItem);

        return {
          ...changes,
          isOpen: false,
        };
      }

      // Close menu when input is empty
      if (!changes.inputValue) {
        return {
          ...changes,
          isOpen: false,
        };
      }

      // No special state handling for desktop
      if (!mobile) {
        return changes;
      }

      // In mobile make sure to keep the results showing
      // after you close the virtual keyboard.
      // Closing it triggers a blur event.
      if (type === InputBlur) {
        return {
          ...changes,
          inputValue: _inputValue,
          isOpen: true,
        };
      }

      return changes;
    },
  });

  const onRoomsMenuClose = useEvent(() => {
    _setInputValue(_inputValue);
    openMenu();
  });

  const inputProps = getInputProps();
  const menuProps = getMenuProps();

  const menuOpen = isOpen && items.length > 0;
  const shouldRenderClear = Boolean(_inputValue.trim() && mobile);

  return (
    <S.GeocodingSearch className={className}>
      <S.ComboBox css={comboboxCSS}>
        <S.LeftIconArea>
          <S.LeftIcon $loading={searchState.loading} />
        </S.LeftIconArea>
        <S.Input
          {...inputProps}
          css={inputCSS}
          includeClear={shouldRenderClear}
          menuOpen={menuOpen}
          placeholder={placeholder}
        />

        {shouldRenderClear && (
          <S.Clear onClick={reset}>
            <S.ClearIcon />
          </S.Clear>
        )}
      </S.ComboBox>
      <S.Menu {...menuProps} $open={menuOpen} css={menuCSS}>
        {menuOpen &&
          items.map((item, index) => (
            <MenuItem
              key={item.addressString}
              index={index}
              item={item}
              menuItemCSS={menuItemCSS}
              hoveredRowIndex={hoveredRowIndex}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              inputValue={inputValue}
              closeMenu={closeMenu}
              onRoomsMenuClose={onRoomsMenuClose}
            />
          ))}
      </S.Menu>
    </S.GeocodingSearch>
  );
};

const MenuItem = memo(
  ({
    item,
    index,
    menuItemCSS,
    hoveredRowIndex,
    getItemProps,
    highlightedIndex,
    onMouseEnter,
    onMouseLeave,
    inputValue,
    closeMenu,
    onRoomsMenuClose = () => null,
  }) => {
    const mobile = useMediaQuery({ max: 'large' });

    const [open, setOpen] = useState(false);

    const openModal = useEvent(() => setOpen(true));
    const closeModal = useEvent(() => {
      setOpen(false);
      onRoomsMenuClose();
    });

    const { updateFilters } = useFilterContext();

    const handleSeeAllClick = useEvent((space, isOffice = false) => {
      updateFilters({
        isOffice,
        bookingType: 'hourly',
        query: space.name,
        place: {
          lat: space.lat,
          lng: space.lng,
          name: space.name,
        },
      });

      closeMenu();
    });

    const highlightInputString = useCallback(
      (addressString) => {
        return reactStringReplace(
          addressString,
          new RegExp(`(${inputValue.trim()})`, 'i'),
          (match, idx) => <S.Highlighted key={idx}>{match}</S.Highlighted>,
        );
      },
      [inputValue],
    );

    const itemProps = getItemProps({ item, index });

    const {
      addressString,
      deskpassType,
      spaceCount,
      name,
      rooms = [],
      roomsOnly,
    } = item;

    let spaceMetaInfoList = [];
    let spaceRowExtraProps = {};

    const meet = rooms.filter((it) => !it.isOffice);
    const office = rooms.filter((it) => it.isOffice);
    const willRenderRoomsMenu = rooms.length > 0 && !mobile;

    const [style, setStyle] = useState({
      top: '0',
    });

    const [roomsMenuOverlayRef] = useInView({
      skip: !willRenderRoomsMenu,
      threshold: 1,
      onChange: (inView, entry) => {
        if (willRenderRoomsMenu && !inView) {
          const { boundingClientRect, rootBounds, intersectionRect } =
            entry ?? {};

          let overlayHeight = boundingClientRect?.height ?? 0;
          const intersectionHeight = intersectionRect?.height ?? 0;

          const tallerThanViewport = overlayHeight > rootBounds.height;
          const vh = rootBounds.height;

          if (tallerThanViewport) {
            overlayHeight = vh - 120;
          }

          const top = `-${overlayHeight - intersectionHeight + 10}px`;
          const overflowY = tallerThanViewport ? 'auto' : 'hidden';
          let height = overlayHeight;

          setStyle({ top, height, overflowY });
        }
      },
    });

    if (deskpassType === 'space') {
      if (!roomsOnly) spaceMetaInfoList.push('Desks');
      if (meet.length) spaceMetaInfoList.push(`${meet.length} Meeting Rooms`);
      if (office.length)
        spaceMetaInfoList.push(`${office.length} Office Spaces`);

      spaceMetaInfoList = spaceMetaInfoList.join(' / ').split(' ');

      if (meet.length || office.length) {
        spaceRowExtraProps.onMouseEnter = () => onMouseEnter(index);
        spaceRowExtraProps.onMouseLeave = onMouseLeave;
      }
    }

    return (
      <S.MenuItem
        css={menuItemCSS}
        {...itemProps}
        {...spaceRowExtraProps}
        $highlighted={index === highlightedIndex}
        key={addressString}
      >
        {deskpassType === 'space' && <S.SpaceIcon />}
        {deskpassType === 'city' && <S.CityIcon />}

        {deskpassType === 'space' && (
          <S.MenuItemSpace $withRooms={!!rooms.length}>
            <S.MenuItemContent>
              <S.ItemInfoSpace>{highlightInputString(name)} </S.ItemInfoSpace>
              <S.ItemInfo>{highlightInputString(addressString)}</S.ItemInfo>
            </S.MenuItemContent>
            {!!spaceMetaInfoList.length && (
              <S.MenuItemSpaceMetaInfo>
                {spaceMetaInfoList.map((it, idx) => {
                  const key = `${it}-${idx}`;
                  return <span key={key}>{it}</span>;
                })}
              </S.MenuItemSpaceMetaInfo>
            )}
          </S.MenuItemSpace>
        )}

        {deskpassType === 'city' && (
          <S.MenuItemContent>
            <S.ItemInfoCity>
              {highlightInputString(addressString)}{' '}
            </S.ItemInfoCity>
            <S.ItemInfoCityCount>{spaceCount} spaces</S.ItemInfoCityCount>
          </S.MenuItemContent>
        )}

        {deskpassType !== 'city' && deskpassType !== 'space' && (
          <S.MenuItemContent>
            <S.ItemInfo>{highlightInputString(addressString)}</S.ItemInfo>
          </S.MenuItemContent>
        )}

        {deskpassType === 'space' && willRenderRoomsMenu && <S.CaratRight />}

        {deskpassType === 'space' && mobile && !!rooms.length && (
          <S.RoomsMenuButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              openModal();
            }}
          >
            Details
          </S.RoomsMenuButton>
        )}

        {mobile && (
          <Modal
            no-padding
            flex-content
            type="full"
            label="placeSearchModal"
            show={open}
            renderNav={() => (
              <NavBar
                responsive
                title="Room Options"
                onBackClick={(e) => {
                  e.stopPropagation();
                  closeModal();
                }}
              />
            )}
          >
            <RoomsMenu
              item={item}
              handleSeeAllClick={handleSeeAllClick}
              meet={meet}
              office={office}
            />
          </Modal>
        )}

        {!mobile && hoveredRowIndex === index && (
          <S.RoomsMenuOverlay
            onClick={(e) => e.stopPropagation()}
            ref={roomsMenuOverlayRef}
            style={style}
          >
            <RoomsMenu
              item={item}
              handleSeeAllClick={handleSeeAllClick}
              meet={meet}
              office={office}
            />
          </S.RoomsMenuOverlay>
        )}
      </S.MenuItem>
    );
  },
);

const RoomsMenu = memo(
  ({ item, handleSeeAllClick, meet = [], office = [] }) => {
    return (
      <S.RoomsMenu>
        <LocationCard
          href={route('spaceDetail', { spaceSlug: item.slug })}
          image={item.image}
          title={<>All Day Deskspace for 1 person</>}
          subtitle={
            <>{formatCurrency(item.bookingCost, item.currencySymbol)} per day</>
          }
        />

        {meet.length > 0 && (
          <>
            <SeparatorLabel>
              <S.MeetingRoomIcon /> {meet.length} Meeting Rooms
            </SeparatorLabel>

            {meet.slice(0, 2).map((room) => (
              <LocationCard
                key={room.id}
                image={room.images[0]?.url}
                href={route('roomDetail', {
                  spaceSlug: item.slug,
                  roomSlug: room.slug,
                })}
                title={
                  <>
                    {room.name} - fits {room.maxOccupancy}{' '}
                    {room.maxOccupancy === 1 ? 'person' : 'people'}
                  </>
                }
                subtitle={
                  <>
                    Starting at{' '}
                    {formatCurrency(room.hourlyRate, item.currencySymbol)} per
                    hr
                    {room.dayRate
                      ? ` / ${formatCurrency(
                          room.dayRate,
                          item.currencySymbol,
                        )} per day`
                      : ''}
                  </>
                }
              />
            ))}

            {meet.length > 2 && (
              <S.SeeAllLink onClick={() => handleSeeAllClick(item)}>
                See all Meeting Rooms
              </S.SeeAllLink>
            )}
          </>
        )}

        {office.length > 0 && (
          <>
            <SeparatorLabel>
              <S.OfficeIcon /> {office.length} Office
            </SeparatorLabel>

            {office.slice(0, 2).map((room) => (
              <LocationCard
                key={room.id}
                image={room.images[0]?.url}
                imageAlt={room.name}
                href={route('roomDetail', {
                  spaceSlug: item.slug,
                  roomSlug: room.slug,
                })}
                title={
                  <>
                    {room.name} - fits {room.maxOccupancy}{' '}
                    {room.maxOccupancy === 1 ? 'person' : 'people'}
                  </>
                }
                subtitle={
                  <>
                    Starting at{' '}
                    {room.officeDayRate
                      ? `${formatCurrency(
                          room.officeDayRate,
                          item.currencySymbol,
                        )} per day`
                      : ''}
                    {room.weekRate
                      ? ` / ${formatCurrency(
                          room.weekRate,
                          item.currencySymbol,
                        )} per week`
                      : ''}
                    {room.monthRate
                      ? ` / ${formatCurrency(
                          room.monthRate,
                          item.currencySymbol,
                        )} per month`
                      : ''}
                  </>
                }
              />
            ))}

            {office.length > 2 && (
              <S.SeeAllLink onClick={() => handleSeeAllClick(item, true)}>
                See all offices
              </S.SeeAllLink>
            )}
          </>
        )}

        <S.GoToSpaceBox>
          <S.SpaceIcon />
          <S.SeeSpaceLink
            to={
              route('spaceDetail', { spaceSlug: item.slug }) + '#room-options'
            }
          >
            See all Space options at {item.name}
          </S.SeeSpaceLink>
        </S.GoToSpaceBox>
      </S.RoomsMenu>
    );
  },
);

const LocationCard = memo(({ href, image, imageAlt, title, subtitle }) => {
  return (
    <S.LocationCard to={href}>
      <RoomOrSpaceImage src={image} alt={imageAlt} transform="space" />
      <S.LocationCardContent>
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </S.LocationCardContent>
    </S.LocationCard>
  );
});

GeocodingSearch.propTypes = {
  initialInputValue: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  inputCSS: cssPropType,
  menuCSS: cssPropType,
  menuItemCSS: cssPropType,
  comboboxCSS: cssPropType,
  onSelectedItemChange: PropTypes.func,
  proximity: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default memo(GeocodingSearch);
