import { Helmet } from 'react-helmet';

import GenericError from '@/components/error-handling/GenericError';

const Forbidden = () => {
  const message = (
    <span>
      There was a problem loading the page. <br />
      The Deskpass team has been notified and we're looking into it.
    </span>
  );

  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="403" />
        <title>Forbidden</title>
      </Helmet>

      <GenericError
        title="We can't load that."
        message={message}
        btnProps={{ arrow: false }}
        btnText="Find Spaces Near You"
      />
    </>
  );
};

export default Forbidden;
