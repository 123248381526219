import React, { Component, createContext } from 'react';

import { withNotificationContext } from '@/context/Notification';

import { consumerToHOC } from '@/lib/hoc';
import { pathMatchRouteName } from '@/lib/routes';

const Context = createContext({});

/*
 * Watches for user geolocation changes
 */
class Provider extends Component {
  state = {
    // Set to true the first time the success or error callback is fired
    ready: false,
    // User denied to share geolocation
    userDeclined: false,
    // Geolocation available
    available: false,
    // Keep the last error around to see if it changed
    lastError: null,
    // Geolocation coordinates, lat and lng
    coords: {},
  };

  constructor(props) {
    super(props);

    // TODO at some point we might move it outside the bundle
    // Load it the sooner the better.
    this.watchUserLocation();
  }

  render() {
    return (
      <Context.Provider value={{ ...this.state }}>
        {this.props.children}
      </Context.Provider>
    );
  }

  // TODO We should probably add a retry policy here.
  watchUserLocation = () => {
    const matchCurrentPath = (it) =>
      pathMatchRouteName(window.location.pathname, it);

    // Routes we don't want to load user location
    const notInRoutes = ['guestInvitationIndex'].some(matchCurrentPath);

    // Abort clauses
    if (notInRoutes || !window.navigator || !window.navigator.geolocation) {
      return;
    }

    // Watch for updates to user position
    window.navigator.geolocation.watchPosition(this.onSuccess, this.onError, {
      timeout: 8000,
      // Cache geolocation position for a full day
      maximumAge: 1000 * 60 * 60 * 24,
    });
  };

  onSuccess = (position) => {
    let newState = {
      available: true,
      coords: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      },
    };

    if (!this.state.ready) {
      newState.ready = true;
    }

    this.setState(newState);
  };

  onError = (err) => {
    const { lastError, ready } = this.state;

    // Only display an error again if we're dealing with a new one
    if (!lastError || err?.code !== lastError.code) {
      const { notificationContext } = this.props;
      let newState = { available: false };

      if (err) {
        newState.lastError = err;
      }

      if (!ready) {
        newState.ready = true;
      }

      // User denied
      if (err.code === 1) {
        newState.userDeclined = true;
      }

      // Position unavailable
      if (err.code === 2) {
        notificationContext.create(
          'Your location is currently unavailable.',
          'warning',
        );
      }

      // Timeout
      if (err.code === 3) {
        notificationContext.create(
          'We’re having trouble getting your location.',
          'warning',
        );
      }

      this.setState(newState);

      // Something else went wrong
      console.error('Error geolocating user: ', err.code, err.message);
    }
  };

  // In case it started in a page where it doesn't request the location
  // We need the means to tell it to try again.
  retryWatchingUserLocation = () => {
    const { available, userDeclined } = this.state.state;

    // We don't want to keep prompting the user if
    // the location is available or declined
    if (available || userDeclined) return;

    this.watchUserLocation();
  };
}

export const useGeolocationContext = () => React.useContext(Context);
export const GeolocationProvider = withNotificationContext(Provider);
export const withGeolocationContext = consumerToHOC(
  Context.Consumer,
  'geolocationContext',
);
export default Context;
