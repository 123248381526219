import React from 'react';

import NoficationContext from '@/context/Notification';

import { AppLevelErrorTypes } from '@/lib/errors';
import { consumerToHOC } from '@/lib/hoc';

const Context = React.createContext();
const { Consumer, Provider } = Context;

export let setAppError = () => null;
export let createErrorNotification = () => null;

export const ErrorProvider = ({ children }) => {
  const { create: createNotifcation } = React.useContext(NoficationContext);
  const [state, setState] = React.useState(() => null);

  const _setAppError = React.useCallback((cb = () => null) => {
    const errorType = cb(AppLevelErrorTypes);

    if (AppLevelErrorTypes[errorType]) {
      setState(errorType);
    } else {
      console.error('Attempt to set invalid appLevel errorType: ', errorType);
    }
  }, []);

  const reset = React.useCallback(() => {
    setState(null);
  }, []);

  const _createErrorNotification = React.useCallback(
    (msg) => {
      return createNotifcation(msg, 'warning');
    },
    [createNotifcation],
  );

  // Mutate setAppError so it can be used
  // outside of the react life cycle.
  React.useEffect(() => {
    setAppError = _setAppError;
    createErrorNotification = _createErrorNotification;
  }, [_setAppError, _createErrorNotification]);

  const context = React.useMemo(
    () => ({
      appError: state,
      setAppError: _setAppError,
      createErrorNotification: _createErrorNotification,
      reset,
    }),
    [state, _setAppError, _createErrorNotification, reset],
  );

  return <Provider value={context}>{children}</Provider>;
};

export const ErrorConsumer = Consumer;
export const withErrorContext = consumerToHOC(Consumer, 'errorContext');
export const useErrorContext = () => React.useContext(Context);

export default Context;
