import styled, { css } from 'styled-components/macro';

import IconComp from '@/components/graphics/Icon';

import typeStyle from '@/styles/js/fonts/typeStyles';

import radioSelectedSvg from '@/images/sprites/full/radio-selected.svg';
import radioUnselectedSvg from '@/images/sprites/full/radio-unselected.svg';
import checkboxSelectedSvg from '@/images/sprites/full/checkbox-selected.svg';
import checkboxUnselectedSvg from '@/images/sprites/full/checkbox-unselected.svg';

const backgroundImage = ({ type, selected }) => {
  let image = {
    checkbox: checkboxUnselectedSvg,
    radio: radioUnselectedSvg,
  }[type];

  if (selected) {
    image = {
      checkbox: checkboxSelectedSvg,
      radio: radioSelectedSvg,
    }[type];
  }

  return css`
    background-image: url('${image}');
  `;
};

export const InvisibleInput = styled.div.attrs({
  tabIndex: -1,
  readOnly: true,
})`
  opacity: 0;
  position: absolute;
`;

export const SelectableArea = styled.div.attrs({
  tabIndex: 0,
})`
  height: 2rem;
  width: 2rem;
  position: absolute;
  left: 0;
  top: 0.2rem;

  ${backgroundImage};
`;

export const Label = styled.label`
  ${({ smallFont }) => smallFont ? css`
    ${typeStyle('body')}
  `: css`
    ${typeStyle('body', 'medium')}
  `}

  color: ${({ theme }) => theme.colors.accent};
  padding-left: 3.4rem;
  display: block;
  cursor: pointer;
 
  > a {
    text-decoration: underline;
  }
`;

export const FormSelectableItem = styled.div`
  position: relative;
  text-align: left;

  ${({ theme, row, rowNoPadTop, rowNoPadBottom, withImage }) => row && css`
    border-bottom: 1px solid ${theme.colors.rule};
    color: ${theme.colors.accent};

    ${rowNoPadBottom && css`
      border-bottom: none;
    `}

    ${SelectableArea} {
      left: auto;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;

      ${rowNoPadTop && css`
        bottom: 1.5rem;
      `}

      ${rowNoPadBottom && css`
        top: 1.4rem;
      `}
    }

    ${Label} {
      padding: 1.5rem 0;
      position: relative;

      ${rowNoPadTop && css`
        padding-top: 0;
      `}

      ${rowNoPadBottom && css`
        padding-bottom: 0;
      `}

      ${withImage && css`
        padding-left: 4rem;
      `}
    }
  `}
`;

const commonStyles = css`
  position: absolute;
  left: 0;
  top: 0.2rem;
  bottom: 0;
  margin: auto 0;

  ${({ rowNoPadTop, rowNoPadBottom }) => css`
    ${rowNoPadTop && css`
      top: -1.3rem;
    `}
    ${rowNoPadBottom && css`
      top: 1.7rem;
    `}
  `}
`;

export const Img = styled.img`
  ${commonStyles}
`;

export const Icon = styled(IconComp)`
  ${commonStyles}
`;
