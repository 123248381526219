import React from 'react';

import Drawer from '@/components/bits/Drawer';
import MoreView from '@/components/views/MoreView';

import MoreModalContext from '@/context/MoreModal';

const MoreModal = () => {
  const { openMore, toggleMoreModal } = React.useContext(MoreModalContext);

  return (
    <Drawer open={openMore} onClose={toggleMoreModal}>
      <MoreView />
    </Drawer>
  );
};

export default MoreModal;
