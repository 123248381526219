/**
 * Return a string with the first letter capitalized
 *
 * @param    {string}  originalString  The original string
 * @return   {string}  String with the first letter capitalized
 */
export const capitalize = (originalString) => {
  if (!originalString) {
    return '';
  }

  return (
    originalString.charAt(0).toUpperCase() +
    originalString.slice(1).toLowerCase()
  );
};

/**
 * Strip trailing slash from a string (probably a URL). Ensures you don't get
 * messy double slashes at the end of URLs.
 *
 * @param {String} string String to strip trailing slash from
 *
 * @return {String} Returns passed string with trailing slash removed (if
 * applicable)
 */
export const stripTrailingSlash = (string) => {
  string = string || '';

  return string.substr(string.length - 1) === '/'
    ? string.substr(0, string.length - 1)
    : string;
};

/**
 * Convert slug to capitalized display text.
 *
 * @param {String} slug Slug to convert, i.e. 'word-like-this'
 *
 * @return {String} Returns formatted string, i.e. 'Word Like This'
 */
export const slugToDisplay = (slug) => {
  return slug
    .split('-')
    .map((word) => capitalize(word))
    .join(' ');
};

/**
 * Slugify passed value.
 * Modified from: https://gist.github.com/mathewbyrne/1280286
 *
 * @param {String}  text Text input
 * @param {Boolean} trim If true, trim ends of passed text
 *
 * @return {String} Returns slugifed input
 */
export function slugify(text, trim) {
  // In the event passed input is empty, just return empty string
  if (!text) {
    return '';
  }

  let newValue = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-'); // Replace multiple - with single -

  // Trim text if requested
  if (trim) {
    newValue = newValue
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  // And send back the converted value
  return newValue;
}

/**
 * Slugify a camel case name.
 *
 * @param {String} name Name to slugify
 *
 * @return {String} Returns slugified name
 */
export function slugifyCamelcase(name) {
  const raw = name
    .split('')
    .map((it) => (it === it.toUpperCase() ? ' ' + it : it))
    .join('')
    .toLowerCase();

  // Run through normal slugify function just to deal with anything else
  return slugify(raw);
}

/**
 * Slugify an email.
 *
 * @param {String} email Email to slugify
 *
 * @return {String} Returns slugified email
 */
export function slugifyEmail(email) {
  const split = email.split('@');
  const domain = split[1].split('.');

  // Do a bit of special formatting so you can still tell it's an email
  const raw = split[0] + '-at-' + domain.join('-dot-');

  // Run through normal slugify function just to deal with anything else
  return slugify(raw);
}
