import { useRef } from 'react';

import * as S from './SeparatorLabel.styles';

export function SeparatorLabel({ children }) {
  const textRef = useRef();
  let height = (textRef.current?.offsetHeight ?? 17) * 2;

  return (
    <S.Box style={{ height }}>
      <S.Separator>
        <S.Label ref={textRef}>{children}</S.Label>
      </S.Separator>
    </S.Box>
  );
}
