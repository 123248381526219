import styled, { css } from 'styled-components/macro';
import { TransitionGroup } from 'react-transition-group';

import { Notification } from '@/components/notifications/Notification/index.styles';

import units from '@/styles/js/units';

export const NotificationTransitionGroup = styled(TransitionGroup)`
  // Only shows the first
  ${({ $persistent }) => $persistent && css`
    z-index: ${units.zIndex.persistentNotifications};

    & > ${Notification} {
      display: none;

      &:first-child {
        display: block;
      }
    }
  `}
  ${({ $persistent }) => !$persistent && css`
    z-index: ${units.zIndex.notifications};
  `}
`;
