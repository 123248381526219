import queryString from 'query-string';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const RedirectWithSearch = ({ to, ...props }) => {
  const { search } = useLocation();

  const toIsString = typeof to === 'string';
  const pathname = toIsString ? to : to.pathname;
  const toSearch = to?.search;

  const toProps = React.useMemo(() => {
    if (pathname) {
      return {
        pathname,
        search,
      };
    }

    const parsedSearch = queryString.parse(search);
    const parsedNewSearch = queryString.parse(toSearch);

    const nextSearch = queryString.stringify({
      ...parsedSearch,
      ...parsedNewSearch,
    });

    return {
      pathname,
      search: nextSearch,
    };
  }, [search, toSearch, pathname]);

  return (
    <Redirect
      {...props}
      to={{
        ...(!toIsString && (to ?? {})),
        ...toProps,
      }}
    />
  );
};

export default RedirectWithSearch;
