import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import CompanyLogo from '@/components/brand/CompanyLogo';
import Icon from '@/components/graphics/Icon';
import Mq from '@/components/helpers/Mq';
import NavSmall from '@/components/nav/NavSmall';
import Avatar from '@/components/users/Avatar';

import MoreModalContext from '@/context/MoreModal';
import UserContext from '@/context/User';

import route from '@/lib/routes';

import * as S from './MoreView.styles';

/**
 * Mobile-only view display additional navigation options.
 */
const MoreView = () => {
  const { user, authenticated } = useContext(UserContext);
  const { openMore, toggleMoreModal } = React.useContext(MoreModalContext);

  return (
    <S.MoreView isOpen={openMore}>
      <Mq min="large">
        <Redirect to={route('spaceIndex')} />
      </Mq>

      <S.MoreViewHeader>
        {authenticated ? (
          <S.MoreViewLogout to={route('logout')}>
            <S.MoreViewAvatar>
              <Avatar imageUrl={user.profileImageURLDynamic || ''} />
            </S.MoreViewAvatar>
            <span>Log Out</span>
          </S.MoreViewLogout>
        ) : (
          <S.MoreViewNavItemLink to={route('login')}>
            <S.MoreViewAvatar>
              <CompanyLogo simplified white width={24} height={24} />
            </S.MoreViewAvatar>
          </S.MoreViewNavItemLink>
        )}
        <S.CloseButton onClick={toggleMoreModal}>
          <Icon type="close-x-24" />
        </S.CloseButton>
      </S.MoreViewHeader>

      <S.MoreViewNav>
        {!authenticated && (
          <>
            <S.SignInWrapper>
              <S.MoreViewNavItemLink to={route('login')}>
                Log In
              </S.MoreViewNavItemLink>
              <S.MoreViewNavItemLink to={route('signup')}>
                Sign Up
              </S.MoreViewNavItemLink>
            </S.SignInWrapper>
            <S.MoreViewNavItemLink to={route('benefits')}>
              Benefits & Pricing
            </S.MoreViewNavItemLink>
          </>
        )}
        <S.MoreViewNavItem
          href={route('about')}
          rel="noopener noreferrer"
          target="_blank"
        >
          About
        </S.MoreViewNavItem>
        <S.MoreViewNavItem
          href={route('blog')}
          rel="noopener noreferrer"
          target="_blank"
        >
          Blog
        </S.MoreViewNavItem>
        <S.MoreViewNavItem
          href={route('tos')}
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms of Service
        </S.MoreViewNavItem>
        <S.MoreViewNavItem
          href={route('privacyPolicy')}
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy
        </S.MoreViewNavItem>
        <S.MoreViewNavItemLink to={route('rules')}>
          Things to Know
        </S.MoreViewNavItemLink>
      </S.MoreViewNav>

      {authenticated && <NavSmall reverse />}
    </S.MoreView>
  );
};

export default MoreView;
