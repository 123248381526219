// And save to indexedDB/localStorage.  localForage will prefer
// indexedDB over localStorage, which is good because iOS has this thing
// where it will blam localStorage data if it starts to run out of
// memory, which can lead to lost storage items
import localForage from 'localforage';

/* Storage constants */
export const INCOMING_BREATHER_USER = 'incoming-breather-user';

/* Storage Actions */

// INCOMING_BREATHER_USER

export const clearIsBreatherUser = _createRemover(INCOMING_BREATHER_USER);
export const getIsBreatherUser = _createGetter(INCOMING_BREATHER_USER);
export const setIsBreatherUser = _createSetter(
  INCOMING_BREATHER_USER,
  () => true,
);

export async function clearAll() {
  try {
    await localForage.clear();
    console.debug('Removing all data from browser storage');
  } catch (err) {
    console.error('Failed to remove all data from browser storage');
    throw err;
  }
}

/* Internal */

function _createSetter(key, transform = (it) => it) {
  return async (param = {}) => {
    // Chance to convert the set value to what is desired if needed
    const value = transform(param);

    try {
      await localForage.setItem(key, value);
      console.debug(`Setting "${key}" on browser storage`);
    } catch (err) {
      console.error(`Failed to set "${key}" on browser storage`);
      throw err;
    }
  };
}

function _createGetter(key) {
  return async () => {
    try {
      const value = await localForage.getItem(key);
      console.debug(`Retrieving "${key}" from browser storage`);
      return value;
    } catch (err) {
      console.error(`Failed to retrieve "${key}" from browser storage`);
      throw err;
    }
  };
}

function _createRemover(key) {
  return async () => {
    try {
      await localForage.removeItem(key);
      console.debug(`Remove "${key}" from browser storage`);
    } catch (err) {
      console.error(`Failed to remove "${key}" from browser storage`);
      throw err;
    }
  };
}
