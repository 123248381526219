import React, { Component, createContext } from 'react';

import api from '@/api/deskpass';

import { consumerToHOC } from '@/lib/hoc';

const Context = createContext({});

export const initialState = {
  ready: false,
  amenities: [],
  moods: [],
  transit: [],
  roomAmenities: [],
  healthAndSafetyTags: [],
  allAmenitiesBySlug: {},
  allRoomAmenitiesBySlug: {},
};

class Provider extends Component {
  state = initialState;

  componentDidMount() {
    this.loadAmenities().finally(() => {
      this.setState({ ready: true });
    });
  }

  render() {
    // Everything in here will be expose to the context consumer
    const context = {
      ...this.state,
      slugToName: this.slugToName,
      roomSlugToName: this.roomSlugToName,
    };

    return (
      <Context.Provider value={context}>{this.props.children}</Context.Provider>
    );
  }

  /**
   * Convert passed amenity slug to display name
   */
  slugToName = (slug) => {
    return (this.state.allAmenitiesBySlug[slug] || {}).name;
  };

  roomSlugToName = (slug) => {
    return (this.state.allRoomAmenitiesBySlug[slug] || {}).name;
  };

  loadAmenities = () => {
    return Promise.all([
      api.space.amenities.getAll(),
      api.room.amenities.getAll(),
      api.global.healthAndSafetyTags.getAll(),
    ]).then(([amenities, roomAmenities, healthAndSafetyTags]) => {
      const {
        other,
        moods,
        transit,
        allAmenitiesBySlug,
        allRoomAmenitiesBySlug,
      } = this.groupAmenitiesByType(amenities, roomAmenities);

      const newState = {
        allRoomAmenitiesBySlug,
        allAmenitiesBySlug,
        roomAmenities,
        healthAndSafetyTags,
        moods,
        transit,
        amenities: other,
      };

      return new Promise((resolve) => {
        this.setState(newState, () => {
          resolve(newState);
        });
      });
    });
  };

  groupAmenitiesByType = (amenities, roomAmenities) => {
    let moods = [];
    let transit = [];
    let other = [];
    let allAmenitiesBySlug = {};
    let allRoomAmenitiesBySlug = {};

    for (let amenity of roomAmenities) {
      // Add to list of all amenities
      allRoomAmenitiesBySlug[amenity.slug] = amenity;
    }

    for (let amenity of amenities) {
      // Add to list of all amenities
      allAmenitiesBySlug[amenity.slug] = amenity;

      // Then sort by mood or other
      if (amenity.type === 'mood') {
        moods.push(amenity);
      } else if (amenity.type === 'transit') {
        transit.push(amenity);
      } else {
        other.push(amenity);
      }
    }

    return {
      other,
      moods,
      transit,
      allAmenitiesBySlug,
      allRoomAmenitiesBySlug,
    };
  };
}

export default Context;
export const useAmenityContext = () => React.useContext(Context);
export const AmenityProvider = Provider;
export const withAmenityContext = consumerToHOC(
  Context.Consumer,
  'amenityContext',
);
