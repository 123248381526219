/**
 * Notification to display if user has an expired credit card, has a card about
 * to expire or is missing one completely.
 */
import moment from 'moment-timezone';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Mq from '@/components/helpers/Mq';
import Notification from '@/components/notifications/Notification';

import UserContext from '@/context/User';

import route, { pathMatchRouteName } from '@/lib/routes';

const CreditCardExpiredNotification = ({ onIconClick }) => {
  const { user, teamUser } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  const { currentPaymentMethod } = user;

  if (!user.id || teamUser) {
    return null;
  }

  const cardExpiration = currentPaymentMethod
    ? moment(
        `${currentPaymentMethod.exp_month}/${currentPaymentMethod.exp_year}`,
        'MM/YYYY',
      ).endOf('month')
    : null;
  const now = moment();
  const warningThreshold = moment.duration(1, 'month');

  const noCard = !currentPaymentMethod;
  const cardExpired = now.isAfter(cardExpiration);
  const willExpireSoon =
    !!cardExpiration &&
    cardExpiration.isAfter(now) &&
    now.isAfter(moment(cardExpiration).subtract(warningThreshold));

  const inExcludedRoute = [
    'teamSignup',
    'teamSignupProfile',
    'signupRules',
    'signupDone',
    'signup',
  ].some((it) => pathMatchRouteName(location.pathname, it));

  if ((!noCard && !cardExpired && !willExpireSoon) || inExcludedRoute) {
    return null;
  }

  return (
    <Mq min="large">
      <Notification
        warning
        icon="arrow"
        onIconClick={onIconClick}
        onClick={() => {
          history.push(route('accountCreditCardEdit'));
        }}
      >
        {noCard &&
          'Your account currently has no credit card on file. Please enter a credit card to begin creating reservations on Deskpass.'}
        {cardExpired &&
          'Your credit card has expired. Please enter a new card to continue making reservations.'}
        {willExpireSoon &&
          'Your credit card will expire soon. Please enter a new card to continue making reservations.'}
      </Notification>
    </Mq>
  );
};

export default CreditCardExpiredNotification;
