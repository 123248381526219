import React, { useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import localServerApi from '@/api/localServer';

import { useIsIncomingBreatherUser } from '@/components/breather/hooks';
import Button from '@/components/buttons-links/Button';
import TextLink from '@/components/buttons-links/TextLink';
import FormGroup from '@/components/forms/FormGroup';
import FormText from '@/components/forms/FormText';
import Text from '@/components/text-headings/Text';

import { useAnaliticsContext } from '@/context/Analytics';
import { useHubspotContext } from '@/context/Hubspot';
import { useNotificationContext } from '@/context/Notification';
import { useUserContext } from '@/context/User';

import useEvent from '@/hooks/useEvent';
import useStateMergeUpdater from '@/hooks/useStateMergeUpdater';

import breatherLogo from '@/images/png/breather-logo.png';

import route from '@/lib/routes';
import { isValidEmail } from '@/lib/validation';

import * as S from './index.styles';

export default function LoginForm({
  className,
  onLogin = () => null,
  onLoginError = () => null,
}) {
  const { state } = useLocation();

  const { authenticating, login } = useUserContext();
  const { create: createNotification } = useNotificationContext();
  const { trackUserLoginLinkedInConversion } = useAnaliticsContext();

  const isIncomingBreatherUser = useIsIncomingBreatherUser();

  const [formState, setFormState] = React.useState(() => ({
    email: state?.email ?? '',
    password: '',
    csrfToken: null,
    emailValid: false,
    passwordValid: false,
    dirty: false,
  }));

  const { email, password, emailValid, csrfToken, passwordValid, dirty } =
    formState;

  const updateFormState = useStateMergeUpdater(setFormState);

  const onEmailChange = useEvent((evt) => {
    // Make sure email is always lowercase
    const email = evt.target.value.toLowerCase().replace(/\s/g, '');

    updateFormState({ email, emailValid: !!email && isValidEmail(email) });
  });

  const onPasswordChange = useEvent((evt) => {
    const { value } = evt.target;

    updateFormState({
      password: value,
      passwordValid: Boolean(value),
    });
  });

  const isFormValid = useEvent(
    () => !!email && !!password && isValidEmail(email),
  );

  const onSubmit = useEvent(async (evt) => {
    evt.preventDefault();

    updateFormState({ dirty: true });

    if (!isFormValid()) {
      return;
    }

    try {
      await login(email, password, csrfToken);
      trackUserLoginLinkedInConversion();
      onLogin();
    } catch (err) {
      createNotification(err.displayError, 'warning');
      onLoginError(err);
    }
  });

  const loadCSRF = useCallback(async () => {
    try {
      const { result } = await localServerApi.auth.retrieveDPCSRFToken();
      updateFormState({ csrfToken: result });
    } catch (err) {
      console.error('Unable to load CSRF token.', err);
    }
  }, [updateFormState]);

  useEffect(() => {
    if (csrfToken) return;
    loadCSRF();
  }, [csrfToken, loadCSRF]);

  return (
    <S.Form onSubmit={onSubmit} className={className}>
      {isIncomingBreatherUser && <IncomingBreatherUsersMessage />}

      <FormGroup
        name="email"
        label="Email"
        errorMessage={dirty && !emailValid ? 'Please enter a valid email.' : ''}
      >
        <FormText
          name="email"
          placeholder="Enter your email address"
          value={email}
          onChange={onEmailChange}
        />
      </FormGroup>

      <FormGroup
        name="password"
        label="Password"
        errorMessage={
          dirty && !passwordValid ? 'Please enter a valid password.' : ''
        }
      >
        <FormText
          name="password"
          placeholder="Enter your password"
          type="password"
          value={password}
          onChange={onPasswordChange}
        />
      </FormGroup>

      <Button
        type="submit"
        widthSize="full"
        processing={authenticating}
        disabled={authenticating}
      >
        Log In
      </Button>

      <TextLink size="small" to={route('forgotPassword')}>
        Forgot password
      </TextLink>
    </S.Form>
  );
}

function IncomingBreatherUsersMessage() {
  const hubspotContext = useHubspotContext();

  const supportLink = (
    <TextLink underlined onClick={() => hubspotContext.openWidget()}>
      Support
    </TextLink>
  );

  return (
    <S.BreatherSection>
      <S.BreatherLogoWrapper>
        <S.BreatherLogo src={breatherLogo} alt="Breather" />
      </S.BreatherLogoWrapper>

      <S.BreatherContent>
        <Text>
          <p>
            Welcome Breather users! Log in below to import your account details
            including payment info. If you have a Breather account with a
            password, log in with your email and password. If you created your
            account with Google, please go{' '}
            <Link to={route('breatherSetPassword')}>here</Link> first to set a
            password, after which your account details will be imported. If
            you'd prefer to make a new account with Deskpass instead, go{' '}
            <Link to={route('signup')}>here</Link> to sign up. Please reach out
            to our {supportLink} if you have any issues, and we hope you have a
            great experience making reservations on Deskpass.
          </p>
        </Text>
      </S.BreatherContent>
    </S.BreatherSection>
  );
}
