import useEvent from '@/hooks/useEvent';

const useStateMergeUpdater = (setState) => {
  return useEvent((newStateOrCallback) => {
    if (typeof newStateOrCallback === 'function') {
      return setState(newStateOrCallback);
    }

    setState((prevState) => ({
      ...prevState,
      ...newStateOrCallback,
    }));
  });
};

export default useStateMergeUpdater;
