const breakpoints = {
  zero: 0,
  small: 360,
  smallMedium: 414,
  smallTablet: 739,
  medium: 768,
  large: 1024,
  xlarge: 1280,
  full: 1550,
  infinite: 50000,
  maxWidthCentered: 1040,
};

/**
 * Get the width (in px) of a specific breakpoint by name
 *
 * @param    {string}  name    Name of the breakpoint
 * @return   {number}  Breakpoint width in pixels
 */
const breakpoint = (name) => {
  return breakpoints[name];
};

/**
 * Get an object containing all breakpoints,
 * with keys of the breakpoint name,
 * and values of the breakpoint width in pixels
 *
 * @return   {object}  The breakpoints
 */
const allBreakpoints = () => {
  return breakpoints;
};

const isBreakpoint = (name) => {
  return !isNaN(breakpoint(name));
};

export { breakpoint, allBreakpoints, isBreakpoint };
