export const DATE_FILTER_FORMAT = 'YYYY-MM-DD';
export const URL_SEARCH_FILTER_SYNC = 'URL_SEARCH_FILTER_SYNC';

export const chicagoPlaceFallback = {
  lat: 41.8781,
  lng: -87.6298,
  name: 'Chicago',
};

// Filters defaults to reset to
export const DEFAULT_FILTER_STATE = {
  get place() {
    return chicagoPlaceFallback;
  },
  get amenities() {
    return [];
  },
  bookingType: 'deskpass',
  mood: '',
  capacity: '1',
  date: null,
  start: '',
  end: '',
  isFavorite: false,
  isOffice: false,
  query: '',
  instantBooking: false,
};
