import React from 'react';
import { useHistory } from 'react-router-dom';

import Forbidden from '@/components/error-handling/app-level/Forbidden';
import PageNotFound from '@/components/error-handling/app-level/PageNotFound';

import ErrorContext from '@/context/Error';

import { AppLevelErrorTypes } from '@/lib/errors';

const ERROR_VIEW_MAP = {
  [AppLevelErrorTypes.NotFound]: PageNotFound,
  [AppLevelErrorTypes.Forbidden]: Forbidden,
};

/*
 * Display App Leve errors when they occur.
 *
 * While these errors are displayed the app routes aren't registerd
 * therefore, whenever the URL changes after this container is mounted means there
 * was a navigation attempt, and so clean the error context when that happens.
 */
const ErrorContainer = () => {
  const { listen: routeChangeSubscriber } = useHistory();
  const { appError, reset } = React.useContext(ErrorContext);

  React.useEffect(() => {
    // Whenever the URL changes we reset the error context
    // so that the main routes can be rendered again
    const unsubscribe = routeChangeSubscriber(() => reset());

    return () => {
      if (unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [reset, routeChangeSubscriber]);

  // Reset the error context when navigating
  // outside of the app error routes.
  React.useEffect(
    () => () => {
      reset(); // On Dispose
    },
    [reset],
  );

  const ErrorView = ERROR_VIEW_MAP[appError];

  if (!ErrorView) return null;

  return <ErrorView />;
};

export default ErrorContainer;
