import { useCallback, useEffect } from 'react';

export const useHashScroller = () => {
  const scrollToElement = useCallback((behavior) => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior });
      } else {
        // Use MutationObserver to detect when the element is added to the DOM
        const observer = new MutationObserver(() => {
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior });
            observer.disconnect();
          }
        });
        observer.observe(document.body, { childList: true, subtree: true });
      }
    }
  }, []);

  useEffect(() => {
    const handleNavigation = () => {
      scrollToElement('smooth');
    };

    const handleReload = () => {
      scrollToElement('auto');
    };

    // Listen for pushState and replaceState
    const originalPushState = history.pushState;
    const originalReplaceState = history.replaceState;

    history.pushState = function (...args) {
      originalPushState.apply(history, args);
      handleNavigation();
    };

    history.replaceState = function (...args) {
      originalReplaceState.apply(history, args);
      handleNavigation();
    };

    window.addEventListener('popstate', handleNavigation);
    window.addEventListener('load', handleReload);

    // Clean up listeners on unmount
    return () => {
      history.pushState = originalPushState;
      history.replaceState = originalReplaceState;
      window.removeEventListener('popstate', handleNavigation);
      window.removeEventListener('load', handleReload);
    };
  }, [scrollToElement]);
};

export const HashScroller = () => {
  useHashScroller();
  return null;
};
