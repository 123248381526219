import GeocodingSearch from '@/components/forms/Geocoding/Search';

import useGeocodingFilter from './useGeocodingFilter';

const GeocodingFilterDesktop = () => {
  const { onSelect, geocodingParams, place } = useGeocodingFilter();

  return (
    <GeocodingSearch
      {...geocodingParams}
      initialInputValue={place?.name}
      onSelectedItemChange={onSelect}
    />
  );
};

export default GeocodingFilterDesktop;
