import PropTypes from 'prop-types';

import useEvent from '@/hooks/useEvent';

import * as S from './index.styles';

/*
 * Radio or Checkbox input
 */
const FormSelectableItem = ({
  id,
  name,
  children,
  type,
  value,
  icon,
  image,
  imageProps,
  row = false,
  selected = false,
  onClick = () => {},
  ...otherProps
}) => {
  const onKeyDown = useEvent((e) => {
    // pressing space, trigger click when field is on focus
    if (e.keyCode === 32) {
      onClick(e);
    }
  });

  return (
    <S.FormSelectableItem
      {...otherProps}
      withImage={!!(row && (icon || image))}
      row={row}
    >
      {/* Used to hold the actual value */}
      <S.InvisibleInput
        role="none"
        type={type}
        checked={selected}
        value={value}
      />

      {icon && row && <S.Icon {...otherProps} type={icon} />}

      {image && row && (
        <S.Img {...otherProps} alt="" {...imageProps} src={image} />
      )}

      <S.SelectableArea
        id={id}
        name={name}
        role={type}
        onClick={onClick}
        onKeyDown={onKeyDown}
        aria-checked={selected}
        selected={selected}
        type={type}
      />

      <S.Label onClick={onClick} htmlFor={id} {...otherProps}>
        {children}
      </S.Label>
    </S.FormSelectableItem>
  );
};

FormSelectableItem.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  image: PropTypes.string,
  imageProps: PropTypes.object,
  row: PropTypes.bool,
  rowNoPadTop: PropTypes.bool,
  rowNoPadBottom: PropTypes.bool,
};

export default FormSelectableItem;
