import React from 'react';

import FormSelectable from '@/components/forms/FormSelectable';

import AmenityContext from '@/context/Amenity';
import FilterContext from '@/context/Filter';

import useEvent from '@/hooks/useEvent';

const AmenityFilterOptions = () => {
  const {
    filters: { bookingType, amenities: selectedAmenities },
    updateFilters,
  } = React.useContext(FilterContext);

  const { amenities, roomAmenities } = React.useContext(AmenityContext);

  // Figure out which Amenities to list depending
  // on whether user is searching Spaces or Rooms
  const optionAmenities =
    bookingType === 'deskpass' ? amenities : roomAmenities;

  const onClick = useEvent((value) => {
    // Pull out currently-selected amenities
    let currentAmenities = [...selectedAmenities];

    // Figure out if the option was just checked or unchecked
    const isChecked = currentAmenities.indexOf(value) > -1;

    // Then update the amenity array appropriately
    if (isChecked) {
      // If the option is currently checked, remove it from the list of
      // amenities
      currentAmenities.splice(currentAmenities.indexOf(value), 1);
    } else {
      // If not, add it to the list of amenities
      currentAmenities.push(value);
    }

    // Set updated list of selected amenities in the store
    updateFilters({ amenities: currentAmenities });
  });

  const options = React.useMemo(
    () =>
      optionAmenities.map((amenity) => ({
        label: amenity.name,
        value: amenity.slug,
        image: amenity.icon,
        selected: selectedAmenities.indexOf(amenity.slug) > -1,
      })),
    [selectedAmenities, optionAmenities],
  );

  return (
    <FormSelectable
      name="amenities"
      type="checkbox"
      onClick={onClick}
      options={options}
      row
    />
  );
};

export default AmenityFilterOptions;
