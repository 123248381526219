import React from 'react';

import useEvent from '@/hooks/useEvent';

export default function useWatch(
  effectCallback = () => null,
  watchedValues = [],
  fireOnMount = true,
) {
  if (!Array.isArray(watchedValues)) {
    throw new Error('"useWatch" second argument must be an array.');
  }

  const instance = React.useRef({
    fireOnMount,
    mounted: false,
  });
  const previousRef = React.useRef(watchedValues);

  const eventCallback = useEvent(effectCallback);
  const dependencies = watchedValues.concat(eventCallback);

  React.useEffect(
    () => {
      const { fireOnMount, mounted } = instance.current;

      // Abort if not mounted and only desired to fire
      // on subsequent value changes and ignore first run.
      if (!fireOnMount && !mounted) {
        instance.current.mounted = true;
        return;
      }

      const disposer = eventCallback(...previousRef.current);
      previousRef.current = watchedValues;

      return disposer;
    },
    // eslint-disable-next-line
    dependencies,
  );
}
