import React from 'react';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import UserContext from '@/context/User';

import useEvent from '@/hooks/useEvent';
import useMediaQuery from '@/hooks/useMediaQuery';
import useMount from '@/hooks/useMount';

import { consumerToHOC } from '@/lib/hoc';
import {
  hubpostInitialState,
  loadHubspot,
  resetHubspotConfig,
  updateHubpostChatConfig,
} from '@/lib/hubspotChat';

const Context = React.createContext(null);

// Loads hubspot script before component lifecycle
const hubstpotLoadingPromise = loadHubspot();

export const HubspotProvider = ({ children }) => {
  const [hubspotsState, setHubspotState] = React.useState(() => ({
    ...hubpostInitialState,
  }));

  const {
    user,
    authenticated,
    ready: sessionCheckReady,
  } = React.useContext(UserContext);

  const onHubspotLoad = useEvent(async () => {
    const { ready, tracker, widget, openWidget } = await hubstpotLoadingPromise;

    // Save state after hubspot is done loading it's scripts
    setHubspotState({
      ready,
      tracker,
      widget,
      openWidget,
    });
  });

  useMount(() => {
    onHubspotLoad();
  });

  const [, hubspotTokenFetcher] = useDeskpassAPI(
    (api) => api.auth.hubspot.contactToken,
    { fireOnMount: false },
  );

  const isMobile = useMediaQuery({ max: 'large' });

  const authenticateHubspot = useEvent(async () => {
    try {
      const token = await hubspotTokenFetcher();
      const { email, id } = user;

      // Associate tracking session with contact email
      hubspotsState.tracker.identify({ id, email });

      // Authenticate chat
      updateHubpostChatConfig({ token, email });
    } catch (err) {
      console.error('Unable to retrieve Hubspot Conversation token:', err);
    }
  });

  const hubspotLogout = useEvent(() => {
    resetHubspotConfig(isMobile);
  });

  const { ready: hubspotReady } = hubspotsState;

  React.useEffect(() => {
    // Authenticates the chat when the user becomes authenticated
    if (sessionCheckReady && hubspotReady) {
      if (authenticated) {
        authenticateHubspot();
      } else {
        hubspotLogout();
      }
    }
  }, [
    sessionCheckReady,
    hubspotReady,
    authenticated,
    authenticateHubspot,
    hubspotLogout,
  ]);

  React.useEffect(() => {
    // Updates the config to the mobile version
    updateHubpostChatConfig({ isMobile });
  }, [isMobile]);

  return <Context.Provider value={hubspotsState}>{children}</Context.Provider>;
};

export const withHubspot = consumerToHOC(Context.Consumer, 'hubspotContext');
export const useHubspotContext = () => React.useContext(Context);

export default Context;
