import React from 'react';

import FormSelectable from '@/components/forms/FormSelectable';

import AmenityContext from '@/context/Amenity';
import FilterContext from '@/context/Filter';

import useEvent from '@/hooks/useEvent';

const MoodFilterOptions = () => {
  const { filters, updateFilters } = React.useContext(FilterContext);
  const { moods } = React.useContext(AmenityContext);
  const currentMood = filters.mood;

  const onClick = useEvent((value) => {
    updateFilters({
      mood: value,
    });
  });

  const options = React.useMemo(
    () =>
      [
        {
          label: 'Any Mood',
          value: '',
          selected: '' === currentMood,
        },
      ].concat(
        moods.map((mood) => ({
          label: mood.name,
          value: mood.slug,
          selected: mood.slug === currentMood,
        })),
      ),
    [currentMood, moods],
  );

  return (
    <FormSelectable
      name="mood"
      type="radio"
      onClick={onClick}
      options={options}
      row
    />
  );
};

export default MoodFilterOptions;
