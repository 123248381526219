import React from 'react';

import useEvent from '@/hooks/useEvent';
import useMount from '@/hooks/useMount';

const useOutsideClick = (handler = () => null) => {
  const ref = React.useRef(null);
  const onOutsideClick = useEvent(handler);

  useMount(() => {
    const listener = (event) => {
      let domEl = ref.current;

      if (domEl?.contains?.(event?.target)) {
        return;
      }

      // Call the handler only if the click is outside of the element passed.
      onOutsideClick(event);
    };

    window.addEventListener('click', listener);

    return () => {
      // Disposes of the event listeners
      window.removeEventListener('click', listener);
    };
  });

  // Returns the ref to be assined to the element
  // that should have the outside click behavior
  return ref;
};

export default useOutsideClick;
