import styled, { css } from 'styled-components';

import { rgba } from '@/styles/js/utils/colors';
import { subtleShadow } from '@/styles/js/utils/shadow';

import typeStyle from '@/styles/js/fonts/typeStyles';
import fontSmoothing from '@/styles/js/fonts/smoothing';

/**
 * Get Bagde size styles
 * @param {String} size - `small | medium | large`
 */
const getSizeStyles = ({ size }) => ({
  small: css`
    font-size: 1.1rem;
  `,
  medium: css`
    font-size: 1.3rem;
    padding: 0.4rem 0.9rem;
  `,
  large: css`
    font-size: 1.5rem;
    padding: 0.6rem 1rem;
  `,
}[size]);

/**
 * Get Badge color styles
 * @param {String} color - `white | altA | altB | altC | good | altGood | bad |warn | neutral | inactive`
 */
const getColorStyles = ({ theme, color }) => ({
  white: css`
    ${subtleShadow}

    border: none;
    color: ${theme.colors.textLight};
    background-color: ${theme.colors.white};
  `,
  altA: css`
    background-color: ${theme.colors.slate};
    border-color: ${theme.colors.slate};
  `,
  altB: css`
    background-color: ${theme.colors.accent};
    border-color: ${theme.colors.accent};
  `,
  altC: css`
    background-color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
  `,
  good: css`
    background-color: ${theme.colors.primaryDark};
    border-color: ${theme.colors.primaryDark};
  `,
  altGood: css`
    background-color: transparent;
    border-color: ${theme.colors.primary};
    color: ${theme.colors.primary};
  `,
  bad: css`
    background-color: #ea6e66;
    border-color: #ea6e66;
  `,
  warn: css`
    background-color: ${theme.colors.orange};
    border-color: ${theme.colors.orange};
  `,
  neutral: css`
    background-color: #b3c0c7;
    border-color: #b3c0c7;
  `,
  inactive: css`
    background-color: transparent;
    border-color: ${({ theme }) => rgba(theme.colors.slate, 0.45)};
    color: ${({ theme }) => rgba(theme.colors.slate, 0.75)};
  `,
}[color]);

export const Badge = styled.span`
  ${typeStyle('mini')};
  ${fontSmoothing}

  font-weight: bold;
  line-height: 1.6rem;
  color: white;
  background-color: ${({ theme }) => theme.colors.text};
  border-radius: 0.2rem;
  border: 1px solid ${({ theme }) => theme.colors.text};
  display: inline-flex;
  padding: 0.2rem 0.7rem;

  ${getSizeStyles}
  ${getColorStyles}
`;
