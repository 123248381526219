/*
 * Lazy load all main routes using lazy import feature and
 * webpack code-spliting with dynamic import feature.
 *
 * That way the app JS code is broken by
 * page and what the page needs
 */
import { lazyImport } from '@/lib/componentHelpers';

export const LogoutView = lazyImport(() =>
  import('@/components/views/LogoutView'),
);
export const AccountShowView = lazyImport(() =>
  import('@/components/views/AccountShowView'),
);
export const BookingResourcesRoutes = lazyImport(() =>
  import('@/components/views/BookingResources/Routes'),
);
export const ReservationIndexView = lazyImport(() =>
  import('@/components/views/ReservationIndexView'),
);
export const ReservationShowView = lazyImport(() =>
  import('@/components/views/ReservationShowView'),
);
export const ReservationInfoView = lazyImport(() =>
  import('@/components/views/ReservationInfoView'),
);
export const MoreView = lazyImport(() => import('@/components/views/MoreView'));
export const RulesView = lazyImport(() =>
  import('@/components/views/RulesView'),
);
export const SignupIndexView = lazyImport(() =>
  import('@/components/views/Signup/Routes'),
);
export const ConfirmEmailView = lazyImport(() =>
  import('@/components/views/ConfirmEmailView'),
);
export const ForgotPasswordView = lazyImport(() =>
  import('@/components/views/ForgotPasswordView'),
);
export const BreatherSetPasswordView = lazyImport(() =>
  import('@/components/views/BreatherSetPasswordView'),
);
export const ResetPasswordView = lazyImport(() =>
  import('@/components/views/ResetPasswordView'),
);
export const LoginView = lazyImport(() => import('@/components/views/Login'));
export const GuestInvitationView = lazyImport(() =>
  import('@/components/views/GuestInvitationView'),
);
