export function trackCreateUser({
  analyticsContext,
  routerContext: { parsedSearchParams },
  data,
}) {
  const {
    firstName,
    lastName,
    email,
    country,
    homeCity,
    cityId,
    stripeCardToken,
    mobileNumber,
    coupon,
    organization,
    teamOrganizationId,
    isTeamUser,
  } = data;

  // marketing params
  const { adgroupid, adposition, campaignid, gclid, keyword, network } =
    parsedSearchParams;

  let eventName = 'Signup Step - Create User';
  let teamTraits = {};

  if (isTeamUser) {
    eventName = `Team Member ${eventName}`;

    teamTraits.organizationName = organization;
    teamTraits.organizationDatabaseId = teamOrganizationId;
  }

  // Send off event to Hubspot
  analyticsContext.hubspot.legacyEvent(email, eventName, {
    firstName,
    lastName,
    email,
    country,
    homeCity,
    cityId,
    stripeCardToken,
    organization,
    mobileNumber,
    coupon,
    ...teamTraits,

    // params from queryString
    adgroupid,
    adposition,
    campaignid,
    gclid,
    keyword,
    network,

    /* eslint-disable camelcase */
    utm_medium: parsedSearchParams['utm_medium'],
    utm_source: parsedSearchParams['utm_source'],
    /* eslint-enable */
  });
}

export function trackSubmitEmailMetrics({
  routerContext,
  analyticsContext,
  data,
}) {
  const { zip, email } = data;
  const { parsedSearchParams } = routerContext;

  const {
    coupon: offeredCoupon,
    // marketing
    adgroupid,
    adposition,
    campaignid,
    gclid,
    keyword,
    network,
  } = parsedSearchParams;

  /* eslint-disable camelcase */
  return analyticsContext.hubspot.legacyEvent(
    email,
    'Signup Step - Submit Email',
    {
      eventName: 'Signup Step - Submit Email',
      email,
      zip,
      offeredCoupon,
      adgroupid,
      adposition,
      campaignid,
      gclid,
      keyword,
      network,
      utm_medium: parsedSearchParams['utm_medium'],
      utm_source: parsedSearchParams['utm_source'],
    },
  );
  /* eslint-enable */
}

export function trackTeamLeadMetrics({ analyticsContext, data = {} }) {
  const {
    companyName,
    companySize,
    dateSent,
    name,
    email,
    primaryNeed,
    scheduleDemo,
  } = data;

  // Send both legacy and beta events for this one (they're used to trigger
  // different things in Hubspot currently)
  return Promise.all([
    analyticsContext.hubspot.legacyEvent(email, 'Team Lead', {
      companyName,
      companySize,
      dateSent,
      name,
      email,
      primaryNeed,
      scheduleDemo,
    }),
    analyticsContext.hubspot.event(email, 'Team Lead', {
      companyName,
      companySize,
      dateSent,
      name,
      email,
      primaryNeed,
      scheduleDemo,
    }),
  ]);
}
