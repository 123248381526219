import PropTypes from 'prop-types';

import * as S from './FormRow.styles';

/**
 * Enables layout of form groups side-by-side.
 */
const FormRow = ({ children }) => <S.FormRow>{children}</S.FormRow>;

FormRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormRow;
