import { memo, useCallback, useRef, useState } from 'react';

import Icon from '@/components/graphics/Icon';

import useDebounce from '@/hooks/useDebounce';
import useEvent from '@/hooks/useEvent';
import useMount from '@/hooks/useMount';
import useWindowResize from '@/hooks/useWindowResize';

import * as S from './ScrollableArea.styles';

export const ScrollableArea = memo(
  ({
    children,
    renderScrollLeftBtn,
    renderScrollRightBtn,
    onScroll = () => {},
  }) => {
    const [scrollable, setScrollable] = useState(() => false);
    const [scrollPosition, setScrollPosition] = useState(() => 'start');

    const scrollableAreaRef = useRef();

    const handleScrollPositioning = useEvent(() => {
      const scrollableAreaDomEl = scrollableAreaRef.current;

      if (!scrollableAreaDomEl) return;

      const { scrollLeft, scrollWidth, clientWidth } = scrollableAreaDomEl;

      if (scrollLeft === 0) {
        setScrollPosition('start');
      } else if (scrollWidth - clientWidth <= Math.ceil(scrollLeft)) {
        setScrollPosition('end');
      } else {
        setScrollPosition(null);
      }
    });

    const setIsScrollable = useDebounce(() => {
      if (!scrollableAreaRef.current) {
        return;
      }

      const { scrollWidth, clientWidth } = scrollableAreaRef.current;

      setScrollable(scrollWidth > clientWidth);
    }, 100);

    const updateScroll = useEvent(() => {
      handleScrollPositioning();
      setIsScrollable();
    });

    const _onScroll = useDebounce((e) => {
      onScroll(e);
      updateScroll();
    }, 250);

    useMount(() => {
      if (!scrollableAreaRef.current) {
        return;
      }

      updateScroll();

      const mutationObserver = new MutationObserver(updateScroll);

      mutationObserver.observe(scrollableAreaRef.current, {
        childList: true,
        subtree: true,
        characterData: true,
      });

      return () => {
        mutationObserver.disconnect();
      };
    });

    useWindowResize({ onResize: updateScroll });

    const registerScrollTo = useCallback(
      (direction) => () => {
        if (!['left', 'right'].includes(direction)) {
          throw new Error('"registerScrollTo" only accepts "left" or "right".');
        }

        const scrollableAreaDomEl = scrollableAreaRef.current;
        const { scrollWidth } = scrollableAreaDomEl;
        const increment = scrollWidth * 0.15;

        const scrollTo = () => {
          const { scrollLeft } = scrollableAreaDomEl;

          const left =
            scrollLeft + (direction === 'left' ? -increment : increment);

          scrollableAreaDomEl.scrollTo({
            left,
            behavior: 'smooth',
          });
        };

        scrollTo();
        const interval = setInterval(scrollTo, increment);

        const mouseup = () => {
          clearInterval(interval);
          document.removeEventListener('mouseup', mouseup);
        };

        document.addEventListener('mouseup', mouseup);
      },
      [scrollableAreaRef],
    );

    const scrollToLeft = useEvent(registerScrollTo('left'));
    const scrollToRight = useEvent(registerScrollTo('right'));

    return (
      <S.ScrollableArea>
        {scrollable && scrollPosition !== 'start' && (
          <S.ScrollableEdge>
            {typeof renderScrollLeftBtn === 'function' ? (
              <>{renderScrollLeftBtn({ scrollToLeft })}</>
            ) : (
              <Icon onMouseDown={scrollToLeft} type="fat-carat-left-24" />
            )}
          </S.ScrollableEdge>
        )}

        <S.ScrollableAreaInner onScroll={_onScroll} ref={scrollableAreaRef}>
          {children}
        </S.ScrollableAreaInner>

        {scrollable && scrollPosition !== 'end' && (
          <S.ScrollableEdge>
            {typeof renderScrollRightBtn === 'function' ? (
              <>{renderScrollRightBtn({ scrollToRight })}</>
            ) : (
              <Icon onMouseDown={scrollToRight} type="fat-carat-right-24" />
            )}
          </S.ScrollableEdge>
        )}
      </S.ScrollableArea>
    );
  },
);
