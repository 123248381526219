import styled from 'styled-components/macro';

import LoginFormComponent from '@/components/forms/LoginForm';

import mq from '@/styles/js/utils/mq';

export const LoginForm = styled(LoginFormComponent)`
  width: 35rem;
  margin: 7rem 1rem;

  ${mq({ until: 'medium' })} {
    width: 100%;
    margin: 6rem 0;
  }
`;

export const ModalContent = styled.div`
  padding: 1rem 3rem 0;
`;

export const Tabs = styled.div`
  margin: 0 2.5rem 0;
`;

export const CloseIcon = styled.div`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;

  margin: 1rem 0 0 1rem;

  display: flex;
  align-items: center;
`;
