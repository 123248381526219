import React from 'react';
import { useTheme } from 'styled-components/macro';

import { rgba } from '@/styles/js/utils/colors';

const useElemenStyles = () => {
  const theme = useTheme();
  const { text, red } = theme.colors;

  return React.useMemo(
    () => ({
      style: {
        base: {
          fontFamily: 'averta-regular',
          fontSize: '16px',
          color: text,
          letterSpacing: '0.025em',
          '::placeholder': {
            color: rgba(text, 0.3),
          },
        },
        invalid: {
          color: red,
        },
      },
    }),
    [text, red],
  );
};

export default useElemenStyles;
