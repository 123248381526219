import PropTypes from 'prop-types';
import { forwardRef, memo, useMemo } from 'react';

import SvgUse from '@/components/graphics/SvgUse';

import { getSizeModifier } from '@/lib/icons';

import * as S from './Icon.styles';

/**
 * A small graphic element useful for communicating
 * the purpose of an element visually.
 */
const Icon = memo(
  forwardRef(function Icon(
    { type, inline = false, svgProps = {}, className, ...props },
    ref,
  ) {
    const sizeModifier = useMemo(() => {
      const size = (getSizeModifier(type) * 0.1)?.toFixed(1);
      if (['number', 'string'].includes(typeof size)) return size;
      return undefined;
    }, [type]);

    return (
      <S.Icon
        className={className}
        $inline={inline}
        size={sizeModifier}
        ref={ref}
        {...props}
      >
        <SvgUse {...svgProps} id={type} category="icons" />
      </S.Icon>
    );
  }),
);

Icon.propTypes = {
  type: PropTypes.string,
  inline: PropTypes.bool,
};

export default Icon;
