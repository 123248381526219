import styled from 'styled-components';

export const BadgeList = styled.div`
  display: flex;
  white-space: nowrap;
  cursor: pointer;

  > * + * {
    margin-left: 0.7rem;
  }
`;
