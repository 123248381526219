import styled, { css } from 'styled-components/macro';
import { Link as RRLink } from 'react-router-dom';

import mq from '@/styles/js/utils/mq';
import { colors } from '@/styles/js/utils/theme';
import { rgba } from '@/styles/js/utils/colors';

import FilterMenu from '../FilterMenu';

const sideSpacing = '1rem';

export const FiltersNavbar = styled.section`
  border-bottom: solid 1px ${colors.rule};
  background: white;
  padding: 0;
  display: flex;
  flex-direction: column;

  ${mq('large')} {
    padding: 1.5rem 3rem;
    padding-right: 0;
  }

  box-shadow:
    -6px 0 transparent,
    6px 0 transparent,
    0px 3px 4px -2px ${rgba('black', 0.12)};
`;


export const FilterNav = styled.nav`
  display: flex;
  flex-direction: column;
  margin: 0 ${sideSpacing};

  ${mq({ until: 'large' })} {
    padding: 1rem 0;

    ${({ asAppBar }) => asAppBar && css`
      margin: 0;
      padding: 1rem ${sideSpacing};

      box-shadow:
        -6px 0 transparent,
        6px 0 transparent,
        0px 3px 4px -2px ${rgba('black', 0.12)};
    `}

    ${({ asAppBar }) => !asAppBar && css`
      gap: 1rem;
    `}
  }
`;

export const MainNav = styled.nav`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  justify-items: start;

  & > *:last-child {
    justify-self: end;
  }

  ${mq({ until: 'large' })} {
    gap: 1rem;
  }
`;

export const SearchNav = styled.nav`
  width: 100%;
  display: flex;

  & > * {
    flex: 1;
  }
`;

export const MobileMainNav = styled.nav`
  display: flex;

`;


export const LogoLink = styled(RRLink)`
  &&& img,
  &&& svg {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
`;

export const MenuButton = styled.button.attrs({
  type: 'button'
})`
  border: none;
  border-radius: 8%;
  background: none;

  &&& svg {
    margin: auto;
    fill: ${colors.ruleDark};
  }
`;

export const DesktopFilterMenu = styled(FilterMenu)`
  display: flex;
  gap: 1rem;
`;
