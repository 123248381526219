import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import FormSelect from '@/components/forms/FormSelect';
import Icon from '@/components/graphics/Icon';
import { Icon as StyledIcon } from '@/components/graphics/Icon.styles';

const StyledFormTimePicker = styled.div`
  display: flex;

  > * {
    flex: auto;
  }

  ${StyledIcon} {
    color: ${({ theme }) => theme.colors.accent};
    margin: 1rem;
  }
`;

/**
 * Allows for simple start/end time selection.
 */
class FormTimePicker extends React.Component {
  state = {
    options: [],
    start: '',
    end: '',
  };

  /**
   * On component mount, put options together (so we're only doing this once
   * rather than every render).  Start/end options are the same to start.
   */
  componentDidMount() {
    let start = moment({ hour: 0 });
    let options = [{ label: 'Select...', value: '' }].concat(
      [...Array(48).keys()].map(() => {
        const value = {
          label: start.format('h:mm a'),
          value: start.format('h:mma'),
        };

        start.add(30, 'minutes');

        return value;
      }),
    );

    this.setState({
      options: options,
      start: this.props.start,
      end: this.props.end,
    });
  }

  updateTimes = (start, end) => {
    // Figure out if we need to fast forward end time
    const optionKeys = this.state.options.map((option) => option.value);

    // If both start/end are set and there is no time difference between them,
    // jump end forward a notch
    if (
      start &&
      end &&
      optionKeys.indexOf(end) < optionKeys.indexOf(start) + 1
    ) {
      end =
        this.state.options[
          Math.min(optionKeys.indexOf(start) + 1, optionKeys.length - 1)
        ].value;
    }

    this.setState({ start, end }, () => {
      // Then update parent with combined values
      this.props.onChange({
        start: this.state.start,
        end: this.state.end,
      });
    });
  };

  render() {
    return (
      <StyledFormTimePicker>
        <FormSelect
          narrow
          name="start"
          value={this.props.start || this.props.defaultStart}
          options={this.state.options}
          disabled={this.props.disabled}
          onChange={(value) => {
            // Update internal values
            this.updateTimes(value, this.state.end);
          }}
        />
        <Icon type="arrow-right-24" />
        <FormSelect
          narrow
          name="end"
          value={this.props.end || this.props.defaultEnd}
          options={this.state.options}
          disabled={this.props.disabled}
          onChange={(value) => {
            // Update internal values
            this.updateTimes(this.state.start, value);
          }}
        />
      </StyledFormTimePicker>
    );
  }
}

FormTimePicker.defaultProps = {
  start: '',
  end: '',
  defaultStart: '',
  defaultEnd: '',
  onChange: () => {},
  disabled: false,
};

FormTimePicker.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  defaultStart: PropTypes.string,
  defaultEnd: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FormTimePicker;
