import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import Backend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import config from '@/lib/config';
// Load in local translation strings
import enGlobal from '@/lib/locales/en/global';

// Configure the basic i18next instance with Backend for loading locales from
// server and LanguageDetector for automatically attempting to figure out user
// language from browser
i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    // HTTP Backend config
    backend: {
      backends: [
        Backend,
        resourcesToBackend({
          'en-US': {
            global: enGlobal,
          },
        }),
      ],
      backendOptions: [
        {
          loadPath: `${config.API_URL}/api/locales/{{lng}}/{{ns}}`,
        },
      ],
    },

    // Default language settings
    load: 'currentOnly',
    preload: ['en-US'],
    fallbackLng: 'en-US',
    supportedLngs: ['en-US'],
    ns: ['global'],
    defaultNS: 'global',
    fallbackNS: 'global',

    // Flip on to log init info
    debug:
      config.DEBUG_I18N &&
      !!config.NODE_ENV &&
      (config.NODE_ENV === 'dev' || config.NODE_ENV === 'development'),
  });

export default i18n;
