import { childrenOfType, componentWithName } from 'airbnb-prop-types';
import PropTypes from 'prop-types';

import * as Styled from './index.styles';

/**
 * Helps an SVG grow responsively.
 */
const FluidSvg = ({ children, percent, absolute }) => (
  <Styled.FluidSvg style={{ paddingTop: percent + '%' }} absolute={absolute}>
    {children}
  </Styled.FluidSvg>
);

FluidSvg.propTypes = {
  children: PropTypes.oneOfType([
    componentWithName('SvgUse'),
    componentWithName('Lottie'),
    childrenOfType('svg'),
  ]).isRequired,
  percent: PropTypes.number.isRequired,
  absolute: PropTypes.bool,
};

export default FluidSvg;
