import config from '@/lib/config';
import NetworkClient from '@/lib/network/client';

const apiKey = config.IP_GEOLOCATION_KEY;
const baseURL = `https://ip-geolocation.whoisxmlapi.com/api/v1?apiKey=${apiKey}`;

export class IpGeolocationNetworkClient extends NetworkClient {
  config = {
    baseURL,
  };
}

function createClient(instance = new IpGeolocationNetworkClient()) {
  return {
    getGeolocationByIp() {
      if (!apiKey) {
        console.error('Missing IpGeolocation API key.');

        // ipGeolocation shouldn't put anything to a screeching halt
        return Promise.resolve('missingIpGeolocationKey');
      }

      return instance.get();
    },
  };
}

// Default singleton instance
export default createClient();
