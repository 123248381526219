import moment from 'moment-timezone';

const DATE_ONLY_FORMAT = 'YYYY-MM-DD';

// Convert weekday into day of the week name
const WEEK_DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const toJsDateInTz = (date, timezone) => {
  return new Date(moment(date).tz(timezone).format('lll'));
};

/**
 * Given a start and optional end, return difference between the two formatted
 * as 'X hours, Y minutes'. If end is not passed, start will be treated as
 * "time until" date and duration calcuated will be from now until start.
 *
 * @param {Moment}  start   Start date for calculating difference
 * @param {Moment}  end     End date for calculating difference [optional]
 * @param {Boolean} shorten If true will abbreviate time units
 *
 * @return {String} Returns formatted string
 */
export const formatDuration = (start, end, shorten) => {
  if (start && end) {
    // Take advantage of full start/end interval
    start = moment.parseZone(start);
    end = moment.parseZone(end);
  } else {
    // Use now as start and passed start as end so duration is calculated as
    // time until start
    end = moment.parseZone(start);
    start = moment();
  }

  let duration = moment.duration({ minutes: end.diff(start, 'minutes') });

  // In the event duration is <0, reset it to just to be 0 (this can happen due
  // to small time differences between the server and app or due to reservation
  // buffers, that kind of thing)
  if (duration.asMilliseconds() < 0) {
    duration = moment.duration();
  }

  // Put together individual display components
  let pieces = [
    { label: shorten ? 'yr' : 'year', value: duration.years() },
    { label: shorten ? 'mth' : 'month', value: duration.months() },
    { label: shorten ? 'wk' : 'week', value: duration.weeks() },
    { label: shorten ? 'dy' : 'day', value: duration.days() },
    { label: shorten ? 'hr' : 'hour', value: duration.hours() },
    { label: shorten ? 'min' : 'minute', value: duration.minutes() },
  ];

  // Slap populated pieces together, tacking on proper suffix as we go
  return pieces
    .reduce((current, { label, value }) => {
      if (value) {
        current.push(`${value} ${label}${value !== 1 ? 's' : ''}`);
      }

      return current;
    }, [])
    .join(', ');
};

/**
 * Given a date and time of the format 'h:mma' return a moment containing the
 * specified date at the specified time.
 *
 * @param {Mixed} date Parsable date to modify
 * @param {String} time Time to modify date with, in the format h:mma
 *
 * @return {Moment} Returns moment with passed date modified with passed time
 */
export const momentFromTime = (date, time) => {
  let timeMoment = moment(time, 'h:mma');

  return moment(date).set({
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
  });
};

/**
 *
 * Nicely format a date with abbreviated description
 *
 * @param    {string}   date         ISO-formatted date string
 * @param    {boolean}  includeYear  Should year be included
 * @return   {string}   The formatted date string
 */
export const formatDateAbbreviated = (date, includeYear = false) => {
  let dateParts = ['ddd', 'MMM D'];
  if (includeYear) dateParts.push('YYYY');
  return moment.parseZone(date).format(dateParts.join(', '));
};

/**
 *
 * Nicely format a date to be used in a reservation label
 *
 * @param    {Moment}  date   Date to be formatted
 * @param    {string}  startTime  Time string to be formatted
 * @param    {string}  endTime  Time string to be formatted
 * @param    {boolean} isOffice  Boolean indicating whether the reservation is for an office
 * @return   {string}   The formatted date string
 */
export const formatDateReservation = (
  date,
  startTime,
  endTime,
  isOffice = false,
) => {
  let reservationString = '';

  if (isOffice) {
    const start = moment(startTime, 'YYYY-MM-DD').format('MMM D');
    const end = endTime ? moment(endTime, 'YYYY-MM-DD').format('MMM D') : start;
    const year = moment(startTime, 'YYYY-MM-DD').format('YYYY');

    reservationString = `${start}${end !== start ? `–${end}` : ''}, ${year}`;
  } else {
    reservationString = `${date.format('MMMM D, YYYY')}`;
    if (startTime && endTime) {
      reservationString += ` (${startTime}-${endTime})`;
    }
  }

  return reservationString;
};

/**
 * Nicely format a date with abbreviated description
 * @param    {string}   date         ISO-formatted date string
 * @param    {boolean}  includeYear  Should year be included
 * @return   {string}   The formatted date string
 */
export const formatDateShort = (
  date,
  includeYear = false,
  includeWeekday = false,
) => {
  let dateParts = ['MMM D'];
  if (includeWeekday) dateParts.unshift('ddd');
  if (includeYear) dateParts.push('YYYY');
  return moment.parseZone(date).format(dateParts.join(', '));
};

/**
 * Nicely format a date with extended description
 *
 * @param    {string}   date         ISO-formatted date string
 * @param    {boolean}  includeYear  Should year be included
 * @return   {string}   The formatted date string
 */
export const formatDateExtended = (date, includeYear = false) => {
  let dateParts = ['dddd', 'MMM D'];
  if (includeYear) dateParts.push('YYYY');
  return moment.parseZone(date).format(dateParts.join(', '));
};

/**
 * Display formatted datetime with all date components (year, month, day,
 * time).
 *
 * @param {Date} datetime Datetime to format
 *
 * @return {String} Returns formatted datetime string
 */
export const formatDateTime = (datetime) => {
  return moment.parseZone(datetime).format('MMM D, YYYY h:mma');
};

/**
 * Formats a given time nicely
 *
 * @param    {string}   startDate  The start date
 * @param    {string}   endDate    The end date
 * @param    {string}   endDate    The end date
 * @param    {boolen}  remove Zeroes    The end date
 * @return   {string}   { description_of_the_return_value }
 */
export const formatTime = (startDate, endDate, removeZeroes = false) => {
  const startMoment = moment.parseZone(startDate);
  const endMoment = moment.parseZone(endDate);
  const formattedStart =
    startMoment.format('a') === endMoment.format('a')
      ? startMoment.format('h:mm')
      : startMoment.format('h:mma');
  const formattedEnd = endMoment.format('h:mma');
  let formattedTime = [formattedStart, formattedEnd].join('–');

  if (removeZeroes) {
    formattedTime = formattedTime.replace(new RegExp(':00', 'g'), '');
  }

  return formattedTime;
};

/**
 * Obtain the diff amount in hours between two dates.
 *
 * @param    {string}  startDate  The start date
 * @param    {string}  endDate    The end date
 * @return   {number}  Difference in time as hours
 */
export const diffAmountHours = (startDate, endDate, includeText = false) => {
  const ms = moment(endDate).diff(moment(startDate));
  let hours = moment.duration(ms).asHours().toFixed(1) * 1;

  if (includeText) {
    hours += hours === 1 ? ' hour' : ' hours';
  }

  return hours;
};

/**
 * Add a half hour to a time
 *
 * @param    {string}  startTime  ISO-formatted date string
 * @return   {string}  Date with 30 minutes added as a date string
 */
export const addHalfHour = (startTime) => {
  return moment.parseZone(startTime).add(30, 'minutes').format();
};

/**
 * Zeroes out the time for a date.
 *
 * @param    {string}  date  ISO-formatted date string
 * @return   {string}  Date with time set to 00:00:00
 */
export const zeroTime = (date) => {
  return moment
    .parseZone(date)
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .format();
};

/**
 * Convert a time to a timezone
 *
 * Useful if, for instance, we want to display the time for a reservation in
 * the local time for the space the reservation is in.
 *
 * @param    {string}  time      ISO-formatted date string or moment
 * @param    {string}  timezone  Timezone name compatible with moment-timezone
 * @return   {string}  time  ISO-formatted date string
 */
export const inTimezone = (time, timezone = 'America/Chicago') => {
  // If timezone is missing, return the original formatted time
  // and throw an error
  if (typeof timezone !== 'string') {
    // eslint-disable-next-line no-console
    console.error(
      `inTimezone received an invalid value of "${timezone}" for "timezone" and returned the unmodified "time" value.`,
    );
    return moment.parseZone(time).format();
  }

  return moment.parseZone(time).tz(timezone).format();
};

/**
 * Sometimes we need to move datetime info from one timezone to another, but
 * otherwise leave it untouched.  This is useful if you need to work with
 * datetime in one timezone as if it were in another.
 *
 * @param {Mixed} date Date to move to different timezone
 * @param {String} timezone Timezone to move date to. If not passed, local
 * timezone will be assumed
 *
 * @return {Moment} Returns original datetime, sans timezone info
 */
export const shiftTimezone = (date, timezone) => {
  let parsed = moment.parseZone(date);

  return (timezone ? moment.tz(timezone) : moment()).set({
    year: parsed.year(),
    month: parsed.month(),
    date: parsed.date(),
    hour: parsed.hour(),
    minute: parsed.minute(),
    second: parsed.second(),
  });
};

/**
 * Sometimes we need to move datetime info from one timezone to another, but
 * otherwise leave it untouched.  This is useful if you need to work with
 * datetime in one timezone as if it were in another.
 *
 * @param {Moment} date Date to move to different date. If not passed, will
 * default to today
 * @param {String} timezone Timezone to move date to. If not passed, local
 * timezone will be assumed
 *
 * @return {Moment} Returns original datetime, sans timezone info
 */
export const shiftDateKeepTime = (date, timezone) => {
  let now = moment();
  if (timezone) {
    now = moment.tz(timezone);
  }

  let args = [
    // Filtered date with the current time
    (date || now).format(DATE_ONLY_FORMAT) + ' ' + now.format('HH:mm'),
  ];

  let method = moment;

  if (timezone) {
    args.push(timezone);
    method = moment.tz;
  }

  return method(...args);
};

/**
 * Convert filter date components to usable dates that can be passed to server.
 *
 * @param {Moment} date      Base date to form start/end timestamps from
 * @param {String} startTime Start time to modify date with [optional]
 * @param {String} endTime   End time to modify date with [optional]
 *
 * @return {Object} Returns object containing processed start/end timestamps
 */
export const formatDateTimeArgsForMeet = (date, startTime, endTime) => {
  // If only date was passed this is pretty straightforward
  if (!startTime || !endTime) {
    return { start: date.format(), end: '' };
  }

  return {
    start: momentFromTime(date, startTime).format(),
    end: momentFromTime(date, endTime).format(),
  };
};

export const DATE_NO_TIME_FORMAT = 'yyyy-MM-DD';

export const formatDateArgsForOffice = (start, end) => {
  let formatted = {};
  formatted.start = moment(start, DATE_NO_TIME_FORMAT).startOf('day').format();

  if (end) {
    formatted.end = moment(end, DATE_NO_TIME_FORMAT).endOf('day').format();
  }

  return formatted;
};

/**
 * Finds the first date with an available time slot in the given array.
 *
 * @param {Array} dates - The dates to check, each an object with a 'date' property (a string in "YYYY-MM-DDTHH:mm:ssZ" format) and an 'availability' property (an array of timeslots). Assumes the array is ordered by date, and only considers the first 31 items.
 * @param {Object} hours - An object containing opening and closing hours for each day of the week.
 * @returns {string|null} - The date of the first available timeslot, or null if no available timeslot is found.
 */
export function findFirstAvailableDate(dates, hours) {
  if (!dates || !hours) return null;

  // Limit to first 31 items
  const datesOfMonth = dates.slice(0, 31);

  const now = moment();
  const today = now.format(DATE_ONLY_FORMAT);
  const currentHour = now.format('HH:mm');

  for (const date of datesOfMonth) {
    // Parse the date from the string
    const dateMoment = moment(date.date);
    const dateDayName = WEEK_DAYS[dateMoment.day()];
    const { close_time: closingTime } = hours.hoursDetail[dateDayName] || {};

    // Check if it's today and if the space is open
    if (dateMoment.format(DATE_ONLY_FORMAT) === today) {
      // If it's closed today or past closing hours, continue to next date
      if (
        hours.closedWeekdays.includes(dateMoment.day()) ||
        (closingTime && currentHour > closingTime)
      ) {
        continue;
      }
    }

    // If we're considering future dates or the space is open today, check availability
    if (date.availability && date.availability.length > 0) {
      return date.date;
    }
  }

  return null;
}

/**
 * Checks if the space is closed on a specific day.
 *
 * @param {Date} date - The date to check.
 * @param {Array} closedDays - Array of closed days, each an object with a 'start' and 'end' date.
 * @returns {boolean} - True if the space is closed on the given day, false otherwise.
 */
export function isSpaceClosedOnDay(date, closedDays) {
  return closedDays.some((closedDay) => {
    const start = new Date(closedDay.start);
    const end = new Date(closedDay.end);
    return date >= start && date <= end;
  });
}

/**
 * Checks if the space is closed on a specific weekday.
 *
 * @param {number} dayOfWeek - The day of the week to check (0-6, where 0 is Sunday and 6 is Saturday).
 * @param {Array} closedWeekdays - Array of closed weekdays, each a number representing the day of the week.
 * @returns {boolean} - True if the space is closed on the given weekday, false otherwise.
 */
export function isSpaceClosedOnWeekday(dayOfWeek, closedWeekdays) {
  return closedWeekdays.includes(dayOfWeek);
}

/**
 * Checks if the space is open at a specific date and time.
 *
 * @param {moment} date - The moment.js date object to check.
 * @param {Array} weekdayHours - Array of objects, each representing the opening hours for a specific weekday.
 * @returns {boolean} - True if the space is open at the given date and time, false otherwise.
 */
export function isSpaceOpenOnDay(date, weekdayHours) {
  const dayOfWeek = date.day();
  const currentHour = date.hour();
  return weekdayHours.some((weekdayHour) => {
    return (
      weekdayHour.weekday === dayOfWeek &&
      weekdayHour.start <= currentHour &&
      weekdayHour.end >= currentHour
    );
  });
}
