import { breakpoint, isBreakpoint } from '@/lib/breakpoints';
import { isNumber } from '@/lib/utility';

export const cssPxCoercion = (value) => {
  let parsed = +value;
  return isNaN(parsed) ? value : `${value}px`;
};

/*
 * There are more valid units than this but we're not using.
 * Here's a list: https://www.w3schools.com/cssref/css_units.asp
 * If we ever need more just add here.
 */
const validUnits = ['px', 'rem', 'em', '%', 'vw', 'vh'];

/*
 * Validate if a provided arg is a valid css number with a valid unit
 * from the ones we declared in "validUnits".
 */
export const isValidCssUnit = (unitString) => {
  if (typeof unitString !== 'string') return false;

  let unitUsed = validUnits.filter((it) => unitString.endsWith(it));

  if (!unitUsed) return false;

  let [numberBits] = unitString.split(unitUsed);

  return isNumber(numberBits);
};

export const parseValidMeasureUnitOrBreakpoint = (value) => {
  // Returns valid breakpoints
  if (isBreakpoint(value)) {
    return cssPxCoercion(breakpoint(value));
  }

  // Coerce numbers to "px"
  if (isNumber(value)) {
    return cssPxCoercion(value);
  }

  // Accept valid CSS
  if (isValidCssUnit(value)) {
    return value;
  }

  // Otherwise yield null
  return null;
};

const emBase = `16px`; // 16px !default
/**
 * PX-to-EM
 * ---
 * 
 * Convert pixels to ems.
 * 
 * Example: for a relational value of `12px`, write `em(12)`.
 * If the parent is another value say `24px`, write `em(12, 24)`.
 * @param {int} pxval 
 * @param {int} base 
 * @returns styled css unit in em
 */
export const em = (pxval, base = emBase) => {
  return `calc((${pxval / base}) * 1em)`;
};