import useMediaQuery from '@/hooks/useMediaQuery';

import * as Styled from './index.styles';

export const StatusBarArea = (...props) => {
  const desktop = useMediaQuery({ min: 'medium' });

  if (desktop) return null;

  return (
    <Styled.StatusBarArea {...props} hasStatusBar={!!window.statusBarHeight} />
  );
};
