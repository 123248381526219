import styled from 'styled-components/macro';

import mq from '@/styles/js/utils/mq';

export const App = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ${mq({ until: 'large' })} {
    padding-bottom: env(safe-area-inset-bottom);
  }
`;
