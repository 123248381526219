/**
 * Helper functions related to billing functionality.
 */

/**
 * Adjust passed amount by passed Stripe coupon, returning the adjusted amount.
 * If the coupon isn't valid or another issue occurs, will just return the
 * original amount.
 *
 * @param {Object} coupon Stripe Coupon object describing discount to apply to
 * amount
 * @param {Float} amount Amount to adjust with coupon (as a decimal value, NOT
 * in cents)
 *
 * @return {Float} Returns amount adjusted by coupon if possible
 */
export const applyCoupon = (coupon, amount) => {
  // Nothing to apply so just return the original amount. Good job everyone!
  if (!coupon) {
    return amount;
  }

  try {
    if (!coupon.valid) {
      throw Error('invalidCoupon');
    }

    if (coupon.percent_off) {
      amount = amount * ((100 - coupon.percent_off) * 0.01);
    } else if (coupon.amount_off) {
      amount = Math.max(amount - coupon.amount_off * 0.01, 0);
    }

    return +Math.max(amount, 0).toFixed(2);
  } catch (err) {
    console.error(`Error applying coupon ${coupon?.id}`, err);

    return amount;
  }
};
