import { StatusBarArea } from '@/components/helpers/MobileDeviceArea';
import ScrollToTop from '@/components/helpers/ScrollToTop';

import useContextErrorHandler from '@/context/App/useContextErrorHandler';

import { HashScroller } from '../helpers/HashScroller';
import MainContent from './MainContent';
import * as Styled from './index.styles';

const App = () => {
  useContextErrorHandler();

  return (
    <Styled.App>
      <StatusBarArea />
      <HashScroller />
      <MainContent />
      <ScrollToTop />
    </Styled.App>
  );
};

export default App;
