import PropTypes from 'prop-types';
import { memo } from 'react';

import Button from '@/components/buttons-links/Button';
import FooterLarge from '@/components/nav/FooterLarge';
import Heading from '@/components/text-headings/Heading';
import Text from '@/components/text-headings/Text';

import useMediaQuery from '@/hooks/useMediaQuery';

import route from '@/lib/routes';

import * as S from './ErrorPage.styles';

function GenericError({ title, message, btnText, renderAction, btnProps }) {
  const desktop = useMediaQuery({ min: 'medium' });

  let defaultAction = () => (
    <Button
      arrow
      to={route('index')}
      widthSize={!desktop ? 'full' : ''}
      {...btnProps}
    >
      {btnText}
    </Button>
  );

  renderAction = renderAction || defaultAction;

  return (
    <S.ErrorPage>
      <S.ErrorPageContent>
        <Heading size={desktop ? 'xl' : 'l'}>{title}</Heading>

        <Text size={desktop ? 'xlarge' : 'large'} alignment="align-center">
          <p>{message}</p>
        </Text>

        {desktop && renderAction()}
      </S.ErrorPageContent>

      <div>{desktop ? <FooterLarge /> : <>{renderAction()}</>}</div>
    </S.ErrorPage>
  );
}

GenericError.defaultProps = {
  title: 'Something went wrong.',
  message:
    'The Deskpass team has been notified and is looking into the problem.',
  btnText: 'Reload the App',
  btnProps: {},
};

GenericError.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default memo(GenericError);
