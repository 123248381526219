import fetchDriver from '@/lib/network/drivers/fetch';
import {
  bodylessMethods,
  isIn400StatusRange,
  isRedirect,
  isRequestCancel,
  isTypeError,
  methods,
} from '@/lib/network/utils';
import addRetry from '@/lib/retry';

const retryConfigDefaults = {
  // This one is specific to NetworkClient
  methods: ['get'], // Defaults to retrying all GET calls

  // These belong to addRetry
  interval: 300,
  backoffPolicy: (x) => x * 1.5,
  onErrorIgnore: (error) => {
    if (!error) {
      return false;
    }

    return [
      isTypeError(error),
      isRequestCancel(error),
      isIn400StatusRange(error),
      isRedirect(error),
    ].some(Boolean);
  },
};

const configDefaults = {
  baseURL: '',
  retryConfig: retryConfigDefaults,
  transformData: ({ data } = {}) => data,
  transformError: ({ error, status } = {}) => {
    error.status = status;

    return error;
  },
  includeHeaders: () => ({}),
  onRequest: () => ({}),
  onSuccess: () => ({}),
  onError: () => ({}),
};

class NetworkClient {
  constructor(config = {}, driver = fetchDriver) {
    this._config = { ...configDefaults, ...config };
    this._driver = driver;

    this.instance = driver.instance;

    methods.forEach(this._createRequestMethodImplementation);
  }

  get config() {
    return this._config;
  }

  set config(newConfig = {}) {
    this._config = this._mergeWithBaseConfig(newConfig);
  }

  _mergeWithBaseConfig = (config = {}) => {
    const baseConfig = {
      ...this.config,
      ...config,
    };

    const retryConfig = {
      ...(this.config.retryConfig ?? {}),
      ...(config.retryConfig ?? {}),
    };

    let headers = {
      ...(this.config.headers ?? {}),
      ...(config.headers ?? {}),
    };

    headers = {
      ...headers,
      ...(baseConfig.includeHeaders(config) ?? {}),
    };

    return {
      ...baseConfig,
      retryConfig,
      headers,
    };
  };

  _createRequestImplementation = (method, config = {}) => {
    let request = this._driver[method];

    const { methods, ...retryConfig } = config.retryConfig;

    if (methods?.includes(method)) {
      request = addRetry(request, retryConfig);
    }

    return async (...args) => {
      try {
        config.onRequest(config);

        const response = await request(...args);

        config.onSuccess(response);

        return config.transformData(response, config);
      } catch (err) {
        let error = err.originalError ?? err;

        config.onError(error, config);

        // See if it's a retry error otherwise pass the error
        error = config.transformError(err.originalError ?? err, config);

        this._logError({
          error: err,
          method,
          config,
          transformedError: error,
        });

        throw error;
      }
    };
  };

  _logError = ({ error, method, config, transformedError }) => {
    const { status, url } = config;
    let errorMsg = `"${method.toUpperCase()}" request `;

    if (url) {
      errorMsg += `to ${config.url} `;
    }

    errorMsg += `failed `;

    if (status) {
      errorMsg += `with status ${status} `;
    }

    const message = transformedError.message ?? error.message;

    if (message) {
      errorMsg += `due to: ${message}`;
    }

    console.error(errorMsg, error);
  };

  _makeConfigEnhancer =
    ({ method, path, url }) =>
    (argConfig = {}) => {
      return this._mergeWithBaseConfig({
        ...argConfig,
        method,
        path,
        url,
      });
    };

  _createRequestMethodImplementation = (method) => {
    const isBodyless = bodylessMethods.includes(method);

    this[method] = (...args) => {
      if (isBodyless) {
        const { url, config, request } = this._requestBuilder({
          method,
          path: args[0],
          config: args[1],
        });

        return request(url, config);
      }

      const { url, body, config, request } = this._requestBuilder({
        method,
        path: args[0],
        body: args[1],
        config: args[2],
      });

      // Get with only URL goes here with body undefined
      return request(url, body, config);
    };
  };

  _requestBuilder = ({ path, method, body, config: _config }) => {
    const url = this._buildURL(path, _config);
    const enhanceConfig = this._makeConfigEnhancer({ method, path, url });
    const config = enhanceConfig(_config);
    const request = this._createRequestImplementation(method, config);

    return { url, body, config, request };
  };

  _buildURL = (path = '', config = {}) => {
    return { ...this.config, ...config }.baseURL + path;
  };
}

export default NetworkClient;
