import React from 'react';

import * as hubspot from '@/api/hubspot';

import * as analytics from '@/lib/analytics';
import { consumerToHOC } from '@/lib/hoc';

const Context = React.createContext({});

const { Provider, Consumer } = Context;

export const AnalyticsProvider = ({ children }) => {
  const [, setReady] = React.useState(() => false);

  // Sets ready state when analytics lib is loaded
  React.useEffect(() => {
    analytics.initialize(() => {
      setReady(true);
    });
  }, []);

  /**
   * LinkedIn conversion event - Tracks user login
   */
  const trackUserLoginLinkedInConversion = React.useCallback(
    /* eslint-disable-next-line camelcase */
    () => window.lintrk('track', { conversion_id: 10263754 }),
    [],
  );

  /**
   * LinkedIn conversion event - Tracks user reservation
   */
  const trackUserReservationLinkedInConversion = React.useCallback(
    /* eslint-disable-next-line camelcase */
    () => window.lintrk('track', { conversion_id: 10263762 }),
    [],
  );

  /**
   * Track a conversion via Google Analytics (gtag).
   */
  const trackGoogleConversion = React.useCallback((eventData) => {
    window.gtag('event', 'conversion', eventData);
  }, []);

  const context = {
    trackUserLoginLinkedInConversion,
    trackUserReservationLinkedInConversion,
    trackGoogleConversion,
    hubspot,
  };

  return <Provider value={context}>{children}</Provider>;
};

export default Context;
export const useAnaliticsContext = () => React.useContext(Context);

export const withAnalyticsContext = consumerToHOC(Consumer, 'analyticsContext');
