import { css, keyframes } from 'styled-components/macro';

export const skiquickEffect = keyframes`
  50% {
    transform: scale(1.4, 0.6);
  }
`;

export const siquishAnimation = css`
  animation: ${skiquickEffect} 200ms ease-in-out;
`;

const processingEffect = keyframes`
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
`;

export const processingAnimation = css`
  animation-name: ${processingEffect};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  will-change: transform;
`;

const sonarEffect = keyframes`
  0% {
    opacity: 1;
    transform: scale(0);
  }

  80% {
    opacity: 0;
    transform: scale(1);
  }
`;

export const sonarAnimation = css`
  animation-name: ${sonarEffect};
  animation-duration: 3.5s;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-iteration-count: infinite;
  will-change: transform;
`;

const bounceEffect = keyframes`
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
`;

export const bounceAnimation = css`
  animation-name: ${bounceEffect};
  animation-duration: 3.5s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-iteration-count: infinite;
  will-change: transform;
`;

