import PropTypes from 'prop-types';

import * as Styled from './index.styles';

/**
 * ActionSet
 * ---
 * Combines an interactive element with additional footer content.
 *
 * Props
 * - `renderHeader`
 * - `renderFooter`
 *
 * Styled props
 * - `padded`
 * - `horizontalPadding`
 * - `full`
 * - `fullSmall`
 * - `bottomAbsolute`
 * - `shadowTop`
 * - `split`
 * - `splitSmall`
 * - `spacedHeader`
 * - `spacedFooter`
 * - `footerLeft`
 */
const ActionSet = ({ children, renderHeader, renderFooter, ...otherProps }) => {
  const showHeader = typeof renderHeader === 'function';
  const showFooter = typeof renderFooter === 'function';

  return (
    <Styled.ActionSet {...otherProps}>
      {showHeader && <Styled.Header>{renderHeader()}</Styled.Header>}

      <Styled.Item>{children}</Styled.Item>

      {showFooter && (
        <Styled.Footer {...otherProps}>{renderFooter()}</Styled.Footer>
      )}
    </Styled.ActionSet>
  );
};

ActionSet.propTypes = {
  children: PropTypes.node,
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func,
  split: PropTypes.bool,
  padded: PropTypes.bool,
  spacedHeader: PropTypes.bool,
  spacedFooter: PropTypes.bool,
  shadowTop: PropTypes.bool,
  footerLeft: PropTypes.bool,
  full: PropTypes.bool,
  fullSmall: PropTypes.bool,
  bottomAbsolute: PropTypes.bool,
};

export default ActionSet;
