import { useAmenityContext } from '@/context/Amenity';
import { useCountryContext } from '@/context/Country';
import { useCurrentRoomOrSpaceContext } from '@/context/CurrentRoomOrSpace';
import { useGlobalConfigContext } from '@/context/GlobalConfig';
import { useGoogleMapContext } from '@/context/GoogleMap';
import { useTranslation } from '@/context/I18n';
import { useIpLocationContext } from '@/context/IpLocation';
import { useMetaContext } from '@/context/Meta';
import { useUserContext } from '@/context/User';

export default function useSetupContexts() {
  const userContext = useUserContext();
  const ipLocationContext = useIpLocationContext();
  const globalConfigContext = useGlobalConfigContext();
  const metaContext = useMetaContext();
  const googleMapContext = useGoogleMapContext();
  const amenityContext = useAmenityContext();
  const countryContext = useCountryContext();
  const currentRoomOrSpaceContext = useCurrentRoomOrSpaceContext();
  const translationContext = useTranslation('global', { useSuspense: false });

  /*
   * For the purpose of the app setup, currentRoomOrSpaceContext ready state
   * should only matter if we're in a detail page.
   */
  const currentRoomOrSpaceReady =
    currentRoomOrSpaceContext.ready || !currentRoomOrSpaceContext.inDetailPage;

  // The app needs state from all of these
  // context before it can load it's main pieces.
  return [
    googleMapContext,
    translationContext,
    countryContext,
    userContext,
    ipLocationContext,
    metaContext,
    globalConfigContext,
    amenityContext,
    { ready: currentRoomOrSpaceReady },
  ].filter((it) => !!it);
}
