import React from 'react';
import { matchPath, withRouter } from 'react-router-dom';

import route from '@/lib/routes';

/**
 * Ensures that the window is automatically scrolled to the top
 * when routing, as described in:
 * https://reacttraining.com/react-router/web/guides/scroll-restoration
 */
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    // Don't match for certain routes
    const excludedRoutes = [
      route('guestEdit'),
      route('guestAdd'),
      route('reservationCancel'),
      route('reservationModify'),
      route('reservationModifyConfirm'),
      route('reservationExtend'),
      route('reservationExtendConfirm'),
      route('reservationAddToCalendar'),
      route('spaceReserve'),
      route('spaceReserveConfirm'),
      route('roomReserve'),
      route('roomReserveConfirm'),
      route('accountCreditCardEdit'),
    ];
    const excludedMatches = excludedRoutes.filter((path) => {
      return matchPath(this.props.location.pathname, { path });
    });
    const prevExcludedMatches = excludedRoutes.filter((path) => {
      return matchPath(prevProps.location.pathname, { path });
    });

    if (excludedMatches.length || prevExcludedMatches.length) {
      return;
    }

    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
