import { css } from 'styled-components/macro';

const absoluteDefaultPositions = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

/*
 * Styled drop in replacement for our old sass mixin with the same name
 */
export const absolute = (pos = {}) => {
  const positions = { ...absoluteDefaultPositions, ...pos };

  return css`
    position: absolute;
    top: ${positions.top};
    right: ${positions.right};
    bottom: ${positions.bottom};
    left: ${positions.left};
  `;
};
