import React from 'react';

import useEvent from '@/hooks/useEvent';
import useMount from '@/hooks/useMount';
import useStateMergeUpdater from '@/hooks/useStateMergeUpdater';

/*
 * Default config values
 */
const defaultConfig = {
  /*
   * Wether the request should be fired on component mount.
   * Mutually exclusive with the fireWhens.
   */
  fireOnMount: true,
  /*
   * Fires the request once, the first time this valus is true
   * Mutually exclusive with the fireOnMount.
   */
  fireOnceWhen: undefined,
  /*
   * Fires whenever this becomes true.
   * Mutually exclusive with the fireOnMount.
   */
  fireWhen: undefined,
  /*
   * Same concept as useEffect deps array
   * Mutually exclusive with the fireOnMount.
   */
  onChange: undefined,
  /*
   * Optional callback called when the request is complete with no errors
   */
  onSuccess: () => null,
  /*
   * Optional callback called when an error occur
   */
  onError: () => null,
  /*
   * Optional args used just in the first request on mount
   */
  initialArgs: [],
  /*
   * Optional values used to set custom initial state value
   */
  initialState: undefined,
};

const defaultInitialState = {
  // Populated on successful request
  // Default undefined so it can be assigned
  // default values easily on destruct assignment
  data: undefined,
  // Populated on failed request
  // Default undefined so it can be assigned
  // default values easily on destruct assignment
  error: undefined,
  // true when data is being loaded
  loading: false,
  // true after the first request is complete
  ready: false,
};

/**
 * Custom hook that facilitates HTTP requests to the Deskpass API
 * and manages the request lifecle.
 *
 * @param {Function} requestMapper:
 *   - Maps the api client to the actual api caller.
 *   @return {Function} apiCaller:
 *     - Receive any args and calls Deskpass API endpoints.
 *     @return {Promise|Other}:
 *       - When it returns {Promise}, Resolve|Reject the results of the API call.
 *       - When it returns anything but promise it means request was not made.
 *
 * @param {Object} config - Defines configurations that may affect the hook internals.
 *
 * @return {Array} - Tuple containing:
 *  - {Object} requestState - Contains { data, error, loading, ready } states
 *  - {Function} callAPI - Receive any args and calls Deskpass API endpoints
 */
const useAPI = (apiClient, requestMapper, config = {}) => {
  // Take advantage of the mutable .current to hold
  // values without triggering re-renders
  const selfRef = React.useRef({});

  // Request mapper is mandatory, otherwise
  // we can't know what endpoint we're calling.
  if (typeof requestMapper !== 'function') {
    throw new Error(
      '"useAPI" => "requestMapper" argument should be a function',
    );
  }

  let {
    fireOnMount,
    fireOnceWhen,
    fireWhen,
    onChange,
    initialArgs,
    initialState,
    ...remainingConfig
  } = {
    ...defaultConfig,
    ...config,
  };

  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  });

  // Network request state
  const [state, setState] = React.useState(() => initialStateRef.current);

  // Disable "fireOnMount" when the "fireWhens" or "onChange"
  // are set to something other than "undefined"
  const disableFireOnMount = [fireOnceWhen, fireWhen, onChange].some(
    (it) => it !== undefined,
  );

  // Cast to boolean to avoid triggering the effect
  // every time this gets a new instance of something.
  fireOnMount = !!fireOnMount;
  fireOnceWhen = !!fireOnceWhen;
  fireWhen = !!fireWhen;

  /*
   *  Initializes ref instance with useful things
   *  that should not trigger re-renders.
   *  This will only run once.
   */
  React.useEffect(() => {
    // Initializes instance state
    selfRef.current = {
      // Used by fireOnceWhen to make sure it only runs once
      firedOnce: false,
    };
  }, []);

  const apiCaller = useEvent(requestMapper(apiClient));
  const onError = useEvent(remainingConfig.onError ?? (() => null));
  const onSuccess = useEvent(remainingConfig.onSuccess ?? (() => null));

  const resetState = useEvent(() => setState(initialStateRef.current));

  const updateState = useStateMergeUpdater(setState);

  const updateCompleteState = useEvent((newState = {}) =>
    updateState({
      error: undefined,
      data: undefined,
      ...newState,
      loading: false,
      ready: true,
    }),
  );

  /*
   * Defines the API caller and updates the request state
   */
  const callAPI = useEvent(async (...args) => {
    try {
      updateState({ loading: true });

      const data = await apiCaller(...args);
      updateCompleteState({ data });

      // Calls success calbback
      onSuccess({ data, args });

      return data;
    } catch (error) {
      console.error('useAPI request failure: ', error);

      updateCompleteState({ error });
      // Calls error calbback
      onError({ error, args });
      // Throws so it's possible to catch when calling
      // it directly inside the caller component
      throw error;
    }
  });

  /*
   * This is a "callAPI" wrapper used internally whenever we don't
   * want a promise to be returned and just want the state
   * machine to be updated instead.
   */
  const _callAPI = useEvent(async (...args) => {
    try {
      await callAPI(...args);
    } catch (error) {
      // Engulf the error on purpose.
      // At this point error state is already set.
    }
  });

  /*
   * Fires the request immediately if "fireOnMount" is true
   * Only runs once. Doesn't matter if the value changes
   */
  useMount(() => {
    if (fireOnMount && !disableFireOnMount) {
      _callAPI(...initialArgs);
    }
  });

  /*
   * Fires the request only the first time "fireOnceWhen" is true
   */
  React.useEffect(() => {
    if (fireOnceWhen && !selfRef.current.firedOnce) {
      selfRef.current.firedOnce = true;
      _callAPI();
    }
  }, [_callAPI, fireOnceWhen]);

  /*
   * Fires the request only the first time "fireOnceWhen" is true
   */
  React.useEffect(() => {
    if (fireWhen) {
      _callAPI();
    }
  }, [_callAPI, fireWhen]);

  let onChangeDeps;
  if (Array.isArray(onChange)) {
    onChangeDeps = [_callAPI].concat(onChange);
  }

  /*
   * Fires whenever the deps in "onChangeDeps" change like useEffect
   * except that if "onChangeDeps" is undefined it won't fire callAPI
   */
  React.useEffect(
    () => {
      if (Array.isArray(onChangeDeps)) {
        _callAPI();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    onChangeDeps,
  );

  const actions = React.useMemo(
    () => ({
      resetState,
      updateState,
    }),
    [resetState, updateState],
  );

  // Exposes a tuple with the request state and api caller function
  return [state, callAPI, actions];
};

export default useAPI;
