import PropTypes from 'prop-types';
import React from 'react';

import Icon from '@/components/graphics/Icon';
import Mq from '@/components/helpers/Mq';
import { useIntercom } from '@/components/intercom';

import { useHubspotContext } from '@/context/Hubspot';
import MoreModalContext from '@/context/MoreModal';
import RouterContext from '@/context/Router';
import UserContext from '@/context/User';

import route from '@/lib/routes';

import * as Styled from './index.styles';

/**
 * The primary navigation shown at small device sizes.
 * Displays uniquely depending on whether a user
 * is logged in.
 *
 * You should typically use the default import for this component,
 * but we've also provided an unwrapped component useful for testing
 * and styleguide purposes available as a named component.
 */
const NavSmall = ({ reverse, simple }) => {
  const { authenticated, newRoomBookingMessages } =
    React.useContext(UserContext);
  const { withinIframe, lastMainMobileRoute } = React.useContext(RouterContext);
  const { toggleMoreModal } = React.useContext(MoreModalContext);

  return (
    <>
      <Styled.NavSmallSpacer />
      <Styled.NavSmall
        /*
         * This is necessary because of the selector used in MobileMapView.
         * We could eventually come up with a better solution for that eventually.
         */
        className="NavSmall"
        $reverse={reverse}
      >
        {authenticated ? (
          <>
            <Styled.NavItem to={route(lastMainMobileRoute)}>
              Spaces
            </Styled.NavItem>

            <Styled.NavItem to={route('reservationIndex')}>
              {!!newRoomBookingMessages.length && (
                <Styled.NotificationDot>
                  {newRoomBookingMessages.length}
                </Styled.NotificationDot>
              )}
              Reservations
            </Styled.NavItem>

            <Styled.NavItem to={route('accountShow')}>Account</Styled.NavItem>

            <HelpLink />

            <Styled.NavItemButton onClick={toggleMoreModal}>
              <Icon type="ellipse-24" />
            </Styled.NavItemButton>
          </>
        ) : (
          <>
            {!simple && (
              <Mq min="small">
                <Styled.NavItemAnchor href={route('benefits')}>
                  Benefits & Pricing
                </Styled.NavItemAnchor>
              </Mq>
            )}

            <Styled.NavItem to={route(lastMainMobileRoute)}>
              Spaces
            </Styled.NavItem>

            {!simple && (
              <Styled.NavItem to={route('login')}>Log In</Styled.NavItem>
            )}

            {!simple && !withinIframe && (
              <Styled.NavItem to={route('signup')}>Sign Up</Styled.NavItem>
            )}

            <HelpLink />
          </>
        )}
      </Styled.NavSmall>
    </>
  );
};

/**
 * Render simple link to Deskpass Help page if active chat widget is not
 * available, or if it is render a button to open the support chat window.
 */
function HelpLink() {
  const hubspotContext = useHubspotContext();

  const { Intercom, showNewMessage } = useIntercom();

  // If chat widget (Intercom or Hubspot) is loaded, set up button to
  // launch chat when Help is pressed
  if (hubspotContext || Intercom?.booted) {
    return (
      <Styled.NavItemButton
        onClick={() => {
          if (hubspotContext) {
            hubspotContext.openWidget();
          } else {
            showNewMessage();
          }
        }}
      >
        Help
      </Styled.NavItemButton>
    );
  }

  // Otherwise fall through to simple link to help page
  return <Styled.NavItemAnchor href={route('help')}>Help</Styled.NavItemAnchor>;
}

NavSmall.propTypes = {
  reverse: PropTypes.bool,
  simple: PropTypes.bool,
};

export default NavSmall;
