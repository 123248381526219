/**
 * 7/11/2023:
 * This used to manage setting up Segment based on the user's tracking
 * preferences in iOS. We're not using Segment anymore, but I would like to
 * reinstate this functionality for our new analytics provider Hubspot at some
 * point, so I'm keeping all the tracking preference stuff around for now.
 */
import debounce from 'p-debounce';

import BrowserWebviewClient from '@/lib/BrowserWebviewClient';
import config from '@/lib/config';

const webviewClient = new BrowserWebviewClient();

const isDisabled = config.ANALYTICS_TRACKING_DISABLED;

// TODO implement
// eslint-disable-next-line
const _loadAnalytics = (onReady = () => null) => {
  // Load analytics lib, whatever that ends up being, Hubspot probably
};

const _loadOnIOSWrapper = (onReady = () => null) => {
  const initialTrackingStatus = webviewClient?.ios?.trackingStatus;

  // Simply abort. Don't load analytics if user denied tracking
  if (initialTrackingStatus === 'denied') {
    return;
  }

  // If tracking was authorized once load analytics already
  if (initialTrackingStatus === 'authorized') {
    return _loadAnalytics(onReady);
  }

  // Listens to the user tracking status decision that will come from the ios webview the first
  // time the user selects a choice on the tracking status popup
  const listenToWebviewMessage = ({ data }) => {
    // Authorized once load analytics already
    if (data.newTrackingStatus === 'authorized') {
      _loadAnalytics(onReady);

      // Unregister listener
      window.removeEventListener('message', listenToWebviewMessage);
    }

    // Denied simply remove the listener, don't load analytics, move on with
    // life
    if (data.newTrackingStatus === 'denied') {
      // Unregister listener
      window.removeEventListener('message', listenToWebviewMessage);
    }
  };

  // Register listener;
  window.addEventListener('message', listenToWebviewMessage);
};

/*
 * Initialize analytics lib
 */
export const initialize = (onReady = () => null) => {
  if (isDisabled) {
    return;
  }

  if (webviewClient.isIos()) {
    _loadOnIOSWrapper(onReady);
  } else {
    _loadAnalytics(onReady);
  }
};

/*
 * Fires a page event
 *
 * I added a debouncer here to compensate for all of the
 * client side routing logic the app does so that this way
 * we can avoid firing too many repeated events because
 * of the addition or removal of query parameters or things
 * of the sort.
 */
export const page = debounce((location) => {
  const { pathname, search } = location;

  const path = pathname + (search || '');

  if (typeof window.ga === 'function') {
    window.ga('send', 'pageview');
  }
  if (typeof window.gtag === 'function') {
    window.gtag('config', 'UA-46647614-3');
    window.gtag('config', 'G-X5RPE6GJPG');
  }
  if (typeof window._hsq === 'object') {
    window._hsq.push(['setPath', path]);
    window._hsq.push(['trackPageView']);
  }
}, 100);
