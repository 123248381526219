import { memo, useState } from 'react';

import Icon from '@/components/graphics/Icon';
import Modal from '@/components/modals/Modal';
import NavBar from '@/components/nav/NavBar';

import useEvent from '@/hooks/useEvent';

import * as S from './Mobile.styles';
import useGeocodingFilter from './useGeocodingFilter';

const GeocodingFilterMobile = () => {
  const [open, setOpen] = useState(false);

  const { onSelect, geocodingParams, place } = useGeocodingFilter();

  const openModal = useEvent(() => setOpen(true));
  const closeModal = useEvent(() => setOpen(false));

  const applySearch = useEvent((selectedItem) => {
    onSelect(selectedItem);
    closeModal();
  });

  return (
    <>
      <S.Search onClick={openModal}>
        <Icon type="search-24" />
        <S.Text>{place?.name || 'Search locations and spaces...'}</S.Text>
      </S.Search>

      <Modal
        no-padding
        flex-content
        type="full"
        label="placeSearchModal"
        show={open}
        renderNav={() => (
          <NavBar responsive title="Search" onCloseClick={closeModal} />
        )}
      >
        <S.ModalContent>
          <S.GeocodingSearch
            {...geocodingParams}
            initialInputValue={place?.name}
            onSelectedItemChange={applySearch}
            inputCSS={S.inputCSS}
            menuCSS={S.menuCSS}
            menuItemCSS={S.menuItemCSS}
          />
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default memo(GeocodingFilterMobile);
