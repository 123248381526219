import { Reset as ResetStyles } from 'styled-reset';
import { Normalize as NormalizeStyles } from 'styled-normalize';

import GlobalStyle from '@/styles/js/global/global';
import GlobalFonts from '@/styles/js/fonts/global';

import HubspotStyles from '@/styles/js/vendor/Hubspot';

const AppStyles = () => {
  return (
    <>
      <NormalizeStyles />
      <ResetStyles />
      <GlobalFonts />
      <GlobalStyle />
      <HubspotStyles />
    </>
  );
};

export default AppStyles;
