import { Elements as StripeElementsProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import config from '@/lib/config';

const stripePromise = loadStripe(config.STRIPE_KEY);

export const StripeProvider = ({ children }) => {
  // TODO see if we can import a font from the app itself
  const options = {
    fonts: [
      {
        cssSrc:
          'https://storage.googleapis.com/deskpass/assets/fonts/averta/averta.css',
      },
    ],
  };

  return (
    <StripeElementsProvider stripe={stripePromise} options={options}>
      {children}
    </StripeElementsProvider>
  );
};
