import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import { allBreakpoints } from '@/lib/breakpoints';

/**
 * Automatically displays `children` based on the viewport
 * size.
 *
 * `min` is the minimum viewport size where the content will display
 * `max` is the maximum viewport size where the content will display
 */
const Mq = ({ children, min, max, ...otherProps }) => (
  <MediaQuery
    minWidth={allBreakpoints()[min]}
    maxWidth={allBreakpoints()[max] - 1}
    {...otherProps}
  >
    {children}
  </MediaQuery>
);

Mq.defaultProps = {
  min: 'zero',
  max: 'infinite',
};

Mq.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
};

export default Mq;
