import { css } from 'styled-components/macro';
import { rgba } from './colors';

/**
 * Not Selectable
 * 
 * Prevents selection of text
 */
export const notSelectable = css`
  -webkit-touch-callout: none;
  user-select: none;
`;

/**
 * Reset Button Styles
 * 
 * Overrides the default styles on a <button> element
 */
export const resetButtonStyles = css`
  display: inline-block;
  padding: 0;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

/**
 * Common Focus
 * 
 * Adds consistent focus styles to certain elements
 */
export const commonFocus = css`
  outline: none;
  box-shadow: ${({ theme }) => '0 0 0 5px '.concat(rgba(theme.colors.text, 0.1))};
`;

/**
 * Hides content
 */
export const hideVisually = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;