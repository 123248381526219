import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import debounce from 'p-debounce';
import React, { Component, createContext } from 'react';

import api from '@/api/deskpass';

import { withAmenityContext } from '@/context/Amenity';
import { withFilterContext } from '@/context/Filter';
import { withMapContext } from '@/context/Map';
import { withUserContext } from '@/context/User';

import config from '@/lib/config';
import { compose, consumerToHOC } from '@/lib/hoc';
import { roundFloat } from '@/lib/mathHelpers';
import { atLeastOneTrue } from '@/lib/utility';

const Context = createContext({});

/*
 * TODO We should consider wether it makes sense to have Space and Room contexts
 * since they both have so much in common.
 */
class Provider extends Component {
  state = {
    ready: false,
    loading: false,
    spaces: [],
    dailyBookableSpaces: [],
    // Cache for updates in space favorite list keyd by id
    // This cache is always cleared after a spaces request is complete.
    favoriteCache: {},
  };

  render() {
    // Everything in here will be expose to the context consumer
    const context = {
      ...this.state,
      getSpaceMoods: this.getSpaceMoods,
      getSpaceAmenities: this.getSpaceAmenities,
      updateFavoriteCache: this.updateFavoriteCache,
    };

    return (
      <Context.Provider value={context}>{this.props.children}</Context.Provider>
    );
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const { mapContext, userContext } = this.props;

    const prevFilters = prevProps.filterContext.filters;
    const { filters } = this.props.filterContext;
    const { authenticated } = userContext;
    const prevAuthenticated = prevProps.userContext.authenticated;

    const {
      bookingType: prevBookingType,
      place: prevPlace,
      ...restPrevFilters
    } = prevFilters;

    const { bookingType, place, ...restFilters } = filters;

    const filtersChanged = !isEqual(restPrevFilters, restFilters);

    const toDeskMode =
      prevBookingType !== 'deskpass' && bookingType === 'deskpass';

    const toPlace = place && place !== prevPlace;
    const mapViewportChanged = this.didMapViewportChange(prevProps);

    const loggedIn = !prevAuthenticated && authenticated;
    const loggedOut = prevAuthenticated && !authenticated;

    if (bookingType === 'deskpass') {
      if (atLeastOneTrue(toDeskMode, loggedIn, loggedOut, filtersChanged)) {
        return this.load({}, 'filtersChanged');
      }

      if (toPlace) {
        const { lat, lng } = place;
        return this.load(
          {
            coords: { lat, lng },
            radius: config.DEFAULT_RADIUS,
          },
          'toPlace',
        );
      }

      // After moving the map around send the map center coords
      if (mapViewportChanged) {
        return this.load(
          {
            coords: mapContext.lastCenter,
            radius: mapContext.lastRadius,
          },
          'mapViewportChanged',
        );
      }

      this.initialize();
    }
  }

  initialize = () => {
    const { bookingType } = this.props.filterContext.filters;
    const { ready, loading } = this.state;

    // First load
    if (bookingType === 'deskpass' && !ready && !loading) {
      return this.load({}, 'init');
    }
  };

  /*
   * TODO We can probably find an easier approach to this by not doing this
   * in a reactive way and just call .load in response to the right callbacks
   * in our app components.
   *
   * Since this approach is reactive and data driven the way to figure out if
   * the map was dragged or zoomed is to check if the viewport has changed
   * more specifically, check if the map center or radius have changed.
   *
   * Cause in cases of zooms, only the radius will change and in case of
   * dragging only the center will change.
   */
  didMapViewportChange = (prevProps) => {
    const { mapContext } = this.props;

    const prevLat = roundFloat(prevProps.mapContext.lastCenter.lat, 2);
    const prevLng = roundFloat(prevProps.mapContext.lastCenter.lng, 2);
    const lat = roundFloat(mapContext.lastCenter.lat, 2);
    const lng = roundFloat(mapContext.lastCenter.lng, 2);

    if ([prevLat, prevLng, lat, lng].some((it) => isNaN(it) || !it)) {
      return false;
    }

    const radiusChanged =
      mapContext.lastRadius !== prevProps.mapContext.lastRadius;
    const coordsChanged = prevLat !== lat || prevLng !== lng;

    return coordsChanged || radiusChanged;
  };

  /*
   * Updates the cache of favorite spaces after user add/remove from the list
   * This cache is always cleared after a spaces request is complete.
   */
  updateFavoriteCache = (id, value) => {
    this.setState({
      ...this.state,
      favoriteCache: {
        ...this.state.favoriteCache,
        [id]: value,
      },
    });
  };

  load = (params = {}, eventName) => {
    return new Promise((resolve) => {
      this.setState({ loading: true });

      this.debouncedLoad(params, eventName, (spaces) => {
        this.setState(
          {
            spaces,
            // Clears the favorite cache
            favoriteCache: {},
            // Only spaces that can be booked
            dailyBookableSpaces: spaces.filter((it) => !it.roomsOnly),
            loading: false,
            ready: true,
          },
          () => {
            resolve(this.state.spaces);
          },
        );
      });
    });
  };

  // Debunce the data loading to avoid requesting it multiple times on
  // every setState call on the filter context or when initialize is called
  // on a bookingType filter change
  debouncedLoad = debounce((params = {}, eventName, cb) => {
    api.space.getAll(this.getRequestArgs(params, eventName)).then(cb);
  }, 10);

  getRequestArgs = ({ coords, radius } = {}, eventName) => {
    const { userContext, mapContext, filterContext } = this.props;

    const { authenticated } = userContext;
    const { filters } = filterContext;

    // Right now the date filter is not being sent to the API
    // cause we're not filtering it out
    // We are displaying all and greying out the closed ones.
    let args = {};

    const usedRadius = radius ? radius : config.DEFAULT_RADIUS;

    // City filter, place
    if (filters.place) {
      const { lat, lng } = filters.place;

      args = {
        ...(coords ? coords : { lat, lng }),
        radius: usedRadius,
        // Only send bounding box for viewport change event because other events
        // such as the filters changing happen before the viewport has had a
        // chance to update, so the bounding box sent will be out of date.
        // @TODO: Deal with the above at some point so we can send the bounding
        // box for all events.
        ...(mapContext.googleMap && eventName === 'mapViewportChanged'
          ? { boundingBox: JSON.stringify(mapContext.getCurrentBoundingBox()) }
          : null),
      };
    } else if (mapContext.dirty) {
      args = {
        ...(coords ? coords : mapContext.lastCenter),
        radius: usedRadius,
        ...(mapContext.googleMap && eventName === 'mapViewportChanged'
          ? { boundingBox: JSON.stringify(mapContext.getCurrentBoundingBox()) }
          : null),
      };
    }

    // Amenities
    const amenities = []
      .concat(filters.amenities, filters.mood)
      .filter((amenity) => amenity);
    if (amenities.length) {
      args.amenities = amenities;
    }

    // Change userTime param to get the correct availability fields
    if (filters.date) {
      args.userTime = moment().format();
      const date = moment(filters.date).startOf('day').format();

      args.openDate = date;
      args.date = date;
    }

    // Favorite spaces
    if (authenticated && filters.isFavorite) {
      args.isFavorite = filters.isFavorite;
    }

    return args;
  };

  getSpaceAmenitiesByType = (space, type) => {
    const { amenityContext } = this.props;
    let amenitiesByType = amenityContext[type];
    const tags = space.tags || [];

    if (!tags.length) return [];
    if (!amenitiesByType.length) return [];

    // Pull out amenity slugs for easier filtering
    const amenityTags = amenitiesByType.map((tag) => tag.slug);

    // Shoot back set of space's tags that match amenities in amenity context
    return tags.filter((tag) => amenityTags.includes(tag.slug));
  };

  /**
   * Same story as the above, but will pull Space's non-mood amenities.
   *
   * @param {Space} space Space to pull amenities for
   *
   * @return {Array} Returns array of amenities for the space or empty array if
   * space has no amenities set or something else goes wrong
   */
  getSpaceAmenities = (space) => {
    return this.getSpaceAmenitiesByType(space, 'amenities');
  };

  /**
   * Given passed space, return space's Moods (assuming it has any set).
   * Assumes tags are populated in the passed space. Also assumes all tags
   * are populated in AmenityContext (this is needed to tell which tags are
   * moods).
   *
   * @param {Space} space Space to pull moods for
   *
   * @return {Array} Returns array of moods for the space or empty array if
   * space has no moods set or something else goes wrong
   */
  getSpaceMoods = (space) => {
    return this.getSpaceAmenitiesByType(space, 'moods');
  };
}

export const useSpaceContext = () => React.useContext(Context);
export default Context;
export const SpaceProvider = compose(
  withMapContext,
  withFilterContext,
  withAmenityContext,
  withUserContext,
)(Provider);
export const withSpaceContext = consumerToHOC(Context.Consumer, 'spaceContext');
