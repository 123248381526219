import pick from 'lodash/pick';
import moment from 'moment-timezone';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useUserContext } from '@/context/User';

import useEvent from '@/hooks/useEvent';
import useStateMergeUpdater from '@/hooks/useStateMergeUpdater';
import useWatch from '@/hooks/useWatch';

import { consumerToHOC } from '@/lib/hoc';
import { pathMatchRouteName } from '@/lib/routes';

import { DATE_FILTER_FORMAT, DEFAULT_FILTER_STATE } from './constants';
import useFilterInitialPlace from './useFilterInitialPlace';
import useSearchParams from './useSearchParams';

const Context = React.createContext({});

export const FilterProvider = ({ children }) => {
  const { pathname } = useLocation();
  const { authenticated } = useUserContext();
  const searchParams = useSearchParams();
  // Place filter should never be empty
  const initialPlace = useFilterInitialPlace();

  const [filters, setFilters] = React.useState(() => {
    let bookingType = 'deskpass';
    let isOffice = false;

    const isRoomPath = [
      'roomIndex',
      'roomMap',
      'roomDetail',
      'roomList',
      'roomDetailSpaces',
      'roomReserve',
      'roomReserveConfirm',
    ].some((routeName) => pathMatchRouteName(pathname, routeName));

    if (isRoomPath) {
      bookingType = 'hourly';
      isOffice = searchParams.isOffice;
    }

    let filtersFromSearch = pick(searchParams, [
      'mood',
      'start',
      'end',
      'capacity',
      'isFavorite',
      'query',
      'amenities',
      'instantBooking',
    ]);

    // Make sure date is a moment instance and parse it from the expected format
    const date = searchParams.date
      ? moment(searchParams.date, DATE_FILTER_FORMAT)
      : null;

    if (!filtersFromSearch.query) {
      filtersFromSearch.query = initialPlace.name;
    }

    return {
      ...DEFAULT_FILTER_STATE,
      ...filtersFromSearch,
      place: initialPlace,
      bookingType,
      isOffice,
      date,
    };
  });

  const updateState = useStateMergeUpdater(setFilters);

  /*
   * Reset every filter to it's default state
   * except for the place filter.
   */
  const resetFilters = useEvent((...filterKeys) => {
    const containKeys = filterKeys.length > 0;
    const hasFilterKey = Object.keys(DEFAULT_FILTER_STATE).some((key) =>
      filterKeys.includes(key),
    );
    const { place } = filters;

    if (containKeys && hasFilterKey) {
      return updateState({ ...pick(DEFAULT_FILTER_STATE, filterKeys), place });
    }

    updateState({ ...DEFAULT_FILTER_STATE, place });
  });

  const updateFilters = useEvent((newFilters = {}) => {
    // Favorite is only applicable for logged users
    if (!authenticated) {
      newFilters.isFavorite = false;
    }
    // Make sure date filter is always a moment instance
    if (newFilters.date) {
      newFilters.date = moment(newFilters.date);
    }
    // Setting city will just treat it as a place
    if (newFilters.city) {
      const { lat, lng, name } = newFilters.city;
      newFilters.place = { name, lat, lng };
      newFilters.query = name;
    }

    if (newFilters.place) {
      const { lat, lng, addressString: query } = newFilters.place;
      newFilters.place = { name: query, lat, lng };
      newFilters.query = query;
    }
    // Reset isOffice on Deskpass mode
    if (newFilters.bookingType === 'deskpass') {
      newFilters.isOffice = false;
    }

    // Reset date/time filters when changing bookingType/isOffice
    if (newFilters.isOffice || newFilters.bookingType === 'hourly') {
      newFilters.start = DEFAULT_FILTER_STATE.start;
      newFilters.end = DEFAULT_FILTER_STATE.end;
      newFilters.date = DEFAULT_FILTER_STATE.date;
    }

    updateState({ ...newFilters });
  });

  const { bookingType } = filters;

  // Resets specific filter values when changing booking type
  useWatch(
    () => {
      resetFilters(
        'amenities',
        'mood',
        'start',
        'end',
        'capacity',
        'instantBooking',
      );
    },
    [bookingType],
    false,
  );

  const context = React.useMemo(
    () => ({
      filters,
      updateState,
      updateFilters,
      resetFilters,
    }),
    [filters, updateState, updateFilters, resetFilters],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const withFilterContext = consumerToHOC(
  Context.Consumer,
  'filterContext',
);

export const useFilterContext = () => React.useContext(Context);

export default Context;
