import SvgUse from '@/components/graphics/SvgUse';

import * as S from './StripeSecure.styles';

/**
 * A message assuring the user that their payment is secure.
 */
const StripeSecure = ({ alt }) => (
  <S.StripeSecure $alt={alt}>
    {alt ? (
      <>
        <S.StripeSecureBadge $alt={alt}>
          <SvgUse id="powered-by-stripe" category="full" />
        </S.StripeSecureBadge>
        <p>Your payment details are safe and secure.</p>
      </>
    ) : (
      <>
        <p>Your payment details are safe and secure.</p>
        <S.StripeSecureBadge>
          <SvgUse id="powered-by-stripe" category="full" />
        </S.StripeSecureBadge>
      </>
    )}
  </S.StripeSecure>
);

export default StripeSecure;
