import PropTypes from 'prop-types';
import { memo } from 'react';

import * as S from './FormLabel.styles';

/**
 * Labels a form input/group.
 */
function FormLabel({ children, htmlFor, required, ...props }) {
  return (
    <S.FormLabel {...props} htmlFor={htmlFor}>
      {children}
      {required && <span>*</span>}
    </S.FormLabel>
  );
}

FormLabel.defaultProps = {
  required: false,
};

FormLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  htmlFor: PropTypes.string.isRequired,
  required: PropTypes.bool,
  warning: PropTypes.bool,
  reverse: PropTypes.bool,
  alt: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  mini: PropTypes.bool,
  full: PropTypes.bool,
  arrow: PropTypes.bool,
};

export default memo(FormLabel);
