import PropTypes from 'prop-types';

import Icon from '@/components/graphics/Icon';

import useMapOldDashedProps from '@/hooks/useMapOldDashedProps';

import { childrenType } from '@/lib/props';

import * as S from './index.styles';

// These are being parsed to camel case on the Modal Component
export const dashedModifiers = [
  'rule',
  'no-pad-bottom',
  'small-pad-bottom',
  'flex-content',
  'flex-center',
  'no-padding',
];

/**
 * ModalContentNavClose
 * ---
 * Modal content nav close button.
 * @param {Function} onClose
 * @returns {JSX.Element} close button
 */
export const ModalContentNavClose = ({ onClose, ...otherProps }) => (
  <S.ModalContentNavClose {...otherProps} onClick={onClose}>
    <Icon type="close-x-24" />
  </S.ModalContentNavClose>
);

/**
 * Displays content in a way that is appropriate for a modal.
 */
const ModalContent = ({
  children,
  renderNav,
  renderFooter,
  isInitialScrollPosition,
  bottomFooter = false,
  stickyBottomFooter = false,
  footerContent = false,
  ...dashedNamedProps
}) => {
  const modifiers = useMapOldDashedProps(dashedNamedProps, dashedModifiers);

  const shouldRenderNav = typeof renderNav === 'function';
  const shouldRenderFooter = typeof renderFooter === 'function';

  return (
    <S.ModalContent {...modifiers}>
      {shouldRenderNav && (
        <S.Header
          {...modifiers}
          isInitialScrollPosition={isInitialScrollPosition}
        >
          {renderNav()}
        </S.Header>
      )}

      <S.Content {...modifiers}>{children}</S.Content>

      {shouldRenderFooter && (
        <S.Footer
          $bottomFooter={bottomFooter}
          $stickyBottomFooter={stickyBottomFooter}
          $footerContent={footerContent}
        >
          {renderFooter()}
        </S.Footer>
      )}
    </S.ModalContent>
  );
};

ModalContent.propTypes = {
  children: childrenType,
  renderNav: PropTypes.func,
  renderFooter: PropTypes.func,
  isInitialScrollPosition: PropTypes.bool,
};

export default ModalContent;
