import React from 'react';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import NotificationContext from '@/context/Notification';

import config from '@/lib/config';

const { DASH_URL } = config;

const useTransitionToDash = () => {
  // Keep the state of loading longer while the browser
  // loads the new page Even if the request itself has finished
  const [transitioning, setTransitioning] = React.useState(() => false);

  const { create: createNotifcation } = React.useContext(NotificationContext);

  const [optRequestState, getOtp] = useDeskpassAPI(
    (api) => () => {
      setTransitioning(true);
      return api.auth.getOtp();
    },
    {
      fireOnMount: false,
    },
  );

  React.useEffect(() => {
    const otp = optRequestState.data;

    if (optRequestState.ready && !!optRequestState.error) {
      setTransitioning(false);

      const msg = 'Error Transitioning to Team Management Dashboard.';

      console.info(msg, optRequestState.error);
      createNotifcation(msg, 'warning');
    }

    if (optRequestState.ready && !!otp) {
      window.location.href = `${DASH_URL}/login/otp/${otp}`;
    }
  }, [optRequestState, createNotifcation]);

  return [
    {
      ...optRequestState,
      loading: transitioning,
    },
    getOtp,
  ];
};

export default useTransitionToDash;
