import React from 'react';

/*
 * TODO remove this when it becomes offial
 *
 * This will be implemented in react 18 but as I use the concept a lot
 * I'm getting ahead and adding it here.
 */
function useEvent(handler) {
  const handlerRef = React.useRef(handler);

  // In a real implementation, this would run before layout effects
  React.useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return React.useCallback((...args) => {
    return handlerRef.current(...args);
  }, []);
}

export default useEvent;
