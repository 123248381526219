import styled, { css } from 'styled-components/macro';

import { colors } from '@/styles/js/utils/theme';
import typeStyle from '@/styles/js/fonts/typeStyles';

export const Button = styled.button.attrs(() => ({
  type: 'button'
}))`
  ${typeStyle('button-s')}

  cursor: pointer;
  user-select: none;
  background: transparent;
  color: ${colors.text};
  border: solid 1px ${colors.rule};
  border-radius: 4px;
  padding: 0.2rem 1rem;
  padding-right: 1.6rem;
  display: flex;
  align-items: center;

  ${({ selected }) => selected && css`
    background: ${colors.accentTransparentLight};
  `}

  ${({ active }) => active && css`
    border-color: ${colors.primary};
    background: ${colors.primary};
    color: white;
  `}

  ${({ primary, selected }) => primary && css`
    background: ${colors.primary};
    color: ${colors.white};
    border-color: ${colors.primary};

    ${selected && css`
      background: ${colors.text};
      border-color: ${colors.text};
    `}
  `}

  ${({ reverse, selected }) => reverse && css`
    background: transparent;
    color: ${colors.primary};
    border-color: ${colors.primary};

    ${selected && css`
      background: ${colors.primary};
      border-color: ${colors.primary};
      color: ${colors.white};
    `}
  `}

  & > * {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const Text = styled.span`
  white-space: nowrap;

  &:not(:first-child) {
    margin-left: 4px;
  }
`;
