import React, { createContext } from 'react';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import { consumerToHOC } from '@/lib/hoc';

const Context = createContext({});
const { Consumer, Provider } = Context;

export const CountryProvider = ({ children }) => {
  const [{ data: countries, ...requestState }] = useDeskpassAPI(
    (api) => () => api.global.getCountries(),
  );

  const context = React.useMemo(
    () => ({
      countries,
      ...requestState,
    }),
    [countries, requestState],
  );

  return <Provider value={context}>{children}</Provider>;
};

export const useCountryContext = () => React.useContext(Context);
export const CountryConsumer = Consumer;
export const withCountryContext = consumerToHOC(Consumer, 'countryContext');
export default Context;
