/**
 * Sets up i18n functions via i18next and returns the configured i18n object
 * along with init promise in case you need to manually check when it's done
 * setting up shop. Also provides some convenience functions for actual
 * translation.
 */
import {
  I18nextProvider,
  useTranslation as useTranslationI18next,
  withTranslation as withTranslationI18next,
} from 'react-i18next';

import { compose, setDisplayName } from '@/lib/hoc';
import i18n from '@/lib/i18n';
import { capitalize } from '@/lib/stringHelpers';

export const I18NProvider = ({ children }) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

//////////////////////////////////////////////////////////////////////////////
// Helper Functions //////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

/**
 * Like t, but capitalized.
 */
function capitalizedT(t, key, opts = {}) {
  return capitalize(t(key, opts));
}

/**
 * Like t, but pluralized (default pluralization, if a specific number is
 * needed use regular t with custom opts).
 */
function pluralizedT(t, key, opts = {}) {
  return t(key, Object.assign(opts, { count: 0 }));
}

/**
 * Like t, but capitalized AND pluralized.
 */
function capitalizedAndPlurilizedT(t, key, opts = {}) {
  return capitalize(t(key, Object.assign(opts, { count: 0 })));
}

//////////////////////////////////////////////////////////////////////////////
// Connector wrappers ////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export function useTranslation(...params) {
  const { t, ...rest } = useTranslationI18next(...params) || {};

  const T = (...args) => capitalizedT(t, ...args);
  const ts = (...args) => pluralizedT(t, ...args);
  const Ts = (...args) => capitalizedAndPlurilizedT(t, ...args);

  return { t, Ts, ts, T, ...rest };
}

export function withTranslation(...params) {
  return (BaseComponent) => {
    const WithTranslation = ({ t, ...rest }) => {
      const T = (...args) => capitalizedT(t, ...args);
      const ts = (...args) => pluralizedT(t, ...args);
      const Ts = (...args) => capitalizedAndPlurilizedT(t, ...args);

      const props = { ...rest, t, T, Ts, ts };

      return <BaseComponent {...props} />;
    };

    return compose(
      withTranslationI18next(...params),
      setDisplayName(BaseComponent, 'withTranslation'),
    )(WithTranslation);
  };
}

export default i18n;
