import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import useEvent from '@/hooks/useEvent';
import useEventListener from '@/hooks/useEventListener';

import * as Styled from './Drawer.styles';

/**
 * Drawer
 * ---
 * Navigation drawers provide access to destinations in your app.
 * Drawer is simply a UI component i.e It doesn't mentain it's own `open` and `close` state. This enables high reusability.
 *
 * Props
 * ---
 * - `open` - {bool} - drawer visibility state
 * - `onClose` - {func} - handler for close drawer
 */
const Drawer = ({ children, open, onClose }) => {
  const targetRef = useRef();
  const previousAppOverflowSetting = useRef();

  const showTargetElement = useEvent((appRoot) => {
    // Set app-root overflow to auto
    // - helps drawer stick to the viewport height
    !!appRoot && appRoot.style.setProperty('overflow', 'auto');

    // Disable body scroll
    disableBodyScroll(targetRef.current, { reserveScrollBarGap: false });
  });

  const hideTargetElement = useEvent((appRoot, previousAppOverflowSetting) => {
    // Reset app-root overflow to initial state
    !!appRoot && !!previousAppOverflowSetting
      ? appRoot.style.setProperty('overflow', previousAppOverflowSetting)
      : appRoot.style.removeProperty('overflow');

    // Re-enable body scroll
    enableBodyScroll(targetRef.current);
  });

  // We want prevent the <body/> from scrolling while the drawer is currently open.
  useEffect(() => {
    const appRoot = document.getElementById('app-root');

    if (!!open) {
      previousAppOverflowSetting.current =
        appRoot.style.getPropertyValue('overflow');
      showTargetElement(appRoot);
    } else {
      hideTargetElement(appRoot, previousAppOverflowSetting.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [open, showTargetElement, hideTargetElement]);

  useEventListener('keydown', (e) => {
    if (open) {
      const { keyCode } = e;

      const pressedCloserKey = [
        27, // Escape
        13, // Enter
      ].includes(keyCode);

      if (pressedCloserKey) {
        onClose();
      }
    }
  });

  return (
    <Styled.Drawer ref={targetRef} open={open}>
      {children}
    </Styled.Drawer>
  );
};

Drawer.defaultProps = {
  open: false,
};

Drawer.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,

  /**
   * Callback fired when the component requests to be closed.
   *
   * @param {object} event The event source of the callback.
   */
  onClose: PropTypes.func,

  /**
   * If `true`, the component is shown.
   * @default false
   */
  open: PropTypes.bool,
};

export default Drawer;
