import { useCallback, useContext, useEffect, useRef } from 'react';

import UserContext from '@/context/User';

import useMediaQuery from '@/hooks/useMediaQuery';

import config from '@/lib/config';
import { childrenType } from '@/lib/props';

import Context from './context';

const appID = config.INTERCOM_APP_ID;

const onReady = (cb = () => null) => {
  if (window.Intercom) {
    cb(window.Intercom);
  }
};

const IntercomProvider = ({ children }) => {
  const isMobile = useMediaQuery({ max: 'large' });
  const { user, teamOrganization, ...userContext } = useContext(UserContext);
  const intercomRef = useRef();

  /* eslint-disable camelcase */
  const resolutionBasedSettings = {
    custom_launcher_selector: isMobile ? '.js-intercom-help-trigger' : null,
    hide_default_launcher: isMobile,
  };

  let userBasedSettings = {};

  if (userContext.authenticated) {
    userBasedSettings = {
      email: user.email,
      name: user.fullName,
      user_hash: user.intercomIdentity,
    };
  }
  /* eslint-enable */

  if (teamOrganization && Object.keys(teamOrganization).length) {
    /* eslint-disable */
    userBasedSettings = {
      company: {
        company_id: teamOrganization.slug,
        created_at: teamOrganization.createdAt,
        name: teamOrganization.name,
        website: teamOrganization.website,
        monthly_spend: teamOrganization.currentMonthUsage || 0,
      },
      teamOrganizationId: teamOrganization.id,
      isTeamUser: true,
      ...userBasedSettings,
    };
  }

  const settings = {
    app_id: appID,
    ...userBasedSettings,
    ...resolutionBasedSettings,
  };

  const showNewMessage = useCallback(() => {
    onReady((Intercom) => {
      Intercom('showNewMessage');
    });
  }, []);

  const shutDown = useCallback(() => {
    onReady((Intercom) => {
      Intercom('shutdown');
      intercomRef.current.booted = false;
    });
  }, []);

  const update = useCallback((overrideSettings = {}) => {
    onReady((Intercom) => {
      Intercom('update', {
        ...intercomRef.current.settings,
        ...overrideSettings,
      });
    });
  }, []);

  const boot = useCallback((overrideSettings = {}) => {
    onReady((Intercom) => {
      Intercom('boot', {
        ...intercomRef.current.settings,
        ...overrideSettings,
      });
      intercomRef.current.booted = true;
    });
  }, []);

  // Keep intercom state in ref so it
  // doesn't trigger re renders.
  useEffect(() => {
    intercomRef.current = {
      booted: false,
      settings,
    };
  }, [settings]);

  // Loads Intercom
  useEffect(() => {
    if (!appID) {
      return;
    }

    // Don't bother loading if intercom isn't set as active
    if (config.ACTIVE_CRM !== 'intercom') {
      return;
    }

    if (!window.Intercom) {
      (function (w, d, id, s, x) {
        function i() {
          i.c(arguments);
        }
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = 'https://widget.intercom.io/widget/' + id;
        d.head.appendChild(s);
      })(window, document, appID);
    }

    // If provider is not around anymore, cleanup
    return () => {
      shutDown();
      delete window.Intercom;
    };
  }, [shutDown]);

  useEffect(() => {
    if (userContext.ready) {
      if (userContext.authenticated) {
        if (intercomRef.current.booted) {
          // Authenticated and logging in, update user data
          update();
        } else {
          // Authenticared and Page load, boot
          boot();
        }
      } else {
        // Clean cookies on logout or logged of page load
        shutDown();
        boot();
      }
    }
  }, [userContext.ready, userContext.authenticated]);

  // Exposed context
  const context = {
    Intercom: window.Intercom,
    update,
    shutDown,
    showNewMessage,
    boot,
  };

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

IntercomProvider.propTypes = {
  children: childrenType,
};

export default IntercomProvider;
