import { Helmet } from 'react-helmet';

import GenericError from '@/components/error-handling/GenericError';

const PageNotFound = () => {
  const message =
    'Please double-check the address, explore our app,' +
    ' or find a place to work near you.';

  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
        <title>Page Not Found</title>
      </Helmet>

      <GenericError
        title="We couldn’t find that page."
        message={message}
        btnProps={{ arrow: false }}
        btnText="Find Spaces Near You"
      />
    </>
  );
};

export default PageNotFound;
