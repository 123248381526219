import React from 'react';
import { Route } from 'react-router-dom';

import ErrorContext from '@/context/Error';

export const NotFound = () => {
  const { setAppError } = React.useContext(ErrorContext);

  React.useEffect(() => {
    setAppError((AppLevelErrorTypes) => AppLevelErrorTypes.NotFound);
  }, [setAppError]);

  return null;
};

/*
 * Render this component when 404 content should be displayed.
 *
 * We should preserve the URL always for the benefit of search engines
 * but make sure to display the 404 page content.
 */
const PageNotFoundRoute = (props = {}) => {
  return <Route {...props} path="*" component={NotFound} />;
};

export default PageNotFoundRoute;
