import { childrenOfType } from 'airbnb-prop-types';
import PropTypes from 'prop-types';

import { CommonLink } from '@/components/buttons-links/CommonLink';

import * as S from './Text.styles';

/**
 * Text Component
 * ---
 * A block of content supporting a limited subset of HTML elements.
 *
 * (Optional) props
 * - `size`
 *    - `xlarge` - Extra larger than usual text
 *    - `large` - Larger than usual text
 *    - `medium-large` - Slightly larger at small viewport
 *    - `medium` - Slightly smaller size
 *    - `small` - Even smaller size
 *    - `smallest` Smallest size
 * - `color`
 *    - `warning` - For important content
 *    - `subtle` - For less important content
 *    - `reverse` - For use on dark backgrounds
 * - `alignment` - `align-center | align-left`
 * - `padding` - `pad-vertical | pad-top | pad-bottom`
 * - `border`
 *    - `bordered` - Display simple border around entire element
 *    - `rounded-border` - Display rounded border around entire element in
 *    - `bottom-border` - Display simple border on bottom of content
 * - `margin` - `no-top-margin | margin-vertical`
 * - `lineBreak` - `with-line-breaks | no-line-break`
 * - `fontStyle`
 *    - `bold` - Makes font weight bold
 *    - `marquee` - Bold + uppercase style
 * - `blockQuote` - `boolean`
 * - `copyable` - `boolean`
 * - `selected` - `boolean`
 * - `underline` - `boolean`
 */
const Text = (props) => <S.Text {...props} />;

Text.propTypes = {
  children: childrenOfType('p', 'span', 'ul', 'ol', 'strong', CommonLink)
    .isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  alignment: PropTypes.string,
  padding: PropTypes.string,
  border: PropTypes.string,
  margin: PropTypes.string,
  lineBreak: PropTypes.string,
  fontStyle: PropTypes.string,
  blockQuote: PropTypes.bool,
  copyable: PropTypes.bool,
  selected: PropTypes.bool,
  underline: PropTypes.bool,
  flexColumn: PropTypes.bool,
};

export default Text;
