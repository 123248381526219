import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React from 'react';

import StripeSecure from '@/components/details/StripeSecure';
import FormGroup from '@/components/forms/FormGroup';

import useEvent from '@/hooks/useEvent';

import * as Styled from './index.styles';
import useElemenStyles from './useElementStyles';

const StripeCard = ({
  options = {},
  onChange = () => {},
  onBlur = () => {},
}) => {
  const elementStyles = useElemenStyles();

  const [dirty, setDirty] = React.useState(() => false);
  const [error, setError] = React.useState(() => {});

  const handleBlur = useEvent(() => {
    setDirty(true);
    onBlur();
  });

  const handleChange = useEvent((meta) => {
    const { error, empty, complete } = meta;

    let incompleteError;

    if (empty) {
      incompleteError = {
        code: 'required',
        type: 'validation_error',
        message: 'Credit card information is required',
      };
    }

    if (!complete) {
      incompleteError = {
        code: 'imcomplete',
        type: 'validation_error',
        message: 'Credit card information is incomplete',
      };
    }

    const newError = error ?? incompleteError;

    if (!newError && !complete) return;

    setError(newError);
    onChange({ ...meta, error: newError });
  });

  const errorMessage = !!error?.message && dirty ? error?.message : '';

  return (
    <Styled.Container>
      <FormGroup
        name="card"
        label="Credit Card Number"
        errorMessage={errorMessage}
      >
        <Styled.Card
          onChange={handleChange}
          onBlur={handleBlur}
          options={{
            hidePostalCode: true,
            ...elementStyles,
            ...options,
          }}
        />
      </FormGroup>

      <StripeSecure alt />
    </Styled.Container>
  );
};

export function useCard() {
  const elements = useElements();
  const stripe = useStripe();

  const generateCardToken = useEvent(async () => {
    if (!elements) {
      throw new Error('Could not get the stripe elements object');
    }

    const card = elements.getElement(CardElement);

    if (!card) {
      throw new Error('Could not get the stripe card element');
    }

    if (!stripe) {
      throw new Error('Could not get the stripe instance object');
    }

    return stripe.createToken(card);
  });

  return {
    generateCardToken,
  };
}

export default StripeCard;
