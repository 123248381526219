import { useEffect, useLayoutEffect, useRef } from 'react';

export default function useMount(effect = () => null) {
  const effectRef = useRef(effect);
  useEffect(() => effectRef.current(), []);
}

export function useMountLayout(effect = () => null) {
  const effectRef = useRef(effect);
  useLayoutEffect(() => effectRef.current(), []);
}

export function useMountAsync(effect = async () => null) {
  useMount(() => {
    effect();
  });
}
