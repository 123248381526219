import React, { Component, createContext } from 'react';

import { consumerToHOC } from '@/lib/hoc';

const Context = createContext({});

export class Provider extends Component {
  state = {
    notifications: [],
    persistentNotifications: [],
  };

  render() {
    // Everything in here will be expose to the context consumer
    const context = {
      ...this.state,
      createOrReplacePersistent: this.createOrReplacePersistent,
      destroyPersistent: this.destroyPersistent,
      create: this.create,
      destroy: this.destroy,
    };

    return (
      <Context.Provider value={context}>{this.props.children}</Context.Provider>
    );
  }

  /**
   * Create a new persistent notification
   *
   * @param    {string}  message  The message to display
   * @param    {string}  style    Unique style to apply ['warning', 'special']
   */
  createOrReplacePersistent = (name, message, style = null, options = {}) => {
    const { url = null } = options;

    const notification = {
      message,
      style,
      id: name,
      url,
    };
    const idx = this.state.persistentNotifications.findIndex(
      (it) => it.id === name,
    );
    const persistentNotifications = [...this.state.persistentNotifications];

    if (idx !== -1) {
      // If it exists with this name, just replace it
      persistentNotifications[idx] = notification;

      this.setState({ persistentNotifications });
    } else {
      // Otherwise, add it to the top of the list
      // as persistent notifications priority to display
      // is always the one on the top
      this.setState({
        persistentNotifications: [notification, ...persistentNotifications],
      });
    }
  };

  /**
   * Destroys an existing persistent notification
   *
   * @param    {string}  id  The ID of the notification to destroy
   */
  destroyPersistent = (id) => {
    this.setState({
      persistentNotifications: this.state.persistentNotifications.filter(
        (it) => it.id !== id,
      ),
    });
  };

  /**
   * Create a new notification
   *
   * @param    {string}  message  The message to display
   * @param    {string}  style    Unique style to apply ['warning', 'special']
   */
  create = (message, style = null, options = {}) => {
    // Don't show repeated messages. Messages won't be huge so it's fine
    // to compare them once in a while
    if (this.state.notifications.some((it) => it.message === message)) return;

    // Options with default values
    const { timeout = 6000, url = null } = options;

    const id = btoa(Math.random()).substring(0, 12);
    const notification = {
      message,
      style,
      id,
      timeout,
      url,
    };

    this.setState({
      notifications: this.state.notifications.concat(notification),
    });

    // Automatically destroying the notification after a given amount of time
    setTimeout(() => {
      this.destroy(id);
    }, timeout);

    return notification;
  };

  /**
   * Destroys an existing notification
   *
   * @param    {string}  id  The ID of the notification to destroy
   */
  destroy = (id) => {
    this.setState({
      notifications: this.state.notifications.filter((it) => it.id !== id),
    });
  };
}

export default Context;
export const useNotificationContext = () => React.useContext(Context);
export const NotificationProvider = Provider;
export const withNotificationContext = consumerToHOC(
  Context.Consumer,
  'notificationContext',
);
