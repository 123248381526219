import styled, { css } from 'styled-components/macro';

import mq from '@/styles/js/utils/mq';
import typeStyle from '@/styles/js/fonts/typeStyles';
import { scaleWithVW, scaleWithVH } from '@/styles/js/utils/scaling';

export const NavBar = styled.div`
  padding-top: 2.2rem;
  padding-bottom: 2rem;
  padding-left: ${scaleWithVW('2.3rem')};
  padding-right: ${scaleWithVW('2.3rem')};

  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 6.8rem;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};

  ${mq('medium')} {
    padding: 0 4rem;
  }

  ${(props) => props.responsive && css`
    ${mq('medium')} {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 6.2rem;
      padding: ${scaleWithVH('2rem')} ${scaleWithVW('2.3rem')} 0;
    }
  `};
`;

export const BackIcon = styled.div`
  color: ${({ theme }) => theme.colors.accent};
  width: 4.4rem;
  height: 4.4rem;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  margin-left: -1rem;
  margin-top: 0.1rem;
`;

export const Back = styled.span`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  ${(props) => props.responsive && css`
    ${mq('medium')} {
      order: 1;
    }
  `}
`;

export const Title = styled.h1`
  ${typeStyle('h4')}

  text-align: center;
  position: absolute;
  left: 0;
  right: 0;

  ${(props) => props.responsive && css`
    ${mq('medium')} {
      ${typeStyle('h2')}

      text-align: left;
      position: static;
      padding-bottom: 0.6rem;
      margin-top: 2.4rem;
      display: block;
      order: 2;
    }
  `}
`;

const textStyles = css`
  ${typeStyle('body-s')}

  color: ${({ theme }) => theme.colors.accent};
  margin-top: 0.1rem;
  font-weight: bold;
`;

export const BackText = styled.span`
  ${textStyles}

  display: inline-block;
  padding-left: 3rem;
`;

export const Action = styled.span`
  ${textStyles}

  position: relative;
  cursor: pointer;
  margin-top: 0.1rem;

  ${(props) => props.responsive && css`
    ${mq('medium')} {
      order: 3;
    }
  `}
`;
