import styled from 'styled-components';

import { ReactComponent as ReactSpritesFull } from '@/images/output/full.symbol.svg';
import { ReactComponent as ReactSpritesIcons } from '@/images/output/icons.symbol.svg';

import { hideVisually } from '@/styles/js/utils/interations';

const StyledSvgSprites = styled.span`
  ${hideVisually}
`;

const SvgSprites = () => (
  <StyledSvgSprites>
    <ReactSpritesFull />
    <ReactSpritesIcons />
  </StyledSvgSprites>
);

export default SvgSprites;
