import NetworkClient from '@/lib/network/client';

class FeedsNetworkClient extends NetworkClient {
  config = {
    baseURL: 'https://feeds.deskpass.com',
  };
}

export const feedsClient = new FeedsNetworkClient();

/**
 * Get City and Space count from feeds.
 *
 * @return {Promise} Returns on find location success or error
 */
export async function getCityAndSpaceCount() {
  try {
    const { result } = (await feedsClient.get('/feed/meta')) ?? {};
    const { totalActiveCities, totalActiveSpaces } = result ?? {};

    return {
      cityCount: totalActiveCities || 'many',
      spaceCount: totalActiveSpaces || 'many',
    };
  } catch (err) {
    console.error('Error getting City and Space Count: ', err.message);

    throw err;
  }
}
