import styled from 'styled-components/macro';

export const Icon = styled.span.attrs(({ className }) => ({
  className: `Icon ${className}`,
}))`
  height: ${({ size }) => size ? `${size}rem` : '1rem'};
  width: ${({ size }) => size ? `${size}rem` : '1rem'};
  display: ${(props) => (props.$inline ? 'inline-block' : 'block')};

  svg {
    height: ${({ size }) => size ? `${size}rem` : '1rem'};
    width: ${({ size }) => size ? `${size}rem` : '1rem'};
  }
`;