import currency from 'currency.js';

/**
 * Format a number to a currency.
 *
 * @param {Number} amount Currency number to format
 *
 * @return {String} A string currency number.
 */
export const formatCurrency = (
  amount,
  currencySymbol = '$',
  showZeroCents = true,
  absolute = false,
) => {
  const options = { separator: ',', decimal: '.' };

  if (absolute) {
    amount = Math.abs(amount);
  }

  let formatted = `${currency(amount, options).format({
    symbol: currencySymbol,
  })}`;

  if (showZeroCents) return formatted;

  // Only show decimal when there are cents
  if (!isNaN(amount) && amount % 1 !== 0) return formatted;

  // Let the currency symbol after the minus sign if needed
  if (currencySymbol) {
    if (amount < 0) {
      return `-${currencySymbol}${Math.abs(amount)}`;
    }

    return currencySymbol + amount;
  }
  // Simply return the actual number
  return amount;
};
