import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  CommonSelectable,
  commonOptionProps,
  commonSelectableProps,
} from '@/components/forms/CommonSelectable';
import FormSelectableItem from '@/components/forms/FormSelectableItem';

/**
 * Styled FormSelectable
 * ---
 * Extends component __CommonSelectable__.
 *
 * Optional props
 * - `row`
 * - `rowNoPadTop`
 * - `inline`
 * - All __CommonSelectable__ props
 */
const StyledFormSelectable = styled(CommonSelectable)`
  ${({ row, rowNoPadTop, inline }) => css`
    ${row &&
    css`
      border-top: none;
    `}
    ${rowNoPadTop &&
    css`
      border-top: none;
    `}
    ${inline &&
    css`
      display: flex;
      gap: 2.4rem;
    `}
  `}
`;

/**
 * A list of rows that can be selected.
 * Implements a <FormSelectableItem> for each option.
 *
 * Optional props
 * - `type`
 * - `options` - `{ icon: String, image: String }`
 * - `row`
 * - `rowNoPadTop`
 * - `rowNoPadBottom`
 * - `inline`
 * - `smallFont`
 * - All __CommonSelectable__ props
 */
const FormSelectable = ({ type, ...otherProps }) => {
  function renderItem(option, onClick, commonProps, idx) {
    const rowOnPaddingTop =
      otherProps.options.length > 1 && idx === 0 && otherProps['rowNoPadTop'];
    const rowOnPaddingBottom =
      otherProps.options.length > 1 &&
      idx === otherProps.options.length - 1 &&
      otherProps['rowNoPadBottom'];

    return (
      <FormSelectableItem
        icon={option.icon}
        image={option.image}
        type={type}
        rowNoPadTop={rowOnPaddingTop}
        rowNoPadBottom={rowOnPaddingBottom}
        row={otherProps.row}
        smallFont={otherProps.smallFont}
        onClick={onClick}
        {...commonProps}
      >
        {option.label}
      </FormSelectableItem>
    );
  }

  return <StyledFormSelectable {...otherProps} renderItem={renderItem} />;
};

FormSelectable.propTypes = Object.assign({}, commonSelectableProps, {
  options: PropTypes.arrayOf(
    PropTypes.shape(
      Object.assign({}, commonOptionProps, {
        icon: PropTypes.string,
        image: PropTypes.string,
      }),
    ),
  ).isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
});

export default FormSelectable;
