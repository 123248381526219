import Notification from '@/components/notifications/Notification';

import { useUserContext } from '@/context/User';

/**
 * Displays a team announcement notification
 */
const TeamAnnouncementNotification = ({ closeNotification }) => {
  const { teamUser, teamCustomization } = useUserContext();

  if (!teamUser || !teamCustomization?.announcement) {
    return null;
  }

  return (
    <Notification onIconClick={closeNotification}>
      {teamCustomization.announcement}
    </Notification>
  );
};

export default TeamAnnouncementNotification;
