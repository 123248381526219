import { createContext, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { consumerToHOC } from '@/lib/hoc';

const Context = createContext();

const { Consumer, Provider } = Context;

/**
 * MoreModalProvider
 * ---
 * - Manage modal open and close state.
 * - Auto-detects and close modal when an internal link is clicked.
 *
 * __Context__
 * - `openMore` - {bool} modal state
 * - `toggleModal` - {func} toggles modal visibility
 *
 */
export const MoreModalProvider = ({ children }) => {
  const [openMore, setOpen] = useState(false);
  const location = useLocation();

  const toggleMoreModal = useCallback(() => {
    setOpen(!openMore);
  }, [openMore]);

  // listen and close modal on location change
  useEffect(() => {
    setOpen(false);
  }, [location]);

  const context = { openMore, toggleMoreModal };

  return <Provider value={context}>{children}</Provider>;
};

export const MoreModalConsumer = Consumer;
/**
 * withMoreModalContext
 * ---
 * Provides consumer with MoreModal context
 *
 * __Context__
 * - `openMore` - {bool} modal state
 * - `toggleModal` - {func} toggles modal visibility
 *
 */
export const withMoreModalContext = consumerToHOC(Consumer, 'moreModalContext');
export default Context;
